import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import Box from '@mui/material/Box';

import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import FullPageLoader from 'components/FullPageLoader';

import routes from 'routes/client';

import LoginPage from 'pages/Client/Login';
import {
  ClientContext,
  type ClientContextType,
  useClientContext,
} from 'functions/context';
import {APIError, apiCall, clearAuthCookie} from 'functions/api';

import sidebarImage from 'assets/img/sidebar.jpg';
import {containerSx, drawerWidth, transition} from 'assets/jss/main';

import {type LocationResponse} from 'types/locations';
import {type ClientResponse} from 'types/clients';

import FormFillLayout from './FormFill';

function ClientLayout() {
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const context = useClientContext();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);

  const filteredRoutes = React.useMemo(
    () =>
      routes.filter(route => {
        if (route.module && !context.location.modules.includes(route.module)) {
          return false;
        }
        return true;
      }),
    [context.location],
  );

  return (
    <Box
      sx={{
        position: 'relative',
        top: '0',
        height: '100vh',
      }}>
      <Sidebar
        logoText="Sign App"
        routes={filteredRoutes}
        base={context.navigationBase}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        image={sidebarImage}
      />
      <Box
        sx={theme => ({
          [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
          },
          overflow: 'auto',
          position: 'relative',
          float: 'right',
          maxHeight: '100%',
          width: '100%',
          overflowScrolling: 'touch',
          ...transition,
        })}>
        <Navbar
          settingsLink
          base={context.navigationBase}
          routes={filteredRoutes}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          sx={{
            marginTop: '70px',
            padding: '30px 15px',
            minHeight: 'calc(100vh - 123px)',
          }}>
          <Box sx={containerSx}>
            <Routes>
              {filteredRoutes.map((route, key) => (
                <Route
                  path={route.path}
                  element={<route.element />}
                  key={key}
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={routes[0].path} replace />}
              />
            </Routes>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

function FormFillWrapper() {
  const formId = parseInt(useParams<'formId'>().formId!, 10);
  return <FormFillLayout portal="client" formId={formId} />;
}

function ClientAuthenticatedLayout() {
  const locationId = useParams<'locationId'>().locationId!;
  const [context, setContext] = React.useState<ClientContextType>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    apiCall('/client/checkLocation', 'POST', {locationId}).then(
      (location: LocationResponse) => {
        setContext({
          location,
          updateContext: setContext,
          navigationBase: `/${locationId}/c`,
        });
        apiCall('/client/client', 'GET')
          .then((client: ClientResponse) => {
            if (client.locationId !== location.id) {
              clearAuthCookie();
              window.location.reload();
            }
            setContext(context => ({
              ...context!,
              client,
            }));
            setLoading(false);
          })
          .catch(e => {
            if (e instanceof APIError && e.code === 'unauthorized') {
              setLoading(false);
            } else {
              console.error(e);
            }
          });
      },
      e => {
        if (e instanceof APIError && e.code === 'not_found') {
          window.location.href = '/';
        } else {
          console.error(e);
        }
      },
    );
  }, []);

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <ClientContext.Provider value={context}>
      {!context?.client && (
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Routes>
      )}
      {context?.client && (
        <Routes>
          {context.location.modules.includes('FORMS') && (
            <Route path="forms/:formId/fill/*" element={<FormFillWrapper />} />
          )}
          <Route path="*" element={<ClientLayout />} />
        </Routes>
      )}
    </ClientContext.Provider>
  );
}

export default ClientAuthenticatedLayout;
