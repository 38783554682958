import React from 'react';
import ReactDOM from 'react-dom/client';
import MainLayout from 'layout/Main';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'assets/css/main.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(<MainLayout />);
