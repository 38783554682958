import React from 'react';
import Dialog from 'components/Dialog';
import {
  DocumentCategory,
  DocumentCategoryAccess,
  DocumentCategoryPortal,
  InsertDocumentCategoryRequest,
} from 'types/documents';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';
import Select from 'components/Select';
import {apiCall} from 'functions/api';

interface Props {
  open: boolean;
  onClose: (refresh: boolean) => void;
  category?: DocumentCategory;
  documentPortal: DocumentCategoryPortal;
}

export const accessTypes = [
  {value: 'NONE', label: 'None'},
  {value: 'VIEW', label: 'View Ony'},
  {value: 'UPLOAD', label: 'Upload'},
];

function DocumentCategoryDialog(props: Props) {
  const [name, setName] = React.useState(initialValue('text'));
  const [description, setDescription] = React.useState(initialValue('any'));
  const [access, setAccess] = React.useState<DocumentCategoryAccess | ''>('');
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');

  const error = React.useMemo(
    () =>
      name.state !== 'success' || description.state !== 'success' || !access,
    [name, description, access],
  );

  const handleClose = () => {
    if (submitting) return;
    props.onClose(false);
  };

  const handleSubmit = () => {
    if (submitting || error) return;
    setSubmitting(true);
    const request: InsertDocumentCategoryRequest = {
      name: name.value,
      description: description.value,
      access: access as DocumentCategoryAccess,
      portal: props.documentPortal,
    };
    const url = props.category
      ? `/location/documentCategories/${props.category.id}`
      : '/location/documentCategories';
    apiCall(url, 'POST', request).then(
      () => {
        setSubmitting(false);
        props.onClose(true);
      },
      e => {
        setSubmitting(false);
        setServerError(e.message || 'An unexpected error occurred.');
      },
    );
  };

  React.useEffect(() => {
    if (props.open) {
      setName(initialValue('text', props.category?.name || ''));
      setDescription(initialValue('any', props.category?.description || ''));
      setAccess(props.category?.access || '');
    }
  }, [props.open, props.category]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.category ? 'Edit Category' : 'New Category'}
      actions={[
        {
          color: 'secondary',
          text: 'Close',
          onClick: handleClose,
        },
        {
          color: 'primary',
          text: submitting ? 'Saving' : 'Save',
          submit: true,
          disabled: error || submitting,
        },
      ]}>
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      <ValidatedInput
        label="Description"
        value={description}
        onChange={setDescription}
        type="any"
      />
      <Select
        label="Access"
        value={access}
        onChange={setAccess}
        items={accessTypes}
      />
      {serverError && <p style={{color: 'red'}}>{serverError}</p>}
    </Dialog>
  );
}

export default DocumentCategoryDialog;
