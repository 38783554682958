import React from 'react';
import {useNavigate} from 'react-router-dom';
import FormNavbar, {navbarHeight} from 'components/FormNavbar';
import {useFormFillContext} from 'functions/context';

import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from 'components/Button';

import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';

import {defaultImageWidth} from 'functions/forms';
import {storageBase} from 'functions/uploader';

import type {FormComponentValue, UpdateComponentRequest} from 'types/forms';

import {apiCall, createAndUploadImage} from 'functions/api';
import DocumentComponentView, {isValidValue} from './DocumentComponent';

export default function DocumentFill() {
  const context = useFormFillContext();
  const components = context.components!;
  const navigate = useNavigate();

  const [submitting, setSubmitting] = React.useState(false);
  const [imageHeight, setImageHeight] = React.useState(0);
  const [scale, setScale] = React.useState(1);
  const [values, setValues] = React.useState<(FormComponentValue | null)[]>(
    () => context.components!.map(component => component.value),
  );
  const [modified, setModified] = React.useState(() => values.map(() => false));

  const associatedIdx = React.useMemo(
    () =>
      components.map(component => {
        if (component.associatedId) {
          return components.findIndex(c => c.id === component.associatedId);
        }
        return -1;
      }),
    [components],
  );

  const disabled = React.useMemo(
    () =>
      components.map(component => {
        if (!context.editable) {
          return true;
        }
        if (context.adminAccess) {
          return false;
        }
        const roleId = component.roleId;
        return !!roleId && context.myRole?.id !== roleId;
      }),
    [components, context],
  );

  const roleNames = React.useMemo(
    () =>
      components.map(component => {
        const roleId = component.roleId;
        console.log(context.roles);
        return context.roles.find(r => r.id === roleId)?.name;
      }),
    [components, context],
  );

  const valid = React.useMemo(
    () =>
      components.every(
        (component, key) =>
          disabled[key] ||
          !!component.associatedId ||
          isValidValue(
            component,
            component.associatedId ? components[associatedIdx[key]] : undefined,
            values[component.associatedId ? associatedIdx[key] : key],
          ),
      ),
    [components, values, disabled],
  );

  const handleValueChange = (idx: number, value: FormComponentValue | null) => {
    const newValues = [...values];
    const newModified = [...modified];
    newValues[idx] = value;
    newModified[idx] = true;
    setValues(newValues);
    setModified(newModified);
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImageHeight(
      (e.currentTarget.naturalHeight / e.currentTarget.naturalWidth) *
        defaultImageWidth,
    );
  };

  const handleFormSubmit = async () => {
    setSubmitting(true);
    try {
      await Promise.all(
        components.map(async (component, key) => {
          if (
            !component.associatedId &&
            !disabled[key] &&
            modified[key] &&
            values[key]
          ) {
            const value = values[key]!;
            if (value.file && value.file.startsWith('data')) {
              value.file = await createAndUploadImage(
                value.file,
                context.portal,
                'signature',
              );
            }
            const apiURL = `${context.pageAPIBase}/components/${component.id}`;
            const data: UpdateComponentRequest = {value};
            await apiCall(apiURL, 'POST', data);
          }
        }),
      );
      navigate(context.navigationBase);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      console.error(e);
    }
  };

  const documentScale = React.useMemo(() => {
    const initialWidth = Math.min(window.innerWidth, 1000);
    return (initialWidth - 40) / defaultImageWidth;
  }, []);

  return (
    <div>
      <FormNavbar
        title={context.page!.name}
        backLink={context.navigationBase}
        button={{
          label: submitting ? 'Submitting' : 'Submit',
          onClick: handleFormSubmit,
          disabled: !valid || submitting,
        }}
      />
      <Box
        sx={{
          height: `calc(100vh - ${navbarHeight}px)`,
          overflow: 'auto',
        }}>
        <Box
          sx={{
            padding: '20px',
            width: `${defaultImageWidth * scale * documentScale + 40}px`,
            height: `${imageHeight * scale * documentScale + 40}px`,
            boxSizing: 'border-box',
            margin: '0 auto',
          }}>
          <Box
            sx={{
              position: 'relative',
              width: `${defaultImageWidth}px`,
              transform: `scale(${scale * documentScale})`,
              transformOrigin: 'top left',
            }}>
            <img
              style={{
                width: '100%',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
              alt="Document Background"
              src={storageBase + context.page!.image}
              onLoad={handleImageLoad}
            />
            {components.map((component, key) => (
              <DocumentComponentView
                key={component.id}
                component={component}
                disabled={
                  disabled[component.associatedId ? associatedIdx[key] : key]
                }
                roleName={
                  roleNames[component.associatedId ? associatedIdx[key] : key]
                }
                associatedComponent={components[associatedIdx[key]]}
                value={
                  values[component.associatedId ? associatedIdx[key] : key]
                }
                onChange={value =>
                  handleValueChange(
                    component.associatedId ? associatedIdx[key] : key,
                    value,
                  )
                }
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 30,
        }}>
        <ButtonGroup orientation="vertical" variant="contained">
          <Button
            size="small"
            color="secondary"
            disabled={scale >= 1.5}
            onClick={() => setScale(scale + 0.1)}>
            <ZoomIn />
          </Button>
          <Button
            size="small"
            color="secondary"
            disabled={scale <= 0.4}
            onClick={() => setScale(scale - 0.1)}>
            <ZoomOut />
          </Button>
        </ButtonGroup>
      </Box>
    </div>
  );
}
