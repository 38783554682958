import React, {useEffect, useState} from 'react';

import Close from '@mui/icons-material/Close';
import {GridActionsCellItem} from '@mui/x-data-grid';

import Card from 'components/Card';
import DataTable from 'components/DataTable';
import ConfirmDialog from 'components/ConfirmDialog';

import {apiCall} from 'functions/api';
import {useAdminContext} from 'functions/context';

import type {LocationAssignment} from 'types/locations';

import AddAssignment from './AddAssignment';

export default function BooksAssignments() {
  const [assignments, setAssignments] = useState<
    LocationAssignment & Array<{name: string}>
  >();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [remove, setRemove] = useState<number>();

  const context = useAdminContext();
  const {location} = context.extra;

  const loadData = () => {
    setAssignments(undefined);
    apiCall('/admin/locationAssignments', 'GET', {
      type: 'BOOK',
      expand: true,
      locationId: location.id,
    }).then(({data}) => {
      setAssignments(data.map((v: any) => ({...v, name: v.content.name})));
    });
  };
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div>
      <Card
        title="Assigned Books"
        button="Assign"
        buttonOnClick={() => {
          setDialogOpen(true);
        }}>
        <DataTable
          rows={assignments}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 2,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={0}
                  label="Remove"
                  icon={<Close />}
                  onClick={() => {
                    setRemove(params.row.id);
                  }}
                />,
              ],
            },
          ]}
        />
      </Card>
      <ConfirmDialog
        title="Remove Assignment"
        text="Are you sure you want to remove this assignment?"
        open={remove !== undefined}
        onClose={s => {
          if (s) loadData();
          setRemove(undefined);
        }}
        apiLink={`/admin/locationAssignments/${remove}`}
      />
      <AddAssignment
        open={dialogOpen}
        onClose={s => {
          setDialogOpen(false);
          if (s) loadData();
        }}
        module="BOOK"
      />
    </div>
  );
}
