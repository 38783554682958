import React from 'react';

import {GridActionsCellItem} from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Card from 'components/Card';
import ConfirmDialog from 'components/ConfirmDialog';
import DataTable from 'components/DataTable';
import Details from 'components/Details';

import {Link, useNavigate, useParams} from 'react-router-dom';
import {format} from 'date-fns';
import {APIError, apiCall} from 'functions/api';
import {useLocationContext} from 'functions/context';

import type {Task, UpdateTaskRequest} from 'types/tasks';

import TaskRepeatView from './Repeat';
import TaskStatusView from './Status';
import TaskDialog from './Dialog';
import ChecklistDialog from './ChecklistDialog';
import UserDialog from './UserDialog';
import CompleteDialog from './CompleteDialog';
import TaskAssignmentsCard, {type TaskAssignmentsCardRef} from './Assignments';

export default function TaskInfoPage() {
  const {taskId} = useParams<'taskId'>();
  const navigate = useNavigate();
  const context = useLocationContext();
  const ref = React.createRef<TaskAssignmentsCardRef>();

  const [task, setTask] = React.useState<Task>();
  const [parentTask, setParentTask] = React.useState<Task | null>();
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [completeDialogOpen, setCompleteDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [checklistEditIdx, setChecklistEditIdx] = React.useState<number>();
  const [checklistDeleteIdx, setChecklistDeleteIdx] = React.useState<number>();
  const [userDialogOpen, setUserDialogOpen] = React.useState(false);
  const [userDeleteId, setUserDeleteId] = React.useState<number>();

  const hasEditAccess = React.useMemo(
    () => context.authUser!.roles.includes('MANAGE_USERS'),
    [context.authUser],
  );

  const dueTimeText = React.useMemo(() => {
    if (!task?.dueTime) {
      return null;
    }
    if (task.type === 'INSTANCE') {
      return format(task.dueTime, 'MMMM dd, HH:mm');
    }
    const hours = task.dueTime / (60 * 60 * 1000);
    if (hours % 24 === 0) {
      return `${hours / 24} days`;
    }
    return `${hours} hours`;
  }, [task]);

  const checklist = React.useMemo(() => {
    if (!task) {
      return [];
    }
    return task.checklist.map((item, id) => ({item, id}));
  }, [task]);

  const loadTask = async () => {
    setTask(undefined);
    try {
      const response = await apiCall(`/location/tasks/${taskId}`, 'GET');
      setTask(response);
    } catch (e: any) {
      if (e instanceof APIError && e.code === 'not_found') {
        navigate(`${context.navigationBase}/tasks`);
      }
    }
  };

  const loadParentTask = async () => {
    if (!task || !task.parentTaskId) return;
    setParentTask(undefined);
    try {
      const response = await apiCall(
        `/location/tasks/${task.parentTaskId}`,
        'GET',
      );
      setParentTask(response);
    } catch (e: any) {
      if (e instanceof APIError && e.code === 'not_found') {
        setParentTask(null);
      }
    }
  };

  const handleEditDialogClose = (reload: boolean) => {
    setEditDialogOpen(false);
    setCompleteDialogOpen(false);
    setChecklistEditIdx(undefined);
    setChecklistDeleteIdx(undefined);
    if (reload) {
      loadTask();
    }
  };

  const handleDeleteDialogClose = (success: boolean) => {
    setDeleteDialogOpen(false);
    if (success) {
      navigate(`${context.navigationBase}/tasks`, {replace: true});
    }
  };

  const handleCompleteDialogClose = handleEditDialogClose;
  const handleChecklistDialogClose = handleEditDialogClose;
  const handleChecklistDeleteDialogClose = handleEditDialogClose;

  const handleChecklistDeleteDialogConfirm = async () => {
    if (!task) {
      return;
    }
    const checklist = task.checklist.filter(
      (_, idx) => idx !== checklistDeleteIdx,
    );
    const request: UpdateTaskRequest = {checklist};
    await apiCall(`/location/tasks/${task.id}`, 'POST', request);
  };

  const handleUserEditDialogClose = (reload: boolean) => {
    setUserDialogOpen(false);
    setUserDeleteId(undefined);
    if (reload && ref.current) {
      ref.current.reload();
    }
  };

  const handleUserDeleteDialogClose = handleUserEditDialogClose;

  React.useEffect(() => {
    loadParentTask();
  }, [task]);

  React.useEffect(() => {
    loadTask();
  }, []);

  if (!task) {
    return (
      <Box sx={{textAlign: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Card
        title={task.name}
        padding
        button={task.type === 'INSTANCE' && 'Mark Complete'}
        buttonOnClick={() => setCompleteDialogOpen(true)}
        menu={
          hasEditAccess && [
            {label: 'Edit', onClick: () => setEditDialogOpen(true)},
            {label: 'Delete', onClick: () => setDeleteDialogOpen(true)},
          ]
        }>
        <Details label="Status">
          <TaskStatusView task={task} />
        </Details>
        <Details label="Description">{task.description}</Details>
        {task.type === 'REPEATING' && (
          <Details label="Repeat At">
            <TaskRepeatView task={task} />
          </Details>
        )}
        {task.type === 'INSTANCE' && (
          <Details label="Assigned At">
            {format(task.assignmentTime!, 'MMMM dd, HH:mm')}
          </Details>
        )}
        {task.parentTaskId && (
          <Details label="Parent Task">
            {parentTask === undefined && <em>Loading</em>}
            {parentTask === null && <em>Not found</em>}
            {parentTask && (
              <Link to={`${context.navigationBase}/tasks/${parentTask.id}`}>
                {parentTask.name}
              </Link>
            )}
          </Details>
        )}
        <Details label="Due Time">{dueTimeText}</Details>
      </Card>

      <Card
        sx={{mt: 2}}
        title="Checklist"
        button={hasEditAccess && 'Add'}
        buttonOnClick={() => setChecklistEditIdx(-1)}>
        <DataTable<(typeof checklist)[0]>
          rows={checklist}
          columns={[
            {
              field: 'item',
              headerName: 'Name',
              flex: 2,
              minWidth: 300,
            },
            {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 2,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={1}
                  showInMenu
                  label="Edit"
                  onClick={() => setChecklistEditIdx(params.row.id)}
                />,
                <GridActionsCellItem
                  key={0}
                  showInMenu
                  label="Delete"
                  onClick={() => setChecklistDeleteIdx(params.row.id)}
                />,
              ],
            },
          ]}
        />
      </Card>

      <TaskAssignmentsCard
        ref={ref}
        sx={{mt: 2}}
        title="Collaborators"
        task={task}
        button={hasEditAccess && 'Add'}
        buttonOnClick={() => setUserDialogOpen(true)}
        onDelete={hasEditAccess && (user => setUserDeleteId(user.id))}
      />

      <TaskDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        task={task}
      />
      <CompleteDialog
        open={completeDialogOpen}
        onClose={handleCompleteDialogClose}
        task={task}
      />
      <ChecklistDialog
        open={checklistEditIdx !== undefined}
        onClose={handleChecklistDialogClose}
        task={task}
        itemIdx={checklistEditIdx ?? -1}
      />
      <UserDialog
        open={userDialogOpen}
        onClose={handleUserDeleteDialogClose}
        task={task}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Task"
        text="Are you sure you want to delete this task?"
        onClose={handleDeleteDialogClose}
        apiLink={`/location/tasks/${task.id}`}
      />
      <ConfirmDialog
        open={checklistDeleteIdx !== undefined}
        title="Delete Item"
        text="Are you sure you want to delete this item?"
        onClose={handleChecklistDeleteDialogClose}
        onConfirm={handleChecklistDeleteDialogConfirm}
      />
      <ConfirmDialog
        open={userDeleteId !== undefined}
        title="Delete Assignment"
        text="Are you sure you want to delete this assignment?"
        onClose={handleUserDeleteDialogClose}
        apiLink={`/location/taskUsers/${userDeleteId}`}
      />
    </>
  );
}
