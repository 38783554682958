import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import MuiButton, {type ButtonProps as MuiProps} from '@mui/material/Button';
import {ThemeProvider, createTheme} from '@mui/material/styles';

import {materialThemeOverride} from 'assets/jss/main';

export interface ButtonProps extends MuiProps {
  loading?: boolean;
}

const theme = createTheme({
  ...materialThemeOverride,
  palette: {
    ...materialThemeOverride.palette,
    secondary: {
      main: '#FFFFFF',
    },
  },
});

export default function Button(props: ButtonProps) {
  const {loading, disabled, ...rest} = props;

  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        size="large"
        disabled={disabled || loading}
        endIcon={
          loading ? <CircularProgress size={12} color="inherit" /> : null
        }
        {...rest}
      />
    </ThemeProvider>
  );
}
