import React from 'react';

import Typography from '@mui/material/Typography';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';
import Dialog from 'components/Dialog';
import SearchAutocomplete from 'components/SearchAutocomplete';

import {apiCall} from 'functions/api';
import {useFormBuilderContext} from 'functions/context';
import {getAPIBases} from 'functions/forms';

import type {
  FormRole,
  Form,
  FormType,
  ListRolesRequest,
  InsertFormRequest,
  FormRolePortal,
} from 'types/forms';
import type {Portal} from 'types/auth';
import type {ListResponse} from 'types/utils';

export interface DetailDialogProps {
  open: boolean;
  portal?: Portal;
  form?: Form;
  type: FormType;
  autoAssign?: FormRolePortal;
  template?: Form;
  onClose: (reload: boolean, form?: Form) => void;
}

function FormDialog(props: DetailDialogProps) {
  const [name, setName] = React.useState(initialValue('text'));
  const [description, setDescription] = React.useState(initialValue('any'));
  const [roles, setRoles] = React.useState<FormRole[]>();
  const [roleValues, setRoleValues] = React.useState<any>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();

  const context = useFormBuilderContext();
  const portal = React.useMemo(
    () => props.portal || context?.portal,
    [props.portal, context?.portal],
  );

  const error = React.useMemo(() => {
    if (!name.success || !description.success) {
      return true;
    }
    if (props.template) {
      if (!roles?.length) {
        return true;
      }
      return roles.some((role, index) =>
        role.portal === 'EXTERNAL_USER'
          ? !roleValues[index].success
          : roleValues[index] === undefined,
      );
    }
    return false;
  }, [name, description, roles, roleValues, props.template]);

  const [formsAPIBase, rolesAPIBase] = React.useMemo(
    () => getAPIBases(portal),
    [portal],
  );

  const title = React.useMemo(() => {
    if (props.template) {
      return 'Assign Form';
    }
    if (props.form) {
      return props.form.type === 'TEMPLATE'
        ? 'Edit Template'
        : 'Edit Instant Form';
    }
    return props.type === 'TEMPLATE'
      ? 'Create Template'
      : 'Create Instant Form';
  }, [props.form, props.template, props.type]);

  const handleClose = () => {
    if (submitting) return;
    props.onClose(false);
  };

  const handleSubmit = () => {
    if (submitting || error) return;
    setSubmitting(true);
    const apiLink = props.form
      ? `${formsAPIBase}/${props.form.id}`
      : formsAPIBase;
    const request: Partial<InsertFormRequest> = {
      name: name.value,
      description: description.value,
    };
    if (props.template) {
      request.formId = props.template.id;
      request.type = 'INSTANCE';
      request.roles = roles!.map((role, index) => ({
        roleId: role.id,
        email:
          role.portal === 'EXTERNAL_USER' ? roleValues[index].value : undefined,
        userId:
          role.portal !== 'EXTERNAL_USER' ? roleValues[index].id : undefined,
      }));
    }
    if (!props.form) {
      request.type = props.type;
    }
    if (props.autoAssign) {
      request.autoAssign = props.autoAssign;
    }
    apiCall(apiLink, 'POST', request).then(
      (response: Form) => {
        setSubmitting(false);
        props.onClose(true, response);
      },
      errorResponse => {
        setSubmitting(false);
        setServerError(errorResponse.message || 'An error occurred');
      },
    );
  };

  const handleRoleValueChange = (index: number, value: any) => {
    const newValues = [...roleValues];
    newValues[index] = value;
    setRoleValues(newValues);
  };

  React.useEffect(() => {
    if (props.open) {
      setName(initialValue('text', props.form?.name || ''));
      setDescription(initialValue('any', props.form?.description || ''));
    }
  }, [props.open, props.template, props.form, portal]);

  React.useEffect(() => {
    if (props.template) {
      setRoles(undefined);
      const request: ListRolesRequest = {
        formId: props.template.id,
      };
      apiCall(rolesAPIBase, 'GET', request).then(
        (response: ListResponse<FormRole>) => {
          setRoles(response.data);
          setRoleValues(
            response.data.map(role =>
              role.portal === 'EXTERNAL_USER'
                ? initialValue('email')
                : undefined,
            ),
          );
        },
        console.error,
      );
    }
  }, [props.template]);

  return (
    <Dialog
      title={title}
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={props.open}
      errorMessage={serverError}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled: error || submitting,
        },
      ]}>
      <ValidatedInput
        type="text"
        value={name}
        onChange={setName}
        label="Name"
      />
      <ValidatedInput
        type="any"
        value={description}
        onChange={setDescription}
        label="Description"
      />
      {props.autoAssign && (
        <Typography mt={1}>
          This form will be automatically assigned to all new{' '}
          {props.autoAssign === 'CLIENT' ? 'clients' : 'users'}.
        </Typography>
      )}
      {!!roles && !roles.length && (
        <Typography mt={1}>
          You cannot create an assignment of this form because it has no roles.
        </Typography>
      )}
      {!!roles && !!roles.length && (
        <>
          <Typography mt={1} fontSize={14}>
            Roles
          </Typography>
          {roles.map((role, key) => {
            if (role.portal === 'EXTERNAL_USER') {
              return (
                <ValidatedInput
                  key={key}
                  type="email"
                  label={`${role.name} (Email Address)`}
                  value={roleValues[key]}
                  onChange={value => handleRoleValueChange(key, value)}
                />
              );
            }
            return (
              <SearchAutocomplete
                key={key}
                portal="location"
                type={role.portal === 'CLIENT' ? 'client' : 'user'}
                label={`${role.name} (${
                  role.portal === 'CLIENT' ? 'Client' : 'User'
                })`}
                value={roleValues[key]}
                onChange={value => handleRoleValueChange(key, value)}
              />
            );
          })}
        </>
      )}
    </Dialog>
  );
}

export default FormDialog;
