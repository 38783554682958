import React from 'react';
import Box from '@mui/material/Box';
import TickIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';

interface Props {
  label: React.ReactNode;
  children?: React.ReactNode;
  disableNone?: boolean;
  items?: string[];
}

function Details(props: Props) {
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        fontWeight: 400,
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      })}>
      <Box
        sx={{
          color: '#888888',
          flex: '0 0 250px',
          marginTop: '5px',
          marginBottom: '5px',
        }}>
        {props.label}
      </Box>
      <Box
        sx={{
          flex: '1 1 0px',
          marginTop: '5px',
          marginBottom: '5px',
        }}>
        {props.items?.map((item, key) => (
          <Typography variant="subtitle2" key={key}>
            <TickIcon
              sx={{verticalAlign: 'middle', fontSize: 16, opacity: 0.9}}
            />
            &nbsp;{item}
          </Typography>
        ))}
        {props.children}
        {!props.items?.length && !props.children && !props.disableNone && (
          <em>None</em>
        )}
      </Box>
    </Box>
  );
}

export default Details;
