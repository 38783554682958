import React from 'react';

import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {APIError, apiCall} from 'functions/api';

import type {Task, UpdateTaskRequest} from 'types/tasks';

interface Props {
  open: boolean;
  onClose: (reload: boolean) => void;
  itemIdx: number;
  task: Task;
}

export default function ChecklistDialog(props: Props) {
  const [item, setItem] = React.useState(initialValue('text'));
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();

  const error = !item.success;

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = async () => {
    if (submitting) {
      return;
    }
    setServerError(undefined);
    setSubmitting(true);
    try {
      const request: UpdateTaskRequest = {};
      if (props.itemIdx === -1) {
        request.checklist = [...props.task.checklist, item.value];
      } else {
        request.checklist = [...props.task.checklist];
        request.checklist[props.itemIdx] = item.value;
      }
      await apiCall(`/location/tasks/${props.task.id}`, 'POST', request);
      setSubmitting(false);
      props.onClose(true);
    } catch (e: any) {
      setSubmitting(false);
      if (e instanceof APIError && e.message) {
        setServerError(e.message);
      } else {
        setServerError('An unknown error occurred.');
      }
    }
  };

  React.useEffect(() => {
    if (props.open) {
      const {task, itemIdx} = props;
      setServerError(undefined);
      if (itemIdx === -1) {
        setItem(initialValue('text'));
      } else {
        setItem(initialValue('text', task.checklist[itemIdx]));
      }
    }
  }, [props.open, props.task, props.itemIdx]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.itemIdx === -1 ? 'Add Item' : 'Edit Item'}
      errorMessage={serverError}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled: error || submitting,
        },
      ]}>
      <ValidatedInput
        label="Item"
        type="text"
        value={item}
        onChange={setItem}
      />
    </Dialog>
  );
}
