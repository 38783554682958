import React from 'react';

import {useLocationContext} from 'functions/context';

import type {ClientResponse} from 'types/clients';
import TrainingAssignmentCard from 'pages/Training/TrainingAssignments';

function ClientTrainingsPage() {
  const context = useLocationContext();
  const client: ClientResponse = context.extra.client;

  return <TrainingAssignmentCard type="clientAssign" typeId={client.id} />;
}

export default ClientTrainingsPage;
