import React from 'react';

import Box from '@mui/material/Box';
import {Rnd, RndDragCallback, RndResizeCallback} from 'react-rnd';

import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SignatureIcon from '@mui/icons-material/Edit';

import {useFormBuilderContext} from 'functions/context';
import {documentTextComponentTypes} from 'functions/forms';

import {primaryColor, warningColor} from 'assets/jss/main';

import type {SystemStyleObject, Theme} from '@mui/system';
import type {FormComponent} from 'types/forms';
import type {
  DocumentComponentProps,
  DocumentComponentType,
} from 'types/formBuilder';

interface ComponentConstraints {
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  ratio?: number;
}

interface Props {
  scale: number;
  active: boolean;
  highlight: boolean;
  disabled?: boolean;
  onClick: () => void;
  component: FormComponent;
  associatedComponent?: FormComponent;
  parentElement: Element;
  onChange: (properties: Partial<DocumentComponentProps>) => void;
}

export const defaultFontSize = 14;
export const fontSizeOptions = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28];
export const defaultInputWidth = 200;

export const defaultProps: {
  [key in DocumentComponentType]: DocumentComponentProps;
} = {
  TEXT: {
    width: defaultInputWidth,
    fontSize: defaultFontSize,
    x: 0,
    y: 0,
  },
  EMAIL: {
    width: defaultInputWidth,
    fontSize: defaultFontSize,
    x: 0,
    y: 0,
  },
  DATE: {
    width: defaultInputWidth,
    fontSize: defaultFontSize,
    x: 0,
    y: 0,
  },
  LONG_TEXT: {
    width: defaultInputWidth,
    height: 100,
    fontSize: defaultFontSize,
    x: 0,
    y: 0,
  },
  CHECKBOX: {
    width: 30,
    height: 30,
    x: 0,
    y: 0,
  },
  SIGNATURE: {
    width: 200,
    height: 100,
    x: 0,
    y: 0,
  },
};

const getSizeFromCSSString = (css: string) =>
  parseInt(css.replace('px', '').replace(' ', ''), 10);

function DocumentComponentView(props: Props) {
  const context = useFormBuilderContext()!;
  const {component, associatedComponent, active, highlight} = props;
  const componentProps: DocumentComponentProps = {
    ...associatedComponent?.properties,
    ...component.properties,
  };
  const required = associatedComponent
    ? associatedComponent.isRequired
    : component.isRequired;

  const handleClick = (e: any) => {
    if (context.editable && !props.disabled) {
      e.stopPropagation();
      props.onClick();
    }
  };

  const handleDragStop: RndDragCallback = (e, d) => {
    handleClick(e);
    props.onChange({
      x: d.x,
      y: d.y,
    });
  };

  const handleResize: RndResizeCallback = (
    e,
    direction,
    ref,
    delta,
    position,
  ) => {
    setTimeout(() => {
      handleClick(e);
    }, 1);
    props.onChange({
      width: getSizeFromCSSString(ref.style.width),
      height: getSizeFromCSSString(ref.style.height),
      ...position,
    });
  };

  const commonProps = {
    onClick: handleClick,
  };
  const commonStyles: SystemStyleObject<Theme> = {
    backgroundColor: '#FFFFFFAA',
    height: '100%',
    width: '100%',
    display: 'block',
  };
  const constraints: ComponentConstraints = {};
  let height = componentProps.height || 0;
  let componentView: React.ReactNode = null;

  if (active) {
    commonStyles.outline = `2px solid ${primaryColor[2]}`;
  } else if (highlight) {
    commonStyles.outline = `1px solid ${warningColor[2]}`;
  }

  if (documentTextComponentTypes.includes(component.type as any)) {
    let text = 'Text';
    if (component.type === 'LONG_TEXT') {
      text = 'Long Text';
    } else if (component.type === 'EMAIL') {
      text = 'Email';
    } else if (component.type === 'DATE') {
      text = 'Date';
    }
    text += '...';
    if (required) {
      text += ' *';
    }
    componentView = (
      <Box
        sx={{
          ...commonStyles,
          padding: '3px',
          boxSizing: 'border-box',
          fontSize: `${componentProps.fontSize!}px`,
          lineHeight: `${componentProps.fontSize!}px`,
          color: '#777777',
          outline: (commonStyles.outline as string) || '1px solid #aaaaaa',
        }}
        {...commonProps}>
        {text}
      </Box>
    );
    if (component.type !== 'LONG_TEXT') {
      height = componentProps.fontSize! + 6;
      constraints.minHeight = height;
      constraints.maxHeight = height;
    }
  } else if (component.type === 'CHECKBOX') {
    componentView = <CheckBoxIcon sx={{...commonStyles}} {...commonProps} />;
    constraints.ratio = 1;
  } else {
    componentView = (
      <Box
        sx={{
          ...commonStyles,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#444444',
          outline: (commonStyles.outline as string) || '1px solid #aaaaaa',
        }}
        {...commonProps}>
        <SignatureIcon />
        Signature {required && '*'}
      </Box>
    );
  }

  return (
    <Rnd
      size={{width: componentProps.width, height}}
      scale={props.scale}
      bounds={props.parentElement}
      lockAspectRatio={constraints.ratio}
      minHeight={constraints.minHeight}
      maxHeight={constraints.maxHeight}
      minWidth={constraints.minWidth}
      maxWidth={constraints.maxWidth}
      position={{x: componentProps.x, y: componentProps.y}}
      onResize={handleClick}
      onDrag={handleClick}
      enableResizing={context.editable && !props.disabled}
      disableDragging={!context.editable || props.disabled}
      onDragStop={handleDragStop}
      onResizeStop={handleResize}>
      {componentView}
    </Rnd>
  );
}

export default DocumentComponentView;
