import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useLocationContext} from 'functions/context';

import FormAssignmentsCard from 'pages/FormBuilder/FormAssignments';

import type {UserResponse} from 'types/users';
import type {Form} from 'types/forms';

function UserFormsPage() {
  const context = useLocationContext();
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const user: UserResponse = context.extra.user;

  const handleAssignmentClick = (_form: Form) => {
    let url = `${context.navigationBase}/forms/${_form.id}/builder`;
    url += `?from=${encodeURIComponent(pageLocation.pathname)}`;
    navigate(url);
  };

  return (
    <FormAssignmentsCard
      title="Form Assignments"
      userId={user.id}
      portal="location"
      onRowClick={handleAssignmentClick}
    />
  );
}

export default UserFormsPage;
