import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import FullPageLoader from 'components/FullPageLoader';
import FormBuilderNavbar, {
  type Props as NavbarProps,
} from 'components/FormNavbar';
import ConfirmDialog from 'components/ConfirmDialog';

import {useFormBuilderContext} from 'functions/context';
import {apiCall} from 'functions/api';

import type {FormComponent, FormPage} from 'types/forms';
import type {FormBuilderPageProps} from 'types/formBuilder';
import type {ListResponse} from 'types/utils';

import PageDialog from '../Main/PageDialog';
import DocumentFormBuilder from './DocumentBuilder';
import FormBuilder from './FormBuilder';

function FormBuilderPage() {
  const context = useFormBuilderContext()!;
  const pageId = useParams<'pageId'>().pageId;
  const navigate = useNavigate();

  const [page, setPage] = React.useState<FormPage>();
  const [pageDialogOpen, setPageDialogOpen] = React.useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] =
    React.useState<boolean>(false);
  const [components, setComponents] = React.useState<FormComponent[]>();
  const [navbarProps, setNavbarProps] = React.useState<NavbarProps>({
    title: 'Edit Page',
    backLink: context.navigationBase,
    menu: context.editable
      ? [
          {label: 'Edit details', onClick: () => setPageDialogOpen(true)},
          {label: 'Delete', onClick: () => setConfirmDialogOpen(true)},
        ]
      : undefined,
  });

  const apiBase = React.useMemo(
    () => `${context.formsAPIBase}/${context.form.id}/pages/${pageId}`,
    [context.formsAPIBase, context.form, pageId],
  );

  const handlePageDialogClose = (reload: boolean, page?: FormPage) => {
    setPageDialogOpen(false);
    if (reload) {
      setPage(page!);
      setNavbarProps({
        ...navbarProps,
        title: page!.name,
      });
    }
  };

  const handleConfirmDialogClose = (reload: boolean) => {
    setConfirmDialogOpen(false);
    context.updateForm({
      ...context.form,
      order: context.form.order.filter(id => id !== page!.id),
    });
    if (reload) {
      navigate(context.navigationBase, {replace: true});
    }
  };

  React.useEffect(() => {
    apiCall(apiBase).then(
      (response: FormPage) => {
        setPage(response);
        setNavbarProps({
          ...navbarProps,
          title: response.name,
        });
      },
      e => {
        console.error(e);
        navigate(context.navigationBase, {replace: true});
      },
    );
    apiCall(`${apiBase}/components`, 'GET').then(
      (response: ListResponse<FormComponent>) => {
        setComponents(response.data);
      },
      console.error,
    );
  }, []);

  if (!page || !components) {
    return <FullPageLoader />;
  }

  const pageProps: FormBuilderPageProps = {
    page,
    components,
    apiBase: `${apiBase}`,
    navbarProps,
    updatePage: setPage,
    updateNavbarProps: setNavbarProps,
  };

  return (
    <div>
      <FormBuilderNavbar {...navbarProps} />
      {page.type === 'DOCUMENT' && <DocumentFormBuilder {...pageProps} />}
      {page.type === 'FORM' && <FormBuilder {...pageProps} />}
      <PageDialog
        open={pageDialogOpen}
        page={page}
        onClose={handlePageDialogClose}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Delete Page"
        text="Are you sure you want to delete this page?"
        onClose={handleConfirmDialogClose}
        apiLink={apiBase}
      />
    </div>
  );
}

export default FormBuilderPage;
