import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {APIError, apiCall} from 'functions/api';
import {userRoles} from 'functions/users';

import type {
  InsertUserRequest,
  UpdateUserRequest,
  UserResponse,
  UserRole,
} from 'types/users';
import type {ListResponse} from 'types/utils';
import type {FormRole, InsertFormRequest, ListRolesRequest} from 'types/forms';

interface Props {
  open: boolean;
  onClose: (success: boolean) => void;
  user?: UserResponse;
}

function UserDialog(props: Props) {
  const [firstName, setFirstName] = React.useState(initialValue('text'));
  const [lastName, setLastName] = React.useState(initialValue('text'));
  const [email, setEmail] = React.useState(initialValue('email'));
  const [dateOfBirth, setDateOfBirth] = React.useState(initialValue('date'));
  const [password, setPassword] = React.useState(initialValue('password'));
  const [repeatPassword, setRepeatPassword] = React.useState(
    initialValue('password'),
  );
  const [roles, setRoles] = React.useState<UserRole[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');

  const error = React.useMemo(
    () =>
      firstName.state !== 'success' ||
      lastName.state !== 'success' ||
      dateOfBirth.state !== 'success' ||
      email.state !== 'success' ||
      (!props.user &&
        (password.state !== 'success' ||
          repeatPassword.state !== 'success' ||
          password.value !== repeatPassword.value)),
    [firstName, lastName, dateOfBirth, email, password, repeatPassword],
  );

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleRoleChange = (role: (typeof userRoles)[0], checked: boolean) => {
    if (checked) {
      setRoles(r =>
        role.master ? userRoles.map(v => v.role) : [...r, role.role],
      );
    } else {
      setRoles(r => r.filter(x => x !== role.role));
    }
  };

  const handleSubmit = async () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError('');
    const url = props.user
      ? `/location/users/${props.user.id}`
      : '/location/users';
    const request: InsertUserRequest | UpdateUserRequest = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      dateOfBirth: dateOfBirth.value,
      password: password.value,
      roles,
    };
    if (props.user) {
      delete request.password;
    }
    try {
      const insertResponse: UserResponse = await apiCall(url, 'POST', request);
      if (!props.user) {
        const request: ListRolesRequest = {
          autoAssign: true,
          portal: 'LOCATION',
        };
        const {data: forms}: ListResponse<FormRole> = await apiCall(
          '/location/formRoles',
          'GET',
          request,
        );
        await Promise.all(
          forms.map(role => {
            const request: InsertFormRequest = {
              type: 'INSTANCE',
              formId: role.formId,
              roles: [
                {
                  roleId: role.id,
                  userId: insertResponse.id,
                },
              ],
            };
            return apiCall('/location/forms', 'POST', request);
          }),
        );
      }
      setSubmitting(false);
      props.onClose(true);
    } catch (e) {
      setSubmitting(false);
      if (e instanceof APIError) {
        setServerError('An unexpected error occurred.');
      }
    }
  };

  React.useEffect(() => {
    const {user} = props;
    if (props.open) {
      setFirstName(initialValue('text', user?.firstName));
      setLastName(initialValue('text', user?.lastName));
      setEmail(initialValue('email', user?.email));
      setDateOfBirth(initialValue('date', user?.dateOfBirth));
      setRoles(user?.roles || []);
      setPassword(initialValue('password'));
      setRepeatPassword(initialValue('password'));
    }
  }, [props.open, props.user]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.user ? 'Edit User' : 'Create User'}
      actions={[
        {
          text: 'Cancel',
          color: 'secondary',
          onClick: () => {
            handleClose();
          },
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          color: 'primary',
          submit: true,
          disabled: error || submitting,
        },
      ]}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <ValidatedInput
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            type="text"
          />
        </Grid>
        <Grid item xs={6}>
          <ValidatedInput
            label="Last Name"
            value={lastName}
            onChange={setLastName}
            type="text"
          />
        </Grid>
      </Grid>
      <ValidatedInput
        label="Email"
        value={email}
        onChange={setEmail}
        type="email"
      />
      <ValidatedInput
        label="Date of Birth"
        value={dateOfBirth}
        onChange={setDateOfBirth}
        type="date"
      />
      {props.user === undefined && (
        <>
          <ValidatedInput
            label="Password"
            value={password}
            onChange={setPassword}
            type="password"
          />
          <ValidatedInput
            label="Repeat Password"
            value={repeatPassword}
            onChange={setRepeatPassword}
            type="password"
          />
        </>
      )}
      <Typography variant="subtitle2">Roles</Typography>
      {userRoles.map((prop, k) => (
        <FormControlLabel
          key={k}
          disabled={
            !prop.master && roles.includes(userRoles.find(r => r.master)!.role)
          }
          control={
            <Checkbox
              checked={roles.includes(prop.role)}
              onChange={e => handleRoleChange(prop, e.target.checked)}
            />
          }
          label={prop.label}
        />
      ))}
      {serverError && <p style={{color: 'red'}}>{serverError}</p>}
    </Dialog>
  );
}

export default UserDialog;
