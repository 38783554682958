import React, {useEffect, useMemo, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Dialog from 'components/Dialog';
import Dropzone from 'components/Dropzone';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {apiCall, createAndUploadFile} from 'functions/api';
import {useTrainingBuilderContext} from 'functions/context';
import type {
  InsertTrainingContentRequest,
  TrainingContent,
} from 'types/trainings';

const STORAGE_BASE = process.env.REACT_APP_GOOGLE_STORAGE_LINK;

interface Props {
  open: boolean;
  onClose: (submit?: TrainingContent) => void;
  content?: TrainingContent;
  type: TrainingContent['type'];
}
export const contentType: Array<{
  value: TrainingContent['type'];
  label: string;
}> = [
  {value: 'DOCUMENT', label: 'Document'},
  {value: 'VIDEO', label: 'Youtube Video'},
  {value: 'QUIZ', label: 'Quiz'},
];

const YOUTUBE_REGEX =
  /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

function youtubeParser(url: string) {
  const match = url.match(YOUTUBE_REGEX);
  return match && match[7].length === 11 ? match[7] : false;
}

export default function ContentDetailDialog(props: Props) {
  const {apiBase, portal} = useTrainingBuilderContext();
  const [name, setName] = useState(initialValue('text'));
  const [description, setDescription] = useState(initialValue('text'));
  const [youtubeId, setYoutubeId] = useState(initialValue('text'));
  const [numberOfQuestions, setNumberOfQuestions] = useState(
    initialValue('number'),
  );
  const [file, setFile] = useState<File>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const disabled = useMemo(
    () =>
      !name?.success ||
      !description?.success ||
      submitting ||
      (!props.content && props.type === 'DOCUMENT' && !file) ||
      (props.type === 'VIDEO' && !youtubeId?.success) ||
      (props.type === 'QUIZ' &&
        (!numberOfQuestions.success || +numberOfQuestions.value < 1)),
    [
      name,
      description,
      submitting,
      props.type,
      file,
      props.content,
      youtubeId,
      numberOfQuestions,
    ],
  );
  let contentView: React.ReactNode = null;
  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };
  useEffect(() => {
    if (props.open) {
      setName(initialValue('text', props.content?.name || ''));
      setDescription(initialValue('text', props.content?.description || ''));
      setYoutubeId(initialValue('text', props.content?.youtubeId || ''));
      setNumberOfQuestions(
        initialValue(
          'number',
          props.content?.quiz?.numberOfQuestions.toString?.() || '',
        ),
      );
      setSubmitting(false);
      setError(undefined);
      setFile(undefined);
    }
  }, [props.open, props.content]);

  const handleYoutubeChange = (v: string) => {
    const value = youtubeParser(v);
    if (value) {
      setYoutubeId({
        state: 'success',
        success: true,
        value,
      });
    } else {
      setYoutubeId({
        state: 'error',
        success: false,
        value: '',
      });
    }
  };

  const handleSubmit = async () => {
    if (disabled) return;
    setSubmitting(true);
    setError(undefined);
    const request: InsertTrainingContentRequest = {
      name: name.value,
      description: description.value,
      type: props.type,
    };
    if (props.content) {
      delete (request as any).type;
    }
    if (props.type === 'DOCUMENT' && file) {
      try {
        const gcsFileName = await createAndUploadFile(
          file,
          portal,
          'trainingDocument',
          () => {},
        );
        request.file = gcsFileName;
      } catch (e: any) {
        setSubmitting(false);
        setError(e?.message || 'An error occurred');
        return;
      }
    }
    if (props.type === 'VIDEO') {
      request.youtubeId = youtubeId.value;
    }
    if (props.type === 'QUIZ') {
      request.quiz = {
        numberOfQuestions: +numberOfQuestions.value,
        questions: props.content?.quiz?.questions || [],
      };
    }
    apiCall(
      `${apiBase}/contents/${props.content?.id || ''}`,
      'POST',
      request,
    ).then(
      (_content: TrainingContent) => {
        props.onClose(_content);
        setSubmitting(false);
      },
      ({message}) => {
        setError(message || 'An error occurred.');
        setSubmitting(false);
      },
    );
  };
  if (props.type === 'DOCUMENT') {
    contentView = (
      <>
        <Typography mt={1} fontSize={14} lineHeight={2}>
          File
          {!!props.content?.file && (
            <Button
              variant="text"
              sx={{float: 'right', py: 0}}
              size="small"
              href={STORAGE_BASE + props.content!.file}
              target="_blank">
              View
            </Button>
          )}
        </Typography>
        <Dropzone type="document" onDrop={handleDrop} file={file} />
      </>
    );
  } else if (props.type === 'VIDEO') {
    contentView = (
      <Box display="flex" gap={1} mt={1}>
        <ValidatedInput
          sx={{mt: 0}}
          type={YOUTUBE_REGEX}
          value={youtubeId}
          onChange={({value}) => handleYoutubeChange(value)}
          label="Youtube Id (Paste youtube link)"
          helperText="Example: https://www.youtube.com/watch?v=XXXXXXXXXXX"
          InputProps={{
            endAdornment: (
              <Button
                size="small"
                variant="outlined"
                onClick={async () => {
                  await window.navigator.clipboard
                    .readText()
                    .then(handleYoutubeChange);
                }}>
                Paste
              </Button>
            ),
          }}
        />
        <img
          src={`https://i3.ytimg.com/vi/${youtubeId.value}/hqdefault.jpg`}
          alt="Thumbnail"
          style={{
            height: 75,
            width: 177,
            objectFit: 'cover',
            borderRadius: 5,
          }}
        />
      </Box>
    );
  } else {
    contentView = (
      <ValidatedInput
        label="Number of Questions"
        type="number"
        value={numberOfQuestions}
        onChange={setNumberOfQuestions}
      />
    );
  }

  return (
    <Dialog
      width="sm"
      title="Content Detail"
      onClose={props.onClose}
      onSubmit={handleSubmit}
      open={props.open}
      actions={[
        {
          text: 'Cancel',
          onClick: () => props.onClose(),
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled,
        },
      ]}>
      <ValidatedInput
        type="text"
        value={name}
        onChange={setName}
        label="Name"
      />
      <ValidatedInput
        type="text"
        value={description}
        onChange={setDescription}
        label="Description"
        multiline
        rows={2}
      />
      {contentView}
      {error && (
        <Typography color="error" mt={1} fontSize={14}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}
