import React, {Fragment, useEffect, useMemo, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import Add from '@mui/icons-material/Add';
import Close from '@mui/icons-material/Close';

import type {TrainingContent, TrainingQuizQuestion} from 'types/trainings';
import {useTrainingBuilderContext} from 'functions/context';
import {apiCall} from 'functions/api';

const sampleQuizQuestion: TrainingQuizQuestion = {
  question: '',
  type: 'MCQ',
  correctAnswer: 0,
  options: ['', ''],
};

export type QuizBuilderProps = {
  quiz?: TrainingContent['quiz'];
  setContent: (content: TrainingContent) => void;
  contentId: number;
  editable: boolean;
};
export default function QuizBuilder(props: QuizBuilderProps) {
  const {apiBase} = useTrainingBuilderContext();

  const [questions, setQuestions] = useState<TrainingQuizQuestion[]>([
    sampleQuizQuestion,
  ]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setQuestions(props?.quiz?.questions || [sampleQuizQuestion]);
  }, [props.quiz]);

  const isValid = useMemo(
    () =>
      !(questions.length === 0) &&
      !questions
        .map(
          q => !!q.question && q.options.length > 1 && !q.options.includes(''),
        )
        .includes(false),
    [questions],
  );

  const setQuestion =
    (k: number) => (question: Partial<TrainingQuizQuestion>) => {
      setQuestions(
        questions.map((q, i) => (i === k ? {...q, ...question} : q)),
      );
    };

  const handleSubmit = () => {
    setSubmitting(true);
    apiCall(`${apiBase}/contents/${props.contentId}`, 'POST', {
      quiz: {...props.quiz!, questions},
    }).then(content => {
      setSubmitting(false);
      props.setContent(content);
    });
  };
  return (
    <Box display="flex" flexDirection="column" sx={{maxWidth: '400px'}} gap={2}>
      {questions.map((q, k) => (
        <Fragment key={k}>
          <TextField
            sx={{mt: 0}}
            fullWidth
            size="small"
            label={`Question ${k + 1}`}
            disabled={!props.editable}
            onChange={e => {
              setQuestion(k)({question: e.target.value});
            }}
            autoFocus
            value={q.question}
          />
          <Grid container spacing={1}>
            {q.options.map((o, i) => (
              <Grid item xs={12} key={i}>
                <TextField
                  sx={{mt: 0, '& input': {px: 1, py: 1.5}}}
                  disabled={!props.editable}
                  size="small"
                  value={o}
                  placeholder={`Option ${i + 1}`}
                  onChange={e => {
                    setQuestion(k)({
                      options: q.options.map((v, j) =>
                        j === i ? e.target.value : v,
                      ),
                    });
                  }}
                  InputProps={{
                    startAdornment: (
                      <Tooltip title="Mark Correct">
                        <Radio
                          checked={q.correctAnswer === i}
                          disabled={!props.editable}
                          size="small"
                          sx={{p: 0}}
                          onClick={() => {
                            setQuestion(k)({correctAnswer: i});
                          }}
                        />
                      </Tooltip>
                    ),
                    endAdornment: (
                      <IconButton
                        size="small"
                        disabled={!props.editable}
                        onClick={() => {
                          setQuestion(k)({
                            options: q.options.filter((_, j) => j !== i),
                          });
                        }}>
                        <Close fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>
          {props.editable && (
            <Box sx={{display: 'flex', gap: 1}}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setQuestion(k)({
                    options: [...q.options, ''],
                  });
                }}
                sx={{textTransform: 'capitalize'}}>
                Add Option
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="error"
                onClick={() => {
                  setQuestions(questions.filter((_, i) => i !== k));
                }}>
                Remove
              </Button>
            </Box>
          )}
        </Fragment>
      ))}
      {props.editable && (
        <>
          <ButtonBase
            sx={{
              p: 0.7,
              width: '100%',
              borderRadius: 2,
              fontSize: 15,
              backgroundColor: '#e3e3e3',
            }}
            onClick={() => {
              setQuestions([...questions, sampleQuizQuestion]);
            }}>
            <Add />
            Add Question
          </ButtonBase>
          <Button disabled={!isValid || submitting} onClick={handleSubmit}>
            Save
          </Button>
        </>
      )}
    </Box>
  );
}
