import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';

import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import {type Route} from 'types/routes';
import {activeRoute} from 'functions/utils';

import {containerSx, grayColor} from 'assets/jss/main';
import {clearAuthCookie} from 'functions/api';

export interface NavbarProps {
  handleDrawerToggle: () => void;
  base: string;
  routes: Route[];
  settingsLink?: boolean;
}

function Navbar(props: NavbarProps) {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState<Element | undefined>();

  const page = React.useMemo(
    () =>
      props.routes.find(route =>
        activeRoute(`${props.base}/${route.path}`, location),
      ),
    [location, props.base],
  );
  const closeMenu = () => setMenuOpen(undefined);

  const handleLogout = () => {
    closeMenu();
    clearAuthCookie();
    window.location.reload();
  };

  return (
    <AppBar
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: grayColor[7],
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
      }}>
      <Toolbar
        sx={{
          ...containerSx,
          minHeight: '50px',
          justifyContent: 'space-between',
        }}>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              sx={{mr: 1}}
              onClick={props.handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Box
            sx={{
              letterSpacing: 'unset',
              lineHeight: '30px',
              fontSize: '20px',
              textTransform: 'none',
              color: 'inherit',
              fontWeight: '200',
              margin: '0',
              '&:hover,&:focus': {
                background: 'transparent',
              },
            }}>
            {page?.name}
          </Box>
        </Box>
        <IconButton
          color="inherit"
          onClick={e => {
            setMenuOpen(e.currentTarget);
          }}>
          <PersonIcon fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={menuOpen}
          open={!!menuOpen}
          onClose={closeMenu}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          {props.settingsLink && (
            <Link to="settings">
              <MenuItem
                dense
                color="primary"
                sx={{color: theme => theme.palette.text.primary}}
                LinkComponent="a"
                onClick={closeMenu}>
                Settings
              </MenuItem>
            </Link>
          )}
          <MenuItem dense color="primary" onClick={handleLogout}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
