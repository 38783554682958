import React from 'react';

import Typography from '@mui/material/Typography';
import {GridActionsCellItem} from '@mui/x-data-grid';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {ListResponse} from 'types/utils';
import {
  Document,
  DocumentCategory,
  DocumentCategoryPortal,
} from 'types/documents';

import {apiCall} from 'functions/api';
import {formatDate} from 'functions/utils';

import {Portal} from 'types/auth';
import UploadDocumentDialog, {UploadDocumentDialogProps} from './UploadDialog';

const STORAGE_BASE = process.env.REACT_APP_GOOGLE_STORAGE_LINK;

export type DocumentCardProps = {
  portal: Portal;
  categoryPortal: DocumentCategoryPortal;
  filterNone?: boolean;
  userId: number;
};

type Doc = {document?: Document} & DocumentCategory;

const categoryPath: {[k in Portal]: string} = {
  location: '/location/documentCategories',
  client: '/client/documentCategories',
  admin: '',
};
const documentPath: {[k in Portal]: string} = {
  location: '/location/documents',
  client: '/client/documents',
  admin: '',
};

export default function DocumentCard({
  portal,
  filterNone,
  categoryPortal,
  userId,
}: DocumentCardProps) {
  const [documents, setDocuments] = React.useState<Doc[]>();
  const [open, setOpen] =
    React.useState<UploadDocumentDialogProps['document']>();

  React.useEffect(() => {
    if (documents !== undefined) return;
    const params = {portal: categoryPortal};
    apiCall(categoryPath[portal], 'GET', params).then(
      (res: ListResponse<DocumentCategory>) => {
        if (!filterNone) {
          res.data = res.data.filter(v => v.access !== 'NONE');
        }
        apiCall(documentPath[portal], 'GET', {...params, userId}).then(
          (docs: ListResponse<Document>) => {
            setDocuments(
              res.data.map(v => ({
                ...v,
                document: docs.data.find(doc => doc.categoryId === v.id),
              })),
            );
          },
        );
      },
    );
  }, [documents]);

  const handleClose = (submit?: boolean) => {
    if (submit) {
      setDocuments(undefined);
    }
    setOpen(undefined);
  };

  return (
    <>
      <Card title="Documents">
        <DataTable<Doc>
          loading={!documents}
          rows={documents}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 100,
              flex: 1,
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Description',
              minWidth: 100,
              flex: 1,
              sortable: false,
            },
            {
              field: 'uploaded',
              headerName: 'Uploaded At',
              minWidth: 100,
              flex: 1,
              sortable: false,
              renderCell: ({row}) =>
                row.document?.uploadedAt
                  ? formatDate(row.document.uploadedAt)
                  : '-',
            },
            {
              field: 'status',
              headerName: 'Status',
              minWidth: 100,
              flex: 1,
              sortable: false,
              renderCell: ({row}) => {
                if (!row.document) return 'Not Uploaded';
                if (
                  row.document.expiryDate &&
                  new Date(row.document.expiryDate) < new Date()
                )
                  return <Typography color="error">Expired</Typography>;

                return 'Available';
              },
            },
            {
              field: 'expiry',
              headerName: 'Expiry Date',
              minWidth: 100,
              flex: 1,
              sortable: false,
              renderCell: ({row}) =>
                row.document?.expiryDate
                  ? formatDate(row.document.expiryDate)
                  : '-',
            },
            {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 1,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={0}
                  showInMenu
                  label="View Document"
                  disabled={!params.row.document?.file}
                  onClick={() =>
                    params.row.document?.file &&
                    window.open(STORAGE_BASE + params.row.document.file)
                  }
                />,
                <GridActionsCellItem
                  key={2}
                  showInMenu
                  label="Upload Document"
                  disabled={!(filterNone || params.row.access === 'UPLOAD')}
                  onClick={() => {
                    setOpen({
                      userId,
                      categoryId: params.row.id,
                      id: params.row.document?.id,
                    });
                  }}
                />,
              ],
            },
          ]}
        />
      </Card>
      <UploadDocumentDialog
        document={open}
        onClose={handleClose}
        portal={portal}
      />
    </>
  );
}
