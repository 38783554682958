import React from 'react';
import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';

import TrainingAssignmentCard, {
  TrainingAssignmentCardProps,
} from 'pages/Training/TrainingAssignments';

export default function TrainingAssignmentsPage() {
  const {trainingId} = useParams<'trainingId'>();

  const [tab, setTab] = React.useState<TrainingAssignmentCardProps['type']>(
    'trainingAssignClient',
  );

  if (!trainingId) {
    return null;
  }
  return (
    <Box>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab label="Clients" value="trainingAssignClient" />
        <Tab label="Users" value="trainingAssignUser" />
      </Tabs>
      <Divider sx={{mb: 2}} />
      <TrainingAssignmentCard type={tab} typeId={parseInt(trainingId, 10)} />
    </Box>
  );
}
