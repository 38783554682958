import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';

import Article from '@mui/icons-material/Article';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import Button from 'components/Button';
import Card from 'components/Card';
import Details from 'components/Details';
import FormNavbar from 'components/FormNavbar';
import ConfirmDialog from 'components/ConfirmDialog';
import Dialog from 'components/Dialog';

import {dangerColor, successColor} from 'assets/jss/main';
import {useFormFillContext, useLocationContext} from 'functions/context';
import {storageBase} from 'functions/uploader';
import {apiCall} from 'functions/api';

import type {Form, FormPage, MarkCompleteResponse} from 'types/forms';
import type {ListResponse} from 'types/utils';

export default function FormMain() {
  const context = useFormFillContext();
  const locationContext = useLocationContext();
  const navigate = useNavigate();
  const [pages, setPages] = React.useState<FormPage[]>();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [PDFDialogOpen, setPDFDialogOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [serverError, setServerError] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const pdfURL = context.form.pdf ? `${storageBase}${context.form.pdf}` : '#';

  const loadPages = () => {
    setPages(undefined);
    const apiLink = `${context.formsAPIBase}/${context.form.id}/pages`;
    apiCall(apiLink, 'GET').then((response: ListResponse<FormPage>) => {
      const pages = response.data;
      const order = [...context.form.order];
      pages.forEach(page => !order.includes(page.id) && order.push(page.id));
      const ordered: Array<FormPage> = order.map(
        id => pages.find(page => page.id === id)!,
      );
      setPages(ordered);
    }, console.error);
  };

  const handleConfirmDialogClose = async (successful: boolean) => {
    if (submitting) {
      return;
    }
    if (!successful) {
      setConfirmDialogOpen(false);
      setServerError('');
      return;
    }
    setSubmitting(true);
    setServerError('');
    try {
      const apiLink = `${context.formsAPIBase}/${context.form.id}/complete`;
      const response: MarkCompleteResponse = await apiCall(apiLink, 'POST');
      if ((context.myRole && response.roleComplete) || response.formComplete) {
        await Promise.all([context.loadForm(), context.loadRoles()]);
        setSubmitting(false);
        setConfirmDialogOpen(false);
        if (context.myRole && response.roleComplete && !response.formComplete) {
          setMessage(
            'Your part is complete, but some parts of the form that are ' +
              'to be filled by other users are still incomplete.',
          );
        }
      } else {
        setSubmitting(false);
        setServerError('Some fields are still incomplete.');
      }
    } catch (e: any) {
      console.error(e);
      setSubmitting(false);
      setServerError(e?.message || 'Something went wrong.');
    }
  };

  const handlePDFClick = async () => {
    if (context.form.pdf) {
      window.open(pdfURL, '_blank');
      return;
    }
    setSubmitting(true);
    const apiLink = `${context.formsAPIBase}/${context.form.id}/pdf`;
    try {
      const response: Form = await apiCall(apiLink, 'POST');
      context.updateForm(response);
      setSubmitting(false);
      setPDFDialogOpen(true);
    } catch (e) {
      console.error(e);
      setSubmitting(false);
      setMessage('Cannot create PDF for this form.');
    }
  };

  React.useEffect(loadPages, [context.form]);

  let statusText: React.ReactNode = null;
  if (context.form.status === 'COMPLETE') {
    statusText = (
      <Typography color={successColor[0]}>Form is complete</Typography>
    );
  } else if (context.myRole?.status === 'COMPLETE') {
    statusText = (
      <Typography>
        Your part is complete. Some parts required to be filled by other users
        are not filled.
      </Typography>
    );
  } else {
    statusText = <Typography color={dangerColor[0]}>Incomplete</Typography>;
  }

  return (
    <div>
      <FormNavbar title={context.form.name} backLink={context.backURL} />
      <Container
        maxWidth="md"
        sx={{mt: 2, display: 'flex', flexDirection: 'column', gap: 2}}>
        <Card title="Form Details" padding>
          <Details label="Name">{context.form.name}</Details>
          <Details label="Description">{context.form.description}</Details>
          <Details label="Status">{statusText}</Details>
          <Box sx={{textAlign: 'right'}}>
            {context.portal === 'location' &&
              locationContext.authUser?.roles?.includes('MANAGE_FORMS') && (
                <Button
                  size="medium"
                  variant="contained"
                  color="secondary"
                  sx={{ml: 1}}
                  onClick={() =>
                    navigate(
                      `${locationContext.navigationBase}/forms/${context.form.id}/builder`,
                    )
                  }>
                  Form Builder
                </Button>
              )}
            {context.form.status !== 'COMPLETE' && (
              <Button
                size="medium"
                variant="contained"
                color="primary"
                sx={{ml: 1}}
                onClick={() => setConfirmDialogOpen(true)}>
                Mark Complete
              </Button>
            )}
            {context.form.status === 'COMPLETE' && (
              <Button
                size="medium"
                variant="contained"
                color="primary"
                disabled={submitting}
                sx={{ml: 1}}
                onClick={handlePDFClick}>
                {submitting ? 'Generating' : 'View PDF'}
              </Button>
            )}
          </Box>
        </Card>
        <Box>
          <Typography>Pages</Typography>
          {!pages && (
            <Box sx={{textAlign: 'center'}}>
              <CircularProgress />
            </Box>
          )}
          {pages && !pages.length && (
            <Typography>There is no page in this form.</Typography>
          )}
          {pages &&
            pages.map(page => (
              <Link
                to={`${context.navigationBase}/pages/${page.id}`}
                key={page.id}
                style={{display: 'block'}}>
                <MuiCard
                  sx={{
                    p: 2,
                    mb: 1,
                    mt: 0,
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                  }}>
                  <Box sx={{mr: 1}}>
                    {!page.image && (
                      <Article
                        sx={{
                          color: 'gray',
                          verticalAlign: 'middle',
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    )}
                    {page.image && (
                      <img
                        src={storageBase + page.image!}
                        alt={page.name}
                        width={40}
                        height={40}
                        style={{objectFit: 'contain'}}
                      />
                    )}
                  </Box>
                  <Box sx={{flex: 1}}>
                    <Typography>{page.name}</Typography>
                    <Typography
                      textTransform="capitalize"
                      fontSize={11}
                      lineHeight={1}>
                      {page.type.toLowerCase()}
                    </Typography>
                  </Box>
                  <Link to={`${context.navigationBase}/pages/${page.id}`}>
                    <IconButton>
                      <KeyboardArrowRight />
                    </IconButton>
                  </Link>
                </MuiCard>
              </Link>
            ))}
        </Box>
      </Container>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Make your all fields are complete"
        text={
          'Please make sure that you have filled all the fields before you ' +
          'mark this form as complete. Also note that once the form is marked as ' +
          'complete, it cannot be edited.'
        }
        submitting={submitting}
        errorMessage={serverError}
        onClose={handleConfirmDialogClose}
      />
      <Dialog
        open={!!message}
        onClose={() => setMessage('')}
        title="Alert"
        actions={[
          {
            color: 'primary',
            text: 'Ok',
            onClick: () => setMessage(''),
          },
        ]}>
        {message}
      </Dialog>
      <Dialog
        open={!!message}
        onClose={() => setMessage('')}
        title="Alert"
        actions={[
          {
            color: 'primary',
            text: 'Ok',
            onClick: () => setMessage(''),
          },
        ]}>
        {message}
      </Dialog>
      <Dialog
        open={PDFDialogOpen}
        onClose={() => setPDFDialogOpen(false)}
        title="Open PDF"
        actions={[
          {
            color: 'secondary',
            text: 'Close',
            onClick: () => setPDFDialogOpen(false),
          },
          {
            color: 'primary',
            text: 'Open PDF',
            onClick: () => window.open(pdfURL, '_blank'),
          },
        ]}>
        PDF file for this form generated successfully.
      </Dialog>
    </div>
  );
}
