import React from 'react';
import {useDropzone} from 'react-dropzone';
import {uploadFileTypes} from 'functions/api';

interface Props {
  type: keyof typeof uploadFileTypes;
  onDrop: (files: File[]) => void;
  file?: File;
  disabled?: boolean;
  initialText?: string;
  style?: React.CSSProperties;
}

function Dropzone(props: Props) {
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: props.onDrop,
    multiple: false,
    accept: uploadFileTypes[props.type],
  });

  const text = React.useMemo(() => {
    if (props.file) {
      return props.file.name;
    }
    if (isDragActive) {
      return 'Drop the file here ...';
    }
    return (
      props.initialText ||
      "Drag 'n' drop a file here, or click to select a file"
    );
  }, [isDragActive, props.file]);

  return (
    <div
      {...getRootProps()}
      style={{
        borderRadius: '3px',
        border: '1px dashed #ccc',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: props.disabled ? '#cccccc' : '#f5f5f5',
        pointerEvents: props.disabled ? 'none' : undefined,
        boxSizing: 'border-box',
        ...props.style,
      }}>
      <input {...getInputProps()} />
      {text}
    </div>
  );
}

export default Dropzone;
