import React from 'react';
import type {Portal} from 'types/auth';
import type {ClientResponse} from 'types/clients';
import type {
  Form,
  FormComponent,
  FormPage,
  FormRole,
  FormRoleExpanded,
} from 'types/forms';
import type {LocationResponse} from 'types/locations';
import type {UserResponse, UserRole} from 'types/users';

export interface AdminContextType {
  isLoggedIn: boolean;
  extra: any;
  updateContext: (newContext: AdminContextType) => void;
}

export interface FormBuilderContextType {
  portal: Portal;
  form: Form;
  roles: FormRoleExpanded[];
  backUrl: string | null;
  editable: boolean;
  navigationBase: string;
  formsAPIBase: string;
  rolesAPIBase: string;

  updateForm: (form: Form | undefined) => void;
  updateRoles: (roles: FormRoleExpanded[] | undefined) => void;
  loadForm: () => Promise<void>;
  loadRoles: () => Promise<void>;
}

export interface FormFillContextType {
  portal: Portal;
  form: Form;
  page?: FormPage;
  components?: FormComponent[];
  myRole: FormRole | null;
  roles: FormRole[];

  formLoading: boolean;
  adminAccess: boolean;
  editable: boolean;

  navigationBase: string;
  formsAPIBase: string;
  pageAPIBase?: string;
  backURL: string;

  updateForm: (form: Form | undefined) => void;
  updateRoles: (roles: FormRoleExpanded[] | undefined) => void;
  loadForm: () => Promise<void>;
  loadRoles: () => Promise<void>;
}

export interface TrainingBuilderContextType {
  apiBase: string;
  navigationBase: string;
  trainingId: number;
  type: 'edit' | 'fill';
  portal: Portal;
  searchQuery: string;
  backUrl: string;
}

export interface LocationContextType {
  navigationBase: string;
  location: LocationResponse;
  reloadUser: () => void;
  extra: any;
  authUser?: {
    user?: UserResponse;
    isAdmin: boolean;
    roles: UserRole[];
  };
  updateContext: (newContext: LocationContextType) => void;
}

export interface ClientContextType {
  navigationBase: string;
  location: LocationResponse;
  client?: ClientResponse;
  updateContext: (newContext: ClientContextType) => void;
}

export const AdminContext = React.createContext<AdminContextType | undefined>(
  undefined,
);
export const FormFillContext = React.createContext<
  FormFillContextType | undefined
>(undefined);
export const FormBuilderContext = React.createContext<
  FormBuilderContextType | undefined
>(undefined);
export const TrainingBuilderContext = React.createContext<
  TrainingBuilderContextType | undefined
>(undefined);
export const LocationContext = React.createContext<
  LocationContextType | undefined
>(undefined);
export const ClientContext = React.createContext<ClientContextType | undefined>(
  undefined,
);

export const useAdminContext = () => React.useContext(AdminContext)!;
export const useLocationContext = () => React.useContext(LocationContext)!;
export const useClientContext = () => React.useContext(ClientContext)!;
export const useFormFillContext = () => React.useContext(FormFillContext)!;
export const useFormBuilderContext = () =>
  React.useContext(FormBuilderContext)!;
export const useTrainingBuilderContext = () =>
  React.useContext(TrainingBuilderContext)!;
