import React from 'react';

import Typography from '@mui/material/Typography';

import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import Dialog from 'components/Dialog';
import Dropzone from 'components/Dropzone';

import {apiCall, createAndUploadFile} from 'functions/api';

import {Portal} from 'types/auth';
import {InsertDocumentRequest} from 'types/documents';

export interface UploadDocumentDialogProps {
  onClose: (submit?: boolean) => void;
  document?: {userId: number; categoryId: number; id?: number};
  portal: Portal;
}

const documentPath: {[k in Portal]: string} = {
  location: '/location/documents/',
  client: '/client/documents/',
  admin: '',
};
function UploadDocumentDialog(props: UploadDocumentDialogProps) {
  const open = !!props.document;

  const [expiry, setExpiry] = React.useState<Date | null>(null);

  const [file, setFile] = React.useState<File>();
  const [progress, setProgress] = React.useState(0);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');

  const error = React.useMemo(() => !file, [file]);

  const handleClose = () => {
    if (submitting) return;
    props.onClose();
  };

  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async () => {
    if (submitting || error || !props.document || !file) return;
    setSubmitting(true);
    const request: Partial<InsertDocumentRequest> = {};
    const url = documentPath[props.portal] + (props.document.id || '');
    if (expiry) {
      request.expiryDate = expiry.getTime();
    }
    try {
      const gcsFileName = await createAndUploadFile(
        file,
        props.portal,
        'document',
        setProgress,
      );
      request.file = gcsFileName;
    } catch (e: any) {
      setSubmitting(false);
      setServerError(e?.message || 'An error occurred');
      return;
    }
    if (!props.document.id) {
      request.categoryId = props.document.categoryId;
      request.userId = props.document.userId;
    }
    apiCall(url, 'POST', request).then(
      () => {
        setSubmitting(false);
        props.onClose(true);
      },
      e => {
        setSubmitting(false);
        setServerError(e.message || 'An unexpected error occurred.');
      },
    );
  };

  React.useEffect(() => {
    if (open) {
      setExpiry(null);
      setFile(undefined);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Upload Document"
      actions={[
        {
          color: 'secondary',
          text: 'Close',
          onClick: handleClose,
        },
        {
          color: 'primary',
          text: submitting ? 'Saving' : 'Save',
          submit: true,
          disabled: error || submitting,
        },
      ]}
      progress={submitting && progress ? {value: progress} : undefined}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={expiry}
          onChange={setExpiry}
          label="Expiry Date (Optional)"
        />
      </LocalizationProvider>
      <Typography mt={1} fontSize={14} lineHeight={2}>
        Document
      </Typography>

      <Dropzone type="document" onDrop={handleDrop} file={file} />
      {serverError && <p style={{color: 'red'}}>{serverError}</p>}
    </Dialog>
  );
}

export default UploadDocumentDialog;
