import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';
import {StylesProvider} from '@mui/styles';

import {materialThemeOverride} from 'assets/jss/main';

import FormDisplay from 'pages/FormFill/Display';
import AdminAuthenticatedLayout from './Admin';
import LocationAuthenticatedLayout from './Location';
import ClientAuthenticatedLayout from './Client';

function MainLayout() {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={materialThemeOverride}>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/--display-form" element={<FormDisplay />} />
            <Route path="/admin/*" element={<AdminAuthenticatedLayout />} />
            <Route
              path="/:locationId/c/*"
              element={<ClientAuthenticatedLayout />}
            />
            <Route
              path="/:locationId/*"
              element={<LocationAuthenticatedLayout />}
            />
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default MainLayout;
