import React, {useEffect, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import Combo from 'components/Combo';
import Dialog from 'components/Dialog';

import {APIError, apiCall} from 'functions/api';
import {useAdminContext} from 'functions/context';

import type {LocationAssignmentType} from 'types/locations';

interface Props {
  open: boolean;
  onClose: (submit?: boolean) => void;
  module: LocationAssignmentType;
}
const apiUrls: {[key in LocationAssignmentType]: string} = {
  TRAINING_GROUP: '/admin/trainingGroups',
  FORM: '/admin/forms',
  BOOK: '/admin/books',
};
const labels: {[key in LocationAssignmentType]: string} = {
  TRAINING_GROUP: 'Training Group',
  FORM: 'Form',
  BOOK: 'Book',
};
export default function AddAssignment(props: Props) {
  const [options, setOptions] =
    useState<Array<{value: number; label: string}>>();
  const [selected, setSelected] = useState<number>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const context = useAdminContext();
  const {location} = context.extra;

  useEffect(() => {
    setSelected(undefined);
  }, [props.open]);

  useEffect(() => {
    apiCall(apiUrls[props.module], 'GET').then(({data}) => {
      setOptions(data.map((item: any) => ({value: item.id, label: item.name})));
    });
  }, [props.module]);
  return (
    <Dialog
      title={`Assign ${labels[props.module]}`}
      open={props.open}
      onClose={props.onClose}
      onSubmit={() => {
        setSubmitting(true);
        apiCall('/admin/locationAssignments', 'POST', {
          locationId: location.id,
          type: props.module,
          typeId: selected,
        }).then(
          () => {
            setSubmitting(false);
            props.onClose(true);
          },
          e => {
            setSubmitting(false);
            if (e instanceof APIError && e.code === 'id_used') {
              setError('Already Added.');
            } else {
              setError('An unknown error occurred.');
            }
          },
        );
      }}
      actions={[
        {
          text: 'Cancel',
          color: 'secondary',
          onClick: () => {
            props.onClose(false);
          },
        },
        {
          text: 'Assign',
          color: 'primary',
          disabled: !selected || submitting,
          submit: true,
        },
      ]}>
      {!options ? (
        <div style={{textAlign: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <Combo
          value={selected}
          onChange={v => {
            if (v) {
              setSelected(v as number);
            }
          }}
          label={labels[props.module]}
          items={options}
        />
      )}
      {error && (
        <Typography color="error" mt={1} fontSize={14}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}
