import React from 'react';

import Box from '@mui/material/Box';

import {useClientContext} from 'functions/context';

import DocumentCard from '../Location/Documents/DocumentCard';

export default function MyDocumentsPage() {
  const clientContext = useClientContext();
  return (
    <Box>
      <DocumentCard
        portal="client"
        userId={clientContext.client!.id}
        categoryPortal="CLIENT"
      />
    </Box>
  );
}
