import React, {useMemo, useState} from 'react';
import Button from '@mui/material/Button';

import Card from 'components/Card';
import Details from 'components/Details';
import ChangePasswordDialog from 'components/ChangePasswordDialog';
import ConfirmDialog from 'components/ConfirmDialog';

import {useAdminContext} from 'functions/context';
import type {LocationResponse} from 'types/locations';

import LocationDialog from './Dialog';

function LocationInfoPage() {
  const context = useAdminContext();
  const location: LocationResponse = context.extra.location;
  const reload = context.extra.reload as () => void;

  const [action, setAction] = useState<'edit' | 'change-password' | 'remove'>();
  const [copiedLink, setCopiedLink] = useState(false);

  const locationLink = useMemo(
    () =>
      `${window.location.protocol}//${window.location.host}/${location.locationId}`,
    [location],
  );

  const handleCopyClick = () => {
    window.navigator.clipboard.writeText(locationLink);
    setCopiedLink(true);
    setTimeout(() => setCopiedLink(false), 3000);
  };

  const handleDialogClose = (success: boolean) => {
    if (success) {
      reload();
    }
    setAction(undefined);
  };

  return (
    <>
      <Card
        title="Location Details"
        padding
        menu={[
          {
            label: 'Edit',
            onClick: () => setAction('edit'),
          },
          {
            label: 'Change Password',
            onClick: () => setAction('change-password'),
          },
          {
            label: 'Remove',
            onClick: () => setAction('remove'),
          },
        ]}>
        {location && (
          <>
            <Details label="Portal">
              <a href={locationLink} target="_blank" rel="noopener noreferrer">
                {locationLink}
              </a>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                sx={{ml: 1, py: 0}}
                onClick={handleCopyClick}>
                {copiedLink ? 'Copied' : 'Copy'}
              </Button>
            </Details>
            <Details label="Id">{location.locationId}</Details>
            <Details label="Name">{location.name}</Details>
            <Details label="Email">{location.email}</Details>
            <Details label="Modules" items={location.modules} />
          </>
        )}
      </Card>
      <ChangePasswordDialog
        open={action === 'change-password'}
        onClose={handleDialogClose}
        apiLink={`/admin/locations/${location.id}`}
      />
      <ConfirmDialog
        open={action === 'remove'}
        onClose={handleDialogClose}
        title="Remove Location"
        text="Are you sure you want to remove this location?"
        apiLink={`/admin/locations/${location.id}`}
      />
      <LocationDialog
        open={action === 'edit'}
        onClose={handleDialogClose}
        location={location}
      />
    </>
  );
}

export default LocationInfoPage;
