import DashboardIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';

import DashboardPage from 'pages/Client/Dashboard';
import MyDocumentsPage from 'pages/Client/MyDocuments';
import MyDocumentsIcon from '@mui/icons-material/FolderCopy';
import SettingsPage from 'pages/Client/Settings';

import type {Route} from 'types/routes';
import MyTrainingsPage from 'pages/Location/Trainings/MyTrainings';

const routes: Route[] = [
  {
    path: 'dashboard',
    icon: DashboardIcon,
    name: 'Dashboard',
    element: DashboardPage,
  },
  {
    path: 'my-documents',
    icon: MyDocumentsIcon,
    name: 'My Documents',
    element: MyDocumentsPage,
    noAdmin: true,
    module: 'DOCUMENTS',
  },
  {
    path: 'my-trainings',
    icon: MyDocumentsIcon,
    name: 'My Trainings',
    element: MyTrainingsPage,
    noAdmin: true,
    module: 'DOCUMENTS',
  },
  {
    path: 'settings',
    icon: SettingsIcon,
    name: 'Settings',
    element: SettingsPage,
  },
];

export default routes;
