import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';

import Card from 'components/Card';
import DataTable from 'components/DataTable';
import ConfirmDialog from 'components/ConfirmDialog';

import {apiCall} from 'functions/api';

import {Training, type TrainingGroup} from 'types/trainings';

import TrainingDetailDialog from 'pages/Training/DetailDialog';

import TrainingGroupDialog from './TrainingGroupDialog';

export default function TrainingsPage() {
  const groupId = +(useParams()?.groupId || 0);
  const [trainingGroup, setTrainingGroup] = useState<TrainingGroup>();
  const [trainings, setTrainings] = useState<Training[]>();
  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (trainingGroup !== undefined) return;
    apiCall(`/admin/trainingGroups/${groupId}`, 'GET').then(
      v => {
        setTrainingGroup(v);
      },
      () => navigate('/admin/training-groups'),
    );
  }, [trainingGroup]);

  useEffect(() => {
    if (trainings !== undefined) return;
    apiCall('/admin/trainings', 'GET', {groupId}).then(v => {
      if (Array.isArray(v?.data)) {
        setTrainings(v.data);
      } else {
        setTrainings([]);
      }
    });
  }, [trainings]);

  const handleRemoveClose = (s?: boolean) => {
    if (s) navigate('/admin/training-groups');
    setRemove(false);
  };
  const handleEditClose = (s?: boolean) => {
    if (s) setTrainingGroup(undefined);
    setEdit(false);
  };
  if (!trainingGroup) {
    return <CircularProgress sx={{mx: 'auto', display: 'block'}} />;
  }
  return (
    <div>
      <Card
        title={trainingGroup?.name}
        menu={[
          {label: 'Edit Group', onClick: () => setEdit(true)},
          {label: 'Delete Group', onClick: () => setRemove(true)},
        ]}
        button="Add"
        buttonOnClick={() => {
          setOpen(true);
        }}>
        <DataTable
          rows={trainings}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Description',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
          ]}
          onRowClick={params => {
            navigate(
              `/admin/training-groups/${groupId}/training/${params.row.id}?from=/admin/training-groups/${groupId}`,
            );
          }}
        />
      </Card>
      <ConfirmDialog
        open={remove}
        onClose={handleRemoveClose}
        title="Remove Training Group"
        text="Are you sure to remove this Training Group."
        apiLink={`/admin/trainingGroups/${groupId}`}
      />
      <TrainingGroupDialog
        open={edit}
        onClose={handleEditClose}
        trainingGroup={trainingGroup}
      />
      <TrainingDetailDialog
        apiUrl="/admin/trainings"
        apiProps={{groupId}}
        onSubmit={() => setTrainings(undefined)}
        onClose={() => setOpen(false)}
        open={open}
      />
    </div>
  );
}
