import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import FullPageLoader from 'components/FullPageLoader';

import FormMainPage from 'pages/FormFill/Main';
import FormFillPage from 'pages/FormFill/Page';

import {
  FormFillContext,
  FormFillContextType,
  useClientContext,
  useLocationContext,
} from 'functions/context';
import {APIError, apiCall} from 'functions/api';
import {getAPIBases} from 'functions/forms';

import type {Form, FormRole, ListRolesRequest} from 'types/forms';
import type {Portal} from 'types/auth';
import {ListResponse} from 'types/utils';

export interface Props {
  portal: Portal;
  formId: number;
}

export default function FormFillLayout(props: Props) {
  const navigate = useNavigate();
  const locationContext = useLocationContext();
  const clientContext = useClientContext();
  const {portal, formId} = props;

  const [form, setForm] = React.useState<Form>();
  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [myFormRole, setMyFormRole] = React.useState<FormRole>();
  const [roles, setRoles] = React.useState<FormRole[]>();

  const from = useSearchParams()[0].get('from');
  const adminAccess = React.useMemo(
    () =>
      portal === 'location' &&
      locationContext.authUser!.roles.includes('MANAGE_FORMS'),
    [portal, locationContext],
  );

  const [formsAPIBase, rolesAPIBase] = React.useMemo(
    () => getAPIBases(portal),
    [props.portal],
  );

  const backURL = React.useMemo(() => {
    if (from) {
      return from;
    }
    if (props.portal === 'client') {
      return `${clientContext.navigationBase}/forms`;
    }
    return `${locationContext.navigationBase}/forms`;
  }, [from]);

  const loadForm = async () => {
    const apiLink = `${formsAPIBase}/${props.formId}`;
    setFormLoading(true);
    try {
      const form = await apiCall(apiLink, 'GET');
      setForm(form);
      setFormLoading(false);
    } catch (e) {
      console.error(e);
      if (e instanceof APIError && e.code === 'not_found') {
        navigate(backURL);
      }
    }
  };

  const loadRoles = async () => {
    const request: ListRolesRequest = {
      formId,
    };
    try {
      const results: ListResponse<FormRole> = await apiCall(
        rolesAPIBase,
        'GET',
        request,
      );
      setRoles(results.data);
      if (!adminAccess) {
        const userId =
          portal === 'client'
            ? clientContext.client?.id
            : locationContext.authUser?.user?.id;
        const portalKey = portal === 'client' ? 'CLIENT' : 'LOCATION';
        const myRole = results.data.find(
          r => r.userId === userId && r.portal === portalKey,
        );
        if (!myRole) {
          navigate(backURL);
        }
        setMyFormRole(myRole);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof APIError && e.code === 'not_found') {
        navigate(backURL);
      }
    }
  };

  React.useEffect(() => {
    loadForm();
    loadRoles();
  }, []);

  const contextValue = React.useMemo<FormFillContextType | undefined>(() => {
    if (!form || (!myFormRole && !adminAccess)) {
      return undefined;
    }

    const navigationBase =
      props.portal === 'location'
        ? `${locationContext!.navigationBase}/forms/${form.id}/fill`
        : `${clientContext!.navigationBase}/forms/${form.id}/fill`;

    let editable = form.status !== 'COMPLETE';
    editable &&= adminAccess || myFormRole?.status !== 'COMPLETE';

    return {
      portal,
      adminAccess,
      formLoading,
      editable,
      form,
      formsAPIBase,
      roles,
      myRole: myFormRole,
      navigationBase,
      backURL,
      updateForm: setForm,
      updateRoles: setRoles,
      loadForm,
      loadRoles,
    } as FormFillContextType;
  }, [form, roles, myFormRole, backURL, locationContext, adminAccess]);

  if (!contextValue) {
    return <FullPageLoader />;
  }

  return (
    <FormFillContext.Provider value={contextValue}>
      <Routes>
        <Route path="/" element={<FormMainPage />} />
        <Route path="pages/:pageId" element={<FormFillPage />} />
        <Route
          path="*"
          element={<Navigate to={contextValue.navigationBase} />}
        />
      </Routes>
    </FormFillContext.Provider>
  );
}
