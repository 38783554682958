import React from 'react';

import Box from '@mui/material/Box';

import {useLocationContext} from 'functions/context';

import DocumentCard from './Documents/DocumentCard';

export default function MyDocumentsPage() {
  const locationContext = useLocationContext();
  return (
    <Box>
      <DocumentCard
        portal="location"
        userId={locationContext.authUser!.user!.id}
        categoryPortal="LOCATION"
      />
    </Box>
  );
}
