import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import {activeRoute} from 'functions/utils';
import {LocationContext, useLocationContext} from 'functions/context';

import {type ClientResponse} from 'types/clients';
import {type ListResponse} from 'types/utils';
import {type TabRoute} from 'types/routes';

import ClientInfoPage from './Info';
import ClientDialog from './Dialog';
import ClientFormsPage from './Forms';
import ClientDocumentsPage from './Documents';
import ClientTrainingsPage from './Trainings';

const routes: TabRoute[] = [
  {
    name: 'Info',
    path: 'info',
    element: ClientInfoPage,
  },
  {
    name: 'Forms',
    path: 'forms',
    element: ClientFormsPage,
  },
  {
    name: 'Documents',
    path: 'documents',
    element: ClientDocumentsPage,
  },
  {
    name: 'Trainings',
    path: 'trainings',
    element: ClientTrainingsPage,
  },

];

function ClientsListPage() {
  const navigate = useNavigate();
  const {navigationBase} = useLocationContext();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [clients, setClients] = React.useState<ClientResponse[]>();

  const loadData = () => {
    setClients(undefined);
    apiCall('/location/clients').then(
      (response: ListResponse<ClientResponse>) => {
        setClients(response.data);
      },
    );
  };

  const handleClientClick = (id: number) => {
    navigate(`${navigationBase}/clients/${id}`);
  };

  const handleAddClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (success: boolean) => {
    setDialogOpen(false);
    if (success) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card title="Clients" button="Add" buttonOnClick={handleAddClick}>
        <DataTable<ClientResponse>
          rows={clients}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
              renderCell: p => `${p.row.firstName} ${p.row.lastName}`,
            },
            {
              field: 'email',
              headerName: 'Email',
              minWidth: 300,
              flex: 1,
              sortable: false,
            },
          ]}
          onRowClick={params => {
            handleClientClick(params.row.id);
          }}
        />
      </Card>
      <ClientDialog open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
}

function ClientTabsPage() {
  const {clientId} = useParams<'clientId'>();
  const navigate = useNavigate();
  const location = useLocation();
  const context = useLocationContext();
  const [data, setData] = React.useState<ClientResponse>();

  const loadData = React.useCallback(() => {
    setData(undefined);
    apiCall(`/location/clients/${clientId}`).then(
      (response: ClientResponse) => {
        setData(response);
      },
      () => navigate(`${context.navigationBase}/clients`),
    );
  }, [clientId]);

  React.useEffect(() => {
    loadData();
  }, []);

  const base = React.useMemo(
    () => `${context.navigationBase}/clients/${clientId}`,
    [clientId],
  );
  const active: string | undefined = React.useMemo(
    () =>
      routes.find(route => activeRoute(`${base}/${route.path}`, location))
        ?.path || routes[0].path,
    [location.pathname],
  );
  const updatedContext = React.useMemo(
    () => ({
      ...context,
      extra: {
        client: data,
        reload: loadData,
      },
    }),
    [data, context, loadData],
  );

  if (!data) {
    return (
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={active}
          onChange={(event, value) => {
            navigate(`${base}/${value}`);
          }}>
          {routes.map(route => (
            <Tab key={route.path} label={route.name} value={route.path} />
          ))}
        </Tabs>
      </Box>
      <br />
      <LocationContext.Provider value={updatedContext}>
        <Routes>
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))}
          <Route path="*" element={<Navigate to={routes[0].path} />} />
        </Routes>
      </LocationContext.Provider>
    </>
  );
}

function ClientsPage() {
  const {navigationBase} = useLocationContext();
  return (
    <Routes>
      <Route path="/:clientId/*" element={<ClientTabsPage />} />
      <Route path="/" element={<ClientsListPage />} />
      <Route path="*" element={<Navigate to={`${navigationBase}/clients`} />} />
    </Routes>
  );
}

export default ClientsPage;
