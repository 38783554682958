import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FormAssignmentsCard from 'pages/FormBuilder/FormAssignments';

import {useLocationContext} from 'functions/context';

import type {FormStatus, Form} from 'types/forms';

export default function MyFormsPage() {
  const navigate = useNavigate();
  const context = useLocationContext()!;
  const pageLocation = useLocation();
  const [tab, setTab] = React.useState<FormStatus>('PENDING');

  const handleFillClick = (form: Form) => {
    let url = `${context.navigationBase}/forms/${form.id}/fill`;
    url += `?from=${encodeURIComponent(pageLocation.pathname)}`;
    navigate(url);
  };

  if (!context.authUser?.user) return <div />;

  return (
    <Box>
      <Tabs value={tab} onChange={(_, value) => setTab(value)} sx={{mb: 2}}>
        <Tab label="PENDING" value="PENDING" />
        <Tab label="Complete" value="COMPLETE" />
      </Tabs>
      <FormAssignmentsCard
        userId={context.authUser.user.id}
        key={tab}
        status={tab}
        portal="location"
        userPortal="LOCATION"
        onRowClick={handleFillClick}
        title={`${{PENDING: 'Pending', COMPLETE: 'Completed'}[tab]} Forms`}
      />
    </Box>
  );
}
