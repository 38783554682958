/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';

import {GridActionsCellItem} from '@mui/x-data-grid';
import Edit from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import {formatDate} from 'functions/utils';

import type {
  ListTrainingAssignmentsRequest,
  TrainingAssignment,
  TrainingAssignmentExpanded,
} from 'types/trainings';
import type {ListResponse} from 'types/utils';

import AddTrainingAssignment from './AddAssignment';

export type TrainingAssignmentCardProps = {
  type:
    | 'trainingAssignClient'
    | 'trainingAssignUser'
    | 'userAssign'
    | 'clientAssign'
    | 'userFill'
    | 'clientFill';
  typeId: number;
  isComplete?: boolean;
  onPress?: (assignment: TrainingAssignmentExpanded) => void;
};

export default function TrainingAssignmentCard({
  type,
  typeId,
  isComplete,
  onPress,
}: TrainingAssignmentCardProps) {
  const [assignments, setAssignments] =
    useState<TrainingAssignmentExpanded[]>();
  const [open, setOpen] = useState<TrainingAssignment | true>();

  const loadData = () => {
    setAssignments(undefined);
    const props: ListTrainingAssignmentsRequest = {};
    props.expand = true;
    if (type === 'trainingAssignClient') {
      props.trainingId = typeId;
      props.portal = 'CLIENT';
    } else if (type === 'trainingAssignUser') {
      props.trainingId = typeId;
      props.portal = 'LOCATION';
    } else {
      if (type === 'userAssign' || type === 'userFill') {
        props.portal = 'LOCATION';
      } else {
        props.portal = 'CLIENT';
      }
      props.userId = typeId;
    }
    if (isComplete) {
      props.isComplete = isComplete;
    }
    apiCall(
      `/${type === 'clientFill' ? 'client' : 'location'}/trainingAssignments`,
      'GET',
      props,
    ).then((data: ListResponse<TrainingAssignmentExpanded>) => {
      setAssignments(data.data);
    });
  };
  useEffect(() => {
    loadData();
  }, [type]);

  const handleClose = (s?: boolean) => {
    if (s) {
      loadData();
    }
    setOpen(undefined);
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <Card
      title="Training Assignments"
      button={type.includes('Assign') ? 'Assign' : undefined}
      buttonOnClick={() => setOpen(true)}>
      <DataTable<TrainingAssignmentExpanded>
        rows={assignments}
        columns={[
          {
            field: 'name',
            headerName:
              type === 'trainingAssignUser'
                ? 'User'
                : type === 'trainingAssignClient'
                ? 'Client'
                : 'Training',
            renderCell: p =>
              type === 'trainingAssignUser' || type === 'trainingAssignClient'
                ? `${p.row.user?.firstName} ${p.row.user?.lastName}`
                : p.row.training?.name,
            minWidth: 100,
            flex: 1,
          },
          {
            field: 'deadline',
            headerName: 'Deadline',
            minWidth: 100,
            flex: 1,
            renderCell: p => p.row.deadline && formatDate(p.row.deadline),
          },
          {
            field: 'status',
            headerName: '',
            minWidth: 100,
            flex: 1,
            renderCell: p =>
              p.row.isComplete ? (
                <Typography color="success">Complete</Typography>
              ) : (
                ''
              ),
          },
          type.includes('Assign') && {
            field: 'actions',
            type: 'actions',
            minWidth: 100,
            flex: 2,
            align: 'right',
            getActions: params => [
              <GridActionsCellItem
                key={0}
                icon={<Edit />}
                label="Edit"
                onClick={() => setOpen(params.row)}
              />,
            ],
          },
        ]}
        onRowClick={onPress ? p => onPress(p.row) : undefined}
      />
      {type.includes('Assign') && (
        <AddTrainingAssignment
          open={!!open}
          onClose={handleClose}
          entityId={typeId}
          assignment={open && open !== true ? open : undefined}
          select={
            type === 'trainingAssignClient'
              ? 'clientForTraining'
              : type === 'trainingAssignUser'
              ? 'userForTraining'
              : type === 'clientAssign'
              ? 'clientForTraining'
              : 'trainingForUser'
          }
        />
      )}
    </Card>
  );
}
