import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import PerfectScrollbar from 'perfect-scrollbar';

import {activeRoute} from 'functions/utils';
import type {Route} from 'types/routes';

import {
  backgroundDarkSx,
  backgroundLightSx,
  backgroundSx,
  brandSx,
  drawerPaperSx,
  listSx,
  listItemActiveSx,
  listItemIconSx,
  listItemSx,
  listItemTextSx,
  sidebarWrapperDarkSx,
  sidebarWrapperLightSx,
  sidebarWrapperSx,
} from 'assets/jss/sidebar';

export interface SidebarProps {
  open: boolean;
  handleDrawerToggle: () => void;
  image?: string;
  dark?: boolean;
  base: string;
  logoText: string;
  routes: Route[];
}

export function SidebarLinks(props: SidebarProps) {
  const ref = React.useRef();
  const location = useLocation();
  const {image, logoText, routes, base = '/'} = props;

  const handleNavlinkClick = () => {
    if (props.open) {
      props.handleDrawerToggle();
    }
  };

  React.useEffect(() => {
    if (!ref.current) {
      return () => {};
    }
    const scrollbar = new PerfectScrollbar(ref.current);
    const resizeFn = () => scrollbar.update();
    window.addEventListener('resize', resizeFn);
    return () => {
      window.removeEventListener('resize', resizeFn);
      scrollbar.destroy();
    };
  }, [ref]);

  return (
    <>
      <Box
        ref={ref}
        sx={[
          sidebarWrapperSx,
          props.dark ? sidebarWrapperDarkSx : sidebarWrapperLightSx,
        ]}>
        <Box sx={brandSx}>
          <a href="#">{logoText}</a>
        </Box>
        <List sx={listSx}>
          {routes.map((prop, key) => {
            if (!prop.icon) {
              return null;
            }
            const path = base + (prop.path ? `/${prop.path}` : '');
            const isActive = activeRoute(path, location);
            return (
              <NavLink
                to={path.replace('/*', '')}
                className="nav-link"
                onClick={handleNavlinkClick}
                key={key}>
                <ListItemButton sx={[listItemSx, isActive && listItemActiveSx]}>
                  <prop.icon sx={listItemIconSx} />
                  <ListItemText
                    primary={prop.name}
                    disableTypography
                    sx={listItemTextSx}
                  />
                </ListItemButton>
              </NavLink>
            );
          })}
        </List>
      </Box>
      <Box
        sx={[
          backgroundSx,
          props.dark ? backgroundDarkSx : backgroundLightSx,
          !!image && {backgroundImage: `url(${image})`},
        ]}
      />
    </>
  );
}

export default function Sidebar(props: SidebarProps) {
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={props.open}
          PaperProps={{
            sx: drawerPaperSx,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          <SidebarLinks {...props} />
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          PaperProps={{
            sx: drawerPaperSx,
          }}>
          <SidebarLinks {...props} />
        </Drawer>
      </Hidden>
    </div>
  );
}
