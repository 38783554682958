import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import {activeRoute} from 'functions/utils';
import {LocationContext, useLocationContext} from 'functions/context';

import type {UserResponse} from 'types/users';
import type {ListResponse} from 'types/utils';
import type {TabRoute} from 'types/routes';

import UserInfoPage from './Info';
import UserDialog from './Dialog';
import UserFormsPage from './Forms';
import UserDocumentsPage from './Documents';
import UserTrainingsPage from './Trainings';
import UserTasksPage from './Tasks';

const routes: TabRoute[] = [
  {
    name: 'Info',
    path: 'info',
    element: UserInfoPage,
  },
  {
    name: 'Forms',
    path: 'forms',
    element: UserFormsPage,
  },
  {
    name: 'Documents',
    path: 'documents',
    element: UserDocumentsPage,
  },
  {
    name: 'Trainings',
    path: 'trainings',
    element: UserTrainingsPage,
  },
  {
    name: 'Tasks',
    path: 'tasks',
    element: UserTasksPage,
  },
];

function UsersListPage() {
  const navigate = useNavigate();
  const {navigationBase} = useLocationContext();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [users, setUsers] = React.useState<UserResponse[]>();

  const loadData = () => {
    setUsers(undefined);
    apiCall('/location/users').then((response: ListResponse<UserResponse>) => {
      setUsers(response.data);
    });
  };

  const handleUserClick = (id: number) => {
    navigate(`${navigationBase}/users/${id}`);
  };

  const handleAddClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (success: boolean) => {
    setDialogOpen(false);
    if (success) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card title="Users" button="Add" buttonOnClick={handleAddClick}>
        <DataTable<UserResponse>
          rows={users}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
              renderCell: p => `${p.row.firstName} ${p.row.lastName}`,
            },
            {
              field: 'email',
              headerName: 'Email',
              minWidth: 300,
              flex: 1,
              sortable: false,
            },
          ]}
          onRowClick={params => {
            handleUserClick(params.row.id);
          }}
        />
      </Card>
      <UserDialog open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
}

function UserTabsPage() {
  const {userId} = useParams<'userId'>();
  const navigate = useNavigate();
  const location = useLocation();
  const context = useLocationContext();
  const [data, setData] = React.useState<UserResponse>();

  const loadData = React.useCallback(() => {
    setData(undefined);
    apiCall(`/location/users/${userId}`).then(
      (response: UserResponse) => {
        setData(response);
      },
      () => navigate(`/${context.navigationBase}/users`),
    );
  }, [userId]);

  React.useEffect(() => {
    loadData();
  }, []);

  const base = React.useMemo(
    () => `${context.navigationBase}/users/${userId}`,
    [userId],
  );

  const active: string | undefined = React.useMemo(
    () =>
      routes.find(route => activeRoute(`${base}/${route.path}`, location))
        ?.path || routes[0].path,
    [location.pathname],
  );

  const updatedContext = React.useMemo(
    () => ({
      ...context,
      extra: {
        user: data,
        reload: loadData,
      },
    }),
    [data, context, loadData],
  );

  if (!data) {
    return (
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={active}
          onChange={(event, value) => {
            navigate(`${base}/${value}`);
          }}>
          {routes.map(route => (
            <Tab key={route.path} label={route.name} value={route.path} />
          ))}
        </Tabs>
      </Box>
      <br />
      <LocationContext.Provider value={updatedContext}>
        <Routes>
          {routes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))}
          <Route path="*" element={<Navigate to={routes[0].path} />} />
        </Routes>
      </LocationContext.Provider>
    </>
  );
}

function UsersPage() {
  const {navigationBase} = useLocationContext();
  return (
    <Routes>
      <Route path="/:userId/*" element={<UserTabsPage />} />
      <Route path="/" element={<UsersListPage />} />
      <Route
        path="*"
        element={<Navigate to={`${navigationBase}/users`} replace />}
      />
    </Routes>
  );
}

export default UsersPage;
