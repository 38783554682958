import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import {useSearchParams} from 'react-router-dom';
import {defaultImageWidth} from 'functions/forms';
import {storageBase} from 'functions/uploader';

import type {FormRenderData, FormRenderStatus} from 'types/forms';

import DocumentComponentView from './Page/DocumentComponent';
import FormComponentView from './Page/FormComponent';

type PageProps = FormRenderData & {
  onLoad: () => void;
  onError: () => void;
};

const pageWidth = 2000;
const minPageRatio = Math.sqrt(2);

function Form(props: PageProps) {
  const {page, components, onLoad} = props;
  const ref = React.createRef<HTMLDivElement>();
  const [height, setHeight] = React.useState<number>();

  const scale = 1.6;
  const order = page.order;
  const componentsOrdered = order.map(id => components.find(c => c.id === id)!);
  const values = componentsOrdered.map(component => component.value);
  const associatedIdx = componentsOrdered.map(component => {
    if (component.associatedId) {
      return componentsOrdered.findIndex(c => c.id === component.associatedId);
    }
    return -1;
  });

  React.useEffect(() => {
    if (height !== undefined) {
      document.fonts.ready.then(() => onLoad());
    }
  }, [height]);

  React.useEffect(() => {
    if (ref.current) {
      const {height} = ref.current.getBoundingClientRect();
      setHeight(height);
    }
  }, [ref]);

  return (
    <Box
      sx={{
        width: pageWidth,
        height: height || 0,
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}>
      <Box
        ref={ref}
        sx={{
          p: 2,
          width: pageWidth / scale,
          boxSizing: 'border-box',
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        }}>
        <Card sx={{py: 2}}>
          {!components.length && <Box sx={{px: 2, mb: 1}}>No data found.</Box>}
          {componentsOrdered.map((component, idx) => (
            <React.Fragment key={component.id}>
              {component.type === 'HEADING' && idx > 0 && (
                <Divider sx={{my: 2}} />
              )}
              <FormComponentView
                key={component.id}
                component={component}
                viewOnly
                associatedComponent={componentsOrdered[associatedIdx[idx]]}
                value={
                  values[component.associatedId ? associatedIdx[idx] : idx]
                }
                sx={{px: 2, mb: 1}}
              />
            </React.Fragment>
          ))}
        </Card>
      </Box>
    </Box>
  );
}

function Document(props: PageProps) {
  const {page, components, onLoad, onError} = props;
  const [imageHeight, setImageHeight] = React.useState<number>();

  const associatedIdx = components.map(component => {
    if (component.associatedId) {
      return components.findIndex(c => c.id === component.associatedId);
    }
    return -1;
  });
  const values = components.map(component => component.value);
  const documentScale = pageWidth / defaultImageWidth;

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImageHeight(
      (e.currentTarget.naturalHeight / e.currentTarget.naturalWidth) *
        defaultImageWidth,
    );
  };

  const handleImageError = () => {
    onError();
  };

  React.useEffect(() => {
    if (imageHeight !== undefined) {
      document.fonts.ready.then(() => onLoad());
    }
  }, [imageHeight]);

  return (
    <Box
      sx={{
        width: `${defaultImageWidth * documentScale}px`,
        height: `${(imageHeight || 0) * documentScale}px`,
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          position: 'relative',
          width: `${defaultImageWidth}px`,
          transform: `scale(${documentScale})`,
          transformOrigin: 'top left',
        }}>
        <img
          style={{
            width: '100%',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
          alt="Document Background"
          src={storageBase + page.image}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
        {components.map((component, key) => (
          <DocumentComponentView
            key={component.id}
            component={component}
            viewOnly
            associatedComponent={components[associatedIdx[key]]}
            value={values[component.associatedId ? associatedIdx[key] : key]}
          />
        ))}
      </Box>
    </Box>
  );
}

function displayStatus(status: FormRenderStatus) {
  console.log(`--status:${JSON.stringify(status)}`);
}

export default function FormDisplay() {
  const query = useSearchParams()[0];
  const ref = React.createRef<HTMLDivElement>();

  const data = React.useMemo(() => {
    try {
      const encoded = query.get('data');
      if (encoded) {
        return JSON.parse(encoded) as FormRenderData;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }, [query]);

  if (!data) {
    displayStatus({status: 'failed'});
    return null;
  }

  const params: PageProps = {
    ...data,
    onLoad: () => {
      setTimeout(() => {
        if (ref.current) {
          const {width, height} = ref.current.getBoundingClientRect();
          displayStatus({
            status: 'success',
            width,
            height,
          });
        }
      }, 100);
    },
    onError: () => displayStatus({status: 'failed'}),
  };

  return (
    <Box sx={{minHeight: '100vh', backgroundColor: '#FFFFFF'}}>
      <Box
        ref={ref}
        sx={{width: pageWidth, minHeight: pageWidth * minPageRatio}}>
        {data.page.type === 'FORM' ? (
          <Form {...params} />
        ) : (
          <Document {...params} />
        )}
      </Box>
    </Box>
  );
}
