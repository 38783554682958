import React from 'react';

import {GridActionsCellItem, type GridEventListener} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Card, {CardProps} from 'components/Card';
import DataTable from 'components/DataTable';

import {format} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import {apiCall} from 'functions/api';
import {useLocationContext} from 'functions/context';

import type {UserResponse} from 'types/users';
import type {ListTaskUsersRequest, Task, TaskType, TaskUser} from 'types/tasks';
import type {ListResponse} from 'types/utils';

import TaskStatusView from './Status';

interface Props extends Omit<CardProps, 'children'> {
  type?: TaskType;
  task?: Task;
  user?: UserResponse;
  onDelete?: false | ((user: TaskUser) => void);
}

export interface TaskAssignmentsCardRef {
  reload: () => void;
}

const TaskAssignmentsCard = React.forwardRef<TaskAssignmentsCardRef, Props>(
  (props, ref) => {
    const {task, type, user, onDelete, ...rest} = props;
    const context = useLocationContext();
    const navigate = useNavigate();

    const [taskUsers, setTaskUsers] = React.useState<TaskUser[]>();

    const load = async () => {
      setTaskUsers(undefined);
      try {
        const request: ListTaskUsersRequest = {expand: true};
        if (task) {
          request.taskId = task.id;
        }
        if (type) {
          request.type = type;
        }
        if (user) {
          request.userId = user.id;
        }
        const response: ListResponse<TaskUser> = await apiCall(
          `/location/taskUsers`,
          'GET',
          request,
        );
        setTaskUsers(response.data);
      } catch (e: any) {
        // Some thing might come here
      }
    };

    const handleRowClick: GridEventListener<'rowClick'> = params => {
      const user = params.row as TaskUser;
      navigate(`${context.navigationBase}/tasks/${user.taskId}`);
    };

    React.useImperativeHandle(ref, () => ({
      reload() {
        load();
      },
    }));

    React.useEffect(() => {
      load();
    }, [task, user]);

    return (
      <Card {...rest}>
        <DataTable<TaskUser>
          rows={taskUsers}
          onRowClick={!task && !onDelete ? handleRowClick : undefined}
          columns={[
            !user && {
              field: 'userName',
              headerName: 'User',
              flex: 2,
              minWidth: 300,
              renderCell(params) {
                const {user} = params.row;
                if (!user) {
                  return <em>Deleted</em>;
                }
                return `${user.firstName} ${user.lastName}`;
              },
            },
            !task && {
              field: 'taskName',
              headerName: 'Task',
              flex: 3,
              minWidth: 300,
              renderCell(params) {
                return params.row.task!.name;
              },
            },
            !task && {
              field: 'taskStatus',
              headerName: 'Status',
              renderCell(params) {
                return <TaskStatusView task={params.row.task!} />;
              },
            },
            !task && {
              field: 'deadline',
              headerName: 'Deadline',
              renderCell(params) {
                const dueTime = params.row.task!.dueTime;
                if (!dueTime) {
                  return <em>None</em>;
                }
                return format(dueTime, 'MMMM dd, HH:mm');
              },
            },
            !!onDelete && {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 2,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={0}
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={() => onDelete(params.row)}
                />,
              ],
            },
          ]}
        />
      </Card>
    );
  },
);

export default TaskAssignmentsCard;
