import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FormAssignmentsCard from 'pages/FormBuilder/FormAssignments';

import {APIError, apiCall} from 'functions/api';
import {useLocationContext} from 'functions/context';

import type {FormStatus, Form} from 'types/forms';

export default function FormAssignmentsPage() {
  const {formId} = useParams<'formId'>();
  const navigate = useNavigate();
  const context = useLocationContext()!;
  const pageLocation = useLocation();
  const [form, setForm] = React.useState<Form>();
  const [tab, setTab] = React.useState<FormStatus>('PENDING');

  React.useEffect(() => {
    apiCall(`/location/forms/${formId}`).then(
      (response: Form) => {
        setForm(response);
      },
      e => {
        if (e instanceof APIError && e.code === 'not_found') {
          navigate(`${context.navigationBase}/forms`, {replace: true});
        }
      },
    );
  }, []);

  const handleAssignmentClick = (form: Form) => {
    let url = `${context.navigationBase}/forms/${form.id}/fill`;
    url += `?from=${encodeURIComponent(pageLocation.pathname)}`;
    navigate(url);
  };

  if (!form) {
    return (
      <Box sx={{textAlign: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Tabs value={tab} onChange={(_, value) => setTab(value)} sx={{mb: 2}}>
        <Tab label="PENDING" value="PENDING" />
        <Tab label="Complete" value="COMPLETE" />
      </Tabs>
      <FormAssignmentsCard
        form={form}
        key={tab}
        status={tab}
        portal="location"
        onRowClick={handleAssignmentClick}
      />
    </Box>
  );
}
