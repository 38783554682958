import React from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormNavbar from 'components/FormNavbar';

import {useFormFillContext} from 'functions/context';
import {apiCall, createAndUploadImage} from 'functions/api';

import type {FormComponentValue, UpdateComponentRequest} from 'types/forms';

import FormComponentView, {isValidValue} from './FormComponent';

export default function FormFill() {
  const context = useFormFillContext();
  const navigate = useNavigate();
  const order = context.page!.order;
  const components = context.components!;

  const componentsOrdered = React.useMemo(
    () => order.map(id => components.find(c => c.id === id)!),
    [components, order],
  );

  const [submitting, setSubmitting] = React.useState(false);
  const [values, setValues] = React.useState<(FormComponentValue | null)[]>(
    () => componentsOrdered.map(component => component.value),
  );
  const [modified, setModified] = React.useState(() => values.map(() => false));

  const associatedIdx = React.useMemo(
    () =>
      componentsOrdered.map(component => {
        if (component.associatedId) {
          return componentsOrdered.findIndex(
            c => c.id === component.associatedId,
          );
        }
        return -1;
      }),
    [componentsOrdered],
  );

  const disabled = React.useMemo(
    () =>
      componentsOrdered.map(component => {
        if (!context.editable) {
          return true;
        }
        if (context.adminAccess) {
          return false;
        }
        const roleId = component.roleId;
        return !!roleId && context.myRole?.id !== roleId;
      }),
    [componentsOrdered, context],
  );

  const roleNames = React.useMemo(
    () =>
      components.map(component => {
        const roleId = component.roleId;
        return context.roles.find(r => r.id === roleId)?.name;
      }),
    [components, context],
  );

  const valid = React.useMemo(
    () =>
      componentsOrdered.every(
        (component, key) =>
          disabled[key] ||
          !!component.associatedId ||
          isValidValue(
            component,
            component.associatedId ? components[associatedIdx[key]] : undefined,
            values[component.associatedId ? associatedIdx[key] : key],
          ),
      ),
    [components, values, disabled],
  );

  const handleValueChange = (idx: number, value: FormComponentValue | null) => {
    const newValues = [...values];
    const newModified = [...modified];
    newValues[idx] = value;
    newModified[idx] = true;
    setValues(newValues);
    setModified(newModified);
  };

  const handleFormSubmit = async () => {
    setSubmitting(true);
    try {
      await Promise.all(
        componentsOrdered.map(async (component, key) => {
          if (
            !component.associatedId &&
            !disabled[key] &&
            modified[key] &&
            values[key]
          ) {
            const value = values[key]!;
            if (value.file && value.file.startsWith('data')) {
              value.file = await createAndUploadImage(
                value.file,
                context.portal,
                'signature',
              );
            }
            const apiURL = `${context.pageAPIBase}/components/${component.id}`;
            const data: UpdateComponentRequest = {value};
            await apiCall(apiURL, 'POST', data);
          }
        }),
      );
      navigate(context.navigationBase);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      console.error(e);
    }
  };

  return (
    <div>
      <FormNavbar
        title={context.page!.name}
        backLink={context.navigationBase}
        button={{
          label: submitting ? 'Submitting' : 'Submit',
          onClick: handleFormSubmit,
          disabled: !valid || submitting,
        }}
      />
      <Box>
        <Container maxWidth="md">
          <Card sx={{mt: 2, py: 2}}>
            {!components.length && (
              <Box sx={{px: 2, mb: 1}}>
                No components added yet. Click the button below to add a new
                component.
              </Box>
            )}
            {componentsOrdered.map((component, idx) => (
              <React.Fragment key={component.id}>
                {component.type === 'HEADING' && idx > 0 && (
                  <Divider sx={{my: 2}} />
                )}
                <FormComponentView
                  key={component.id}
                  component={component}
                  disabled={
                    disabled[component.associatedId ? associatedIdx[idx] : idx]
                  }
                  roleName={
                    roleNames[component.associatedId ? associatedIdx[idx] : idx]
                  }
                  associatedComponent={componentsOrdered[associatedIdx[idx]]}
                  value={
                    values[component.associatedId ? associatedIdx[idx] : idx]
                  }
                  onChange={value =>
                    handleValueChange(
                      component.associatedId ? associatedIdx[idx] : idx,
                      value,
                    )
                  }
                  sx={{px: 2, mb: 1}}
                />
              </React.Fragment>
            ))}
          </Card>
        </Container>
      </Box>
    </div>
  );
}
