import React, {useState} from 'react';

import Card from 'components/Card';
import Details from 'components/Details';
import ChangePasswordDialog from 'components/ChangePasswordDialog';
import ConfirmDialog from 'components/ConfirmDialog';

import {useLocationContext} from 'functions/context';
import {userRoles} from 'functions/users';

import type {UserResponse} from 'types/users';

import UserDialog from './Dialog';

function UserInfoPage() {
  const context = useLocationContext();
  const user: UserResponse = context.extra.user;
  const reload: any = context.extra.reload;

  const [action, setAction] = useState<'edit' | 'change-password' | 'remove'>();
  return (
    <>
      <Card
        title="User Details"
        padding
        menu={[
          {
            label: 'Edit',
            onClick: () => {
              setAction('edit');
            },
          },
          {
            label: 'Change Password',
            onClick: () => {
              setAction('change-password');
            },
          },
          {
            label: 'Remove',
            onClick: () => {
              setAction('remove');
            },
          },
        ]}>
        {user && (
          <>
            <Details label="Name">
              {user.firstName} {user.lastName}
            </Details>
            <Details label="Email">{user.email}</Details>
            {Array.isArray(user.roles) ? (
              <Details
                label="Roles"
                items={user.roles.map(
                  role => userRoles.find(v => v.role === role)!.label,
                )}
              />
            ) : null}
          </>
        )}
      </Card>
      <ChangePasswordDialog
        open={action === 'change-password'}
        onClose={success => {
          if (success) {
            reload();
          }
          setAction(undefined);
        }}
        apiLink={`/location/users/${user.id}`}
      />
      <ConfirmDialog
        open={action === 'remove'}
        onClose={success => {
          if (success) {
            reload();
          }
          setAction(undefined);
        }}
        title="Remove User"
        text="Are you sure you want to remove this user?"
        apiLink={`/location/users/${user.id}`}
      />
      <UserDialog
        open={action === 'edit'}
        user={user}
        onClose={success => {
          if (success) {
            reload();
          }
          setAction(undefined);
        }}
      />
    </>
  );
}

export default UserInfoPage;
