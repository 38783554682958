interface UploadProps {
  url: string;
  file: File | Blob;
  method: string;
  headers: Record<string, string>;
  onProgress?: (progress: number) => void;
  onComplete?: () => void;
  onError?: () => void;
}

interface UploadToGCSProps {
  signedURL: string;
  file: File | Blob;
  onProgress?: (progress: number) => void;
  onComplete?: () => void;
  onError?: () => void;
  acl?: string;
  cache?: boolean;
}

export const storageBase = process.env.REACT_APP_GOOGLE_STORAGE_LINK!;

export function upload({
  url,
  file,
  method,
  headers,
  onProgress,
  onComplete,
  onError,
}: UploadProps) {
  const xhr = new XMLHttpRequest();
  let uploaded = 0;
  xhr.upload.onprogress = evt => {
    if (evt.lengthComputable && onProgress) {
      onProgress(evt.loaded - uploaded);
      uploaded = evt.loaded;
    }
  };
  xhr.onload = () => {
    if (onComplete) {
      onComplete();
    }
  };
  xhr.onerror = () => {
    if (onError) {
      onError();
    }
  };
  xhr.open(method, url);
  Object.entries(headers).forEach(pair => {
    xhr.setRequestHeader(pair[0], pair[1]);
  });
  xhr.send(file);
}

export function uploadToGCS({
  signedURL,
  file,
  onProgress,
  onComplete,
  onError,
  acl = 'project-private',
  cache = false,
}: UploadToGCSProps) {
  const headers: UploadProps['headers'] = {};
  headers['Content-Type'] = file.type;
  headers['Cache-Control'] = cache ? 'public, max-age=31536000' : 'no-store';
  headers['x-goog-acl'] = acl;
  // Uploading the file
  upload({
    url: signedURL,
    file,
    headers,
    onProgress,
    onComplete,
    onError,
    method: 'PUT',
  });
}
