import React from 'react';

import {useNavigate, useSearchParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Chip from '@mui/material/Chip';
import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {format} from 'date-fns';
import {apiCall} from 'functions/api';
import {useLocationContext} from 'functions/context';

import type {ListTasksRequest, Task, TaskType} from 'types/tasks';
import {ListResponse} from 'types/utils';

import TaskDialog from './Dialog';
import TaskStatusView from './Status';
import TaskRepeatView from './Repeat';

export default function ListTasksPage() {
  const context = useLocationContext();
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [tasks, setTasks] = React.useState<Array<Task>>();

  const selectedTab: TaskType = React.useMemo(() => {
    if (query.get('repeating') === '1') {
      return 'REPEATING';
    }
    return 'INSTANCE';
  }, [query]);

  const title =
    selectedTab === 'INSTANCE' ? 'One Time Tasks' : 'Repeating Tasks';

  const loadData = async () => {
    setTasks(undefined);
    const request: ListTasksRequest = {
      type: selectedTab,
    };
    const response: ListResponse<Task> = await apiCall(
      '/location/tasks',
      'GET',
      request,
    );
    setTasks(response.data);
  };

  const handleTabChange = (e: any, value: TaskType) => {
    const type = value as TaskType;
    const params = new URLSearchParams();
    if (type === 'REPEATING') {
      params.set('repeating', '1');
    }
    setQuery(params);
  };

  const handleDialogClose = (reload: boolean) => {
    setDialogOpen(false);
    if (reload) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, [selectedTab]);

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mb: 2,
        }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="One Time" value="INSTANCE" />
          <Tab label="Repeating" value="REPEATING" />
        </Tabs>
      </Box>
      <Card
        title={title}
        button="Create"
        buttonOnClick={() => setDialogOpen(true)}>
        <DataTable<Task>
          rows={tasks}
          onRowClick={e =>
            navigate(`${context.navigationBase}/tasks/${e.row.id}`)
          }
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              flex: 2,
              minWidth: 300,
              renderCell: cell => (
                <span>
                  {cell.row.name}{' '}
                  {!!cell.row.parentTaskId && <Chip label="Repeated" />}
                </span>
              ),
            },
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              renderCell: cell => <TaskStatusView task={cell.row} />,
            },
            selectedTab === 'INSTANCE' && {
              field: 'dueTime',
              headerName: 'Deadline',
              flex: 1,
              renderCell: cell => {
                const dueTime = cell.row.dueTime;
                if (!dueTime) {
                  return <em>None</em>;
                }
                return format(dueTime, 'MMMM dd, HH:mm');
              },
            },
            selectedTab === 'REPEATING' && {
              field: 'repeating',
              headerName: 'Repeats',
              flex: 1,
              renderCell: cell => <TaskRepeatView task={cell.row} />,
            },
          ]}
        />
      </Card>
      <TaskDialog open={dialogOpen} onClose={handleDialogClose} />
    </Box>
  );
}
