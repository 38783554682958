import React, {useEffect, useState} from 'react';

import {GridActionsCellItem, type GridColDef} from '@mui/x-data-grid';
import Card from 'components/Card';
import ConfirmDialog from 'components/ConfirmDialog';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import type {Book} from 'types/books';

import BookDetailDialog from './BookDetailDialog';

const STORAGE_BASE = process.env.REACT_APP_GOOGLE_STORAGE_LINK;

export default function BooksPage() {
  const [books, setBooks] = useState<Book[]>();
  const [open, setOpen] = useState(0);
  const [remove, setRemove] = useState<number>();

  useEffect(() => {
    if (books !== undefined) return;
    apiCall('/admin/books').then(v => {
      if (Array.isArray(v?.data)) {
        setBooks(v.data);
      }
    });
  }, [books]);

  return (
    <div>
      <Card
        title="Books List"
        button="Add"
        buttonOnClick={() => {
          setOpen(-1);
        }}>
        <DataTable
          rows={books}
          columns={
            [
              {
                field: 'name',
                headerName: 'Name',
                minWidth: 200,
                flex: 2,
                sortable: false,
              },
              {
                field: 'description',
                headerName: 'Description',
                minWidth: 200,
                flex: 3,
                sortable: false,
              },
              {
                field: 'actions',
                type: 'actions',
                minWidth: 100,
                flex: 2,
                align: 'right',
                getActions: params => [
                  <GridActionsCellItem
                    key={0}
                    showInMenu
                    label="View File"
                    onClick={() => window.open(STORAGE_BASE + params.row.file)}
                  />,
                  <GridActionsCellItem
                    key={1}
                    showInMenu
                    label="Edit"
                    onClick={() => {
                      setOpen(params.row.id);
                    }}
                  />,
                  <GridActionsCellItem
                    key={2}
                    showInMenu
                    label="Remove"
                    onClick={() => {
                      setRemove(params.row.id);
                    }}
                  />,
                ],
              },
            ] as Array<GridColDef<Book>>
          }
        />
      </Card>
      <BookDetailDialog
        open={!!open}
        book={books?.find(v => v.id === open)}
        onClose={s => {
          if (s) setBooks(undefined);
          setOpen(0);
        }}
      />
      <ConfirmDialog
        open={remove !== undefined}
        title="Remove Book"
        onClose={() => {
          setRemove(undefined);
        }}
        text="Are you sure to remove this book?"
        confirmButton="Remove"
        onConfirm={async () => {
          await apiCall(`/admin/books/${remove}`, 'DELETE').then(() => {
            setBooks(undefined);
          });
        }}
      />
    </div>
  );
}
