import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import {GridActionsCellItem} from '@mui/x-data-grid';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import {useLocationContext} from 'functions/context';

// import FormAssignmentsPage from './Assignments';
import TrainingDetailDialog from 'pages/Training/DetailDialog';
import {Training} from 'types/trainings';
import TrainingAssignmentsPage from './Assignments';

export function TrainingsListPage() {
  const [trainings, setTrainings] = useState<Training[]>();
  const [open, setOpen] = useState(false);

  const locationContext = useLocationContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (trainings !== undefined) return;
    apiCall('/location/trainings', 'GET').then(v => {
      if (Array.isArray(v?.data)) {
        setTrainings(v.data);
      } else {
        setTrainings([]);
      }
    });
  }, [trainings]);

  const handleViewTraining = (id: number) => {
    navigate(
      `${locationContext.navigationBase}/trainings/${id}/builder?from=${locationContext.navigationBase}/trainings`,
    );
  };
  const handleViewAssignment = (id: number) => {
    navigate(
      `${locationContext.navigationBase}/trainings/${id}/assignments`,
    );
  };

  return (
    <div>
      <Card
        title="Trainings"
        button="Add"
        buttonOnClick={() => {
          setOpen(true);
        }}>
        <DataTable<Training>
          rows={trainings}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Description',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'creator',
              headerName: 'Creator',
              minWidth: 200,
              flex: 2,
              sortable: false,
              renderCell: ({row}) =>
                row.groupId ? 'Admin' : locationContext.location.name,
            },
            {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 2,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={1}
                  showInMenu
                  label="View Training"
                  onClick={()=>handleViewTraining(params.row.id)}
                />,
                <GridActionsCellItem
                  key={0}
                  showInMenu
                  label="View Assignments"
                  onClick={()=>handleViewAssignment(params.row.id)}
                />,
              ],
            },
          ]}
        />
      </Card>
      <TrainingDetailDialog
        apiUrl="/location/trainings"
        onSubmit={() => setTrainings(undefined)}
        onClose={() => setOpen(false)}
        open={open}
      />
    </div>
  );
}

export default function TrainingsPage() {
  const {navigationBase} = useLocationContext();
  return (
    <Routes>
      <Route path="/:trainingId/assignments" element={<TrainingAssignmentsPage />} />
      <Route path="/" element={<TrainingsListPage />} />
      <Route
        path="*"
        element={<Navigate to={`${navigationBase}/trainings`} />}
      />
    </Routes>
  );
}
