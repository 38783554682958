import React from 'react';
import {useLocationContext} from 'functions/context';

import type {UserResponse} from 'types/users';
import DocumentCard from '../Documents/DocumentCard';

function UserDocumentsPage() {
  const context = useLocationContext();
  const user: UserResponse = context.extra.user;

  return (
    <DocumentCard
      categoryPortal="LOCATION"
      portal="location"
      filterNone
      userId={user.id}
    />
  );
}

export default UserDocumentsPage;
