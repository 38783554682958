import React from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from 'components/Dialog';

import {apiCall} from 'functions/api';

import type {Task} from 'types/tasks';

interface Props {
  open: boolean;
  onClose: (reload: boolean) => void;
  task: Task;
}

export default function CompleteDialog(props: Props) {
  const [list, setList] = React.useState<boolean[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();

  const error = React.useMemo(() => !list.every(item => item), [list]);

  const handleItemChange = (idx: number, checked: boolean) => {
    const clone = [...list];
    clone[idx] = checked;
    setList(clone);
  };

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = async () => {
    if (submitting) {
      return;
    }
    setServerError(undefined);
    setSubmitting(true);
    try {
      await apiCall(`/location/tasks/${props.task.id}/complete`, 'POST');
      setSubmitting(false);
      props.onClose(true);
    } catch (e: any) {
      setSubmitting(false);
      setServerError(e?.message || 'An unknown error occurred.');
    }
  };

  React.useEffect(() => {
    if (props.open) {
      const {task} = props;
      setServerError(undefined);
      setList(task.checklist.map(() => false));
    }
  }, [props.open, props.task]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Mark Complete"
      errorMessage={serverError}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled: error || submitting,
        },
      ]}>
      {props.task.checklist.map((item, idx) => (
        <Box key={idx}>
          <FormControlLabel
            control={
              <Checkbox
                checked={list[idx]}
                onChange={e => handleItemChange(idx, e.target.checked)}
              />
            }
            label={item}
          />
        </Box>
      ))}
    </Dialog>
  );
}
