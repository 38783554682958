import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import FormAssignmentsCard from 'pages/FormBuilder/FormAssignments';
import {useLocationContext} from 'functions/context';

import type {ClientResponse} from 'types/clients';
import type {Form} from 'types/forms';

function ClientFormsPage() {
  const context = useLocationContext();
  const navigate = useNavigate();
  const client: ClientResponse = context.extra.client;
  const pageLocation = useLocation();

  const handleAssignmentClick = (_form: Form) => {
    let url = `${context.navigationBase}/forms/${_form.id}/builder`;
    url += `?from=${encodeURIComponent(pageLocation.pathname)}`;
    navigate(url);
  };

  return (
    <FormAssignmentsCard
      title="Form Assignment"
      userId={client.id}
      portal="location"
      onRowClick={handleAssignmentClick}
    />
  );
}

export default ClientFormsPage;
