import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import SignatureDialog from 'components/SignatureDialog';
import {dateRegex, emailRegex} from 'components/ValidatedInput';

import CheckedIcon from '@mui/icons-material/CheckBoxOutlined';
import SignatureIcon from '@mui/icons-material/Edit';
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import {documentTextComponentTypes} from 'functions/forms';
import {storageBase} from 'functions/uploader';

import {dangerColor, successColor} from 'assets/jss/main';

import type {
  DocumentComponentProps,
  DocumentComponentType,
} from 'types/formBuilder';
import type {FormComponent, FormComponentValue} from 'types/forms';

interface Props {
  component: FormComponent;
  associatedComponent?: FormComponent;
  disabled?: boolean;
  viewOnly?: boolean;
  value?: FormComponentValue | null;
  onChange?: (value: FormComponentValue | null) => void;
  roleName?: string;
}

export function isValidValue(
  component: FormComponent,
  associatedComponent: FormComponent | undefined | null,
  value: FormComponentValue | null,
) {
  const type = component.type as DocumentComponentType;
  const textValue = value?.text || '';
  const isRequired = associatedComponent
    ? associatedComponent.isRequired
    : component.isRequired;
  if (type === 'CHECKBOX') {
    return true;
  }
  if (!isRequired && !value) {
    return true;
  }
  if (documentTextComponentTypes.includes(type) && !textValue.length) {
    return !isRequired;
  }
  return (
    (type === 'DATE' && dateRegex.test(textValue)) ||
    (type === 'EMAIL' && emailRegex.test(textValue)) ||
    (type === 'TEXT' && !!textValue.length && textValue.length < 255) ||
    (type === 'LONG_TEXT' && !!textValue.length && textValue.length < 1000) ||
    (type === 'SIGNATURE' && value?.file)
  );
}

export default function DocumentComponentView(props: Props) {
  const {component, associatedComponent, disabled, value, viewOnly: viewMode, onChange} =
    props;
  const type = component.type as DocumentComponentType;

  const [signatureDialogOpen, setSignatureDialogOpen] = React.useState(false);

  const valid = React.useMemo(
    () => isValidValue(component, associatedComponent, value || null),
    [component, associatedComponent, value],
  );

  const componentProps: DocumentComponentProps = {
    ...associatedComponent?.properties,
    ...component.properties,
  };

  const isRequired = associatedComponent
    ? associatedComponent.isRequired
    : component.isRequired;

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    if (!disabled && onChange) {
      onChange({text: event.target.value});
    }
  };

  const handleCheckboxClick = () => {
    if (!disabled && onChange) {
      onChange({checked: !value?.checked});
    }
  };

  const handleSignatureClick = () => {
    if (!disabled) {
      setSignatureDialogOpen(true);
    }
  };

  const handleSignatureDialogClose = (image?: string) => {
    setSignatureDialogOpen(false);
    if (image && onChange) {
      onChange({file: image});
    }
  };

  React.useEffect(() => {
    if (component.type === 'CHECKBOX' && !value && onChange) {
      onChange({checked: false});
    }
  }, [value, component]);

  const commonStyles: React.CSSProperties = {
    position: 'absolute',
    top: componentProps.y,
    left: componentProps.x,
    width: componentProps.width,
    height: componentProps.height,
    border: 'none',
  };

  if (componentProps.fontSize) {
    commonStyles.fontSize = `${componentProps.fontSize}px`;
    commonStyles.lineHeight = `${componentProps.fontSize}px`;
  }

  if (type !== 'CHECKBOX' && !disabled && !viewMode) {
    commonStyles.outline = `1px solid ${
      valid ? successColor[0] : dangerColor[0]
    }`;
  }

  let view: React.ReactNode = null;
  if (documentTextComponentTypes.includes(type)) {
    let placeholder = 'Text';
    if (component.type === 'LONG_TEXT') {
      placeholder = 'Long Text';
    } else if (component.type === 'EMAIL') {
      placeholder = 'Email';
    } else if (component.type === 'DATE') {
      placeholder = 'Date (YYYY-MM-DD)';
    }
    placeholder += '...';
    if (isRequired) {
      placeholder += ' *';
    }

    if (type === 'LONG_TEXT') {
      view = (
        <textarea
          placeholder={placeholder}
          value={value?.text || ''}
          disabled={disabled}
          onChange={handleInputChange}
          style={{
            outline: '1px solid #aaaaaa',
            ...commonStyles,
            resize: 'none',
          }}
        />
      );
    } else {
      view = (
        <input
          placeholder={placeholder}
          type="text"
          value={value?.text || ''}
          disabled={disabled}
          onChange={handleInputChange}
          style={{
            outline: '1px solid #aaaaaa',
            ...commonStyles,
            height: `${componentProps.fontSize! + 6}px`,
          }}
        />
      );
    }
  }
  if (component.type === 'CHECKBOX') {
    const props = {
      sx: {
        ...commonStyles,
        cursor: 'pointer',
        backgroundColor: disabled ? '#E5E5E522' : undefined,
      },
      onClick: handleCheckboxClick,
    };
    view = value?.checked ? (
      <CheckedIcon {...props} />
    ) : (
      <UncheckedIcon {...props} />
    );
  }
  if (component.type === 'SIGNATURE') {
    let file = value?.file;
    if (file && !file.startsWith('data')) {
      file = storageBase + file;
    }
    view = (
      <Box
        onClick={handleSignatureClick}
        sx={{
          ...commonStyles,
          cursor: disabled ? 'default' : 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#444444',
          backgroundColor: disabled ? '#FFFFFFAA' : '#FFFFFF',
          backgroundImage: file && `url(${file})`,
          backgroundSize: 'contain',
          outline: (commonStyles.outline as string) || '1px solid #aaaaaa',
        }}>
        {!value?.file && (
          <>
            <SignatureIcon />
            Signature {isRequired && '*'}
          </>
        )}
      </Box>
    );
  }

  if (disabled) {
    view = <Tooltip title={props.roleName}>{view!}</Tooltip>;
  }

  if (type === 'SIGNATURE') {
    view = (
      <>
        {view}
        <SignatureDialog
          open={signatureDialogOpen}
          onClose={handleSignatureDialogClose}
          ratio={componentProps.height! / componentProps.width!}
        />
      </>
    );
  }
  return view;
}
