import React, {useState, useEffect} from 'react';

import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';
import {apiCall} from 'functions/api';
import {TrainingGroup} from 'types/trainings';

export type Props = {
  trainingGroup?: TrainingGroup;
  open: boolean;
  onClose: (s?: boolean) => void;
};

export default function TrainingGroupDialog({
  trainingGroup,
  open,
  onClose,
}: Props) {
  const [name, setName] = useState(initialValue('text', trainingGroup?.name));
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      setName(initialValue('text', trainingGroup?.name));
    }
  }, [open]);

  const handleSubmit = () => {
    setSubmitting(true);
    apiCall(`/admin/trainingGroups/${trainingGroup?.id || ''}`, 'POST', {
      name: name.value,
    }).then(() => {
      setSubmitting(false);
      onClose(true);
    });
  };

  return (
    <Dialog
      open={!!open}
      onSubmit={handleSubmit}
      onClose={onClose}
      title="Add Training Group"
      actions={[
        {
          text: 'Cancel',
          onClick: onClose,
          color: 'secondary',
        },
        {
          text: 'Submit',
          submit: true,
          color: 'primary',
          disabled: !name.success || submitting,
        },
      ]}>
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
    </Dialog>
  );
}
