import React, {useEffect, useMemo, useState} from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Dialog from 'components/Dialog';
import Dropzone from 'components/Dropzone';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {apiCall, createAndUploadFile} from 'functions/api';
import type {Book, UpdateBookRequest} from 'types/books';

const STORAGE_BASE = process.env.REACT_APP_GOOGLE_STORAGE_LINK;

interface Props {
  open: boolean;
  onClose: (submit?: boolean) => void;
  book?: Book;
}

export default function BookDetailDialog(props: Props) {
  const [name, setName] = useState(initialValue('text'));
  const [description, setDescription] = useState(initialValue('text'));
  const [file, setFile] = useState<File>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [progress, setProgress] = useState(0);
  const disabled = useMemo(
    () =>
      !name?.success ||
      !description?.success ||
      submitting ||
      (!props.book && !file),
    [name, submitting, file, props.book],
  );

  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  useEffect(() => {
    setProgress(0);
    if (props.open) {
      setName(initialValue('text', props.book?.name || ''));
      setDescription(initialValue('text', props.book?.description || ''));
      setSubmitting(false);
      setError(undefined);
      setFile(undefined);
    }
  }, [props.open, props.book]);

  return (
    <Dialog
      width="sm"
      title="Book Detail"
      onClose={props.onClose}
      onSubmit={async () => {
        if (disabled) return;
        setSubmitting(true);
        setError(undefined);
        const request: UpdateBookRequest = {
          name: name.value,
          description: description.value,
        };
        if (file) {
          try {
            const gcsFileName = await createAndUploadFile(
              file,
              'admin',
              'book',
              setProgress,
            );
            request.file = gcsFileName;
          } catch (e: any) {
            setSubmitting(false);
            setError(e?.message || 'An error occurred');
            return;
          }
        }
        apiCall(`/admin/books/${props.book?.id || ''}`, 'POST', request).then(
          () => {
            props.onClose(true);
            setSubmitting(false);
          },
          ({message}) => {
            setError(message || 'An error occurred.');
            setSubmitting(false);
          },
        );
      }}
      open={props.open}
      actions={[
        {
          text: 'Cancel',
          onClick: () => {
            props.onClose();
          },
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled,
        },
      ]}
      progress={progress ? {value: progress} : undefined}>
      <ValidatedInput
        type="text"
        value={name}
        onChange={setName}
        label="Name"
      />
      <ValidatedInput
        type="text"
        value={description}
        onChange={setDescription}
        label="Description"
        multiline
        rows={2}
      />
      <Typography mt={1} fontSize={14} lineHeight={2}>
        File
        {!!props.book?.file && (
          <Button
            variant="text"
            sx={{float: 'right', py: 0}}
            size="small"
            href={STORAGE_BASE + props.book!.file}
            target="_blank">
            View
          </Button>
        )}
      </Typography>
      <Dropzone type="document" onDrop={handleDrop} file={file} />
      {error && (
        <Typography color="error" mt={1} fontSize={14}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}
