import React from 'react';
import {useParams} from 'react-router-dom';

import FullPageLoader from 'components/FullPageLoader';

import {apiCall} from 'functions/api';
import {
  FormFillContext,
  FormFillContextType,
  useFormFillContext,
} from 'functions/context';

import type {FormComponent, FormPage} from 'types/forms';
import type {ListResponse} from 'types/utils';

import DocumentFill from './Document';
import FormFill from './Form';

export default function FormFillPage() {
  const pageId = useParams<'pageId'>().pageId;
  const context = useFormFillContext()!;

  const [page, setPage] = React.useState<FormPage>();
  const [components, setComponents] = React.useState<FormComponent[]>();

  const pageAPIBase = React.useMemo(
    () => `${context.formsAPIBase}/${context.form.id}/pages/${pageId}`,
    [context.formsAPIBase, context.form, pageId],
  );

  const loadPage = () => {
    setPage(undefined);
    apiCall(pageAPIBase, 'GET').then((response: FormPage) => {
      setPage(response);
    }, console.error);
  };

  const loadComponents = () => {
    setComponents(undefined);
    apiCall(`${pageAPIBase}/components`, 'GET').then(
      (response: ListResponse<FormComponent>) => {
        setComponents(response.data);
      },
      console.error,
    );
  };

  React.useEffect(loadPage, [pageId]);
  React.useEffect(loadComponents, [pageId]);

  const updatedContext = React.useMemo<FormFillContextType>(
    () => ({
      ...context,
      components,
      page,
      pageAPIBase,
    }),
    [context, components, page, pageAPIBase],
  );

  if (!page || !components) return <FullPageLoader />;

  return (
    <FormFillContext.Provider value={updatedContext}>
      {page.type === 'DOCUMENT' ? <DocumentFill /> : <FormFill />}
    </FormFillContext.Provider>
  );
}
