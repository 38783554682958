import React, {useEffect, useState} from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import {GridActionsCellItem} from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import FormDialog from 'pages/FormBuilder/Main/FormDialog';

import {apiCall} from 'functions/api';
import {useLocationContext} from 'functions/context';

import type {
  Form,
  FormRolePortal,
  FormType,
  ListFormsRequest,
} from 'types/forms';
import type {ListResponse} from 'types/utils';

import FormAssignmentsPage from './Assignments';

type Filter =
  | 'autoAssignClient'
  | 'autoAssignUser'
  | 'formComplete'
  | 'formIncomplete'
  | 'template';

function createFormName(params: {row: Form}) {
  const form = params.row;
  return (
    <>
      {form.name}
      {!form.locationId && <Chip label="Admin" sx={{ml: 1}} />}
    </>
  );
}

function FormsListPage() {
  const context = useLocationContext();
  const pageLocation = useLocation();
  const [queryParams, setQueryParams] = useSearchParams();
  const navigate = useNavigate();

  const [autoAssignKey, setAutoAssignKey] = useState<FormRolePortal>();
  const [dialogType, setDialogType] = useState<FormType>();
  const [forms, setForms] = useState<Array<Form>>();

  const filter = React.useMemo<Filter>(() => {
    if (queryParams.get('autoAssign') === 'client') {
      return 'autoAssignClient';
    }
    if (queryParams.get('autoAssign') === 'user') {
      return 'autoAssignUser';
    }
    if (queryParams.get('form') === 'complete') {
      return 'formComplete';
    }
    if (queryParams.get('form') === 'incomplete') {
      return 'formIncomplete';
    }
    return 'template';
  }, [queryParams]);

  const title: string = React.useMemo(() => {
    if (filter === 'autoAssignClient') {
      return 'Client Default';
    }
    if (filter === 'autoAssignUser') {
      return 'User Default';
    }
    if (filter === 'formComplete') {
      return 'Complete Forms';
    }
    if (filter === 'formIncomplete') {
      return 'Incomplete Forms';
    }
    return 'Templates';
  }, [filter]);

  const loadData = () => {
    const request: ListFormsRequest = {};
    if (filter === 'autoAssignClient') {
      request.autoAssign = 'CLIENT';
    } else if (filter === 'autoAssignUser') {
      request.autoAssign = 'LOCATION';
    } else if (filter === 'formComplete') {
      request.status = 'COMPLETE';
      request.type = 'INSTANCE';
    } else if (filter === 'formIncomplete') {
      request.status = 'PENDING';
      request.type = 'INSTANCE';
    } else {
      request.type = 'TEMPLATE';
    }
    setForms(undefined);
    apiCall('/location/forms', 'GET', request).then(
      (response: ListResponse<Form>) => {
        setForms(response.data);
      },
      console.error,
    );
  };

  const handleDialogClose = (reload: boolean, form?: Form) => {
    setDialogType(undefined);
    if (reload) {
      if (form?.type === 'INSTANCE') {
        const backValue = encodeURIComponent(
          pageLocation.pathname + pageLocation.search,
        );
        navigate(
          `${context.navigationBase}/forms/${form.id}/builder?from=${backValue}`,
        );
      } else {
        loadData();
      }
    }
  };

  const handleViewAssignments = (form: Form) => {
    navigate(`${context.navigationBase}/forms/${form.id}/assignments`);
  };

  const handleEdit = (form: Form) => {
    const backValue = encodeURIComponent(
      pageLocation.pathname + pageLocation.search,
    );
    navigate(
      `${context.navigationBase}/forms/${form.id}/builder?from=${backValue}`,
    );
  };

  const handleRowClick =
    filter === 'formComplete' || filter === 'formIncomplete'
      ? ({row}: any) => {
          const form = row as Form;
          const backValue = encodeURIComponent(
            pageLocation.pathname + pageLocation.search,
          );
          navigate(
            `${context.navigationBase}/forms/${form.id}/fill?from=${backValue}`,
          );
        }
      : undefined;

  const handleAddTemplate = () => {
    setDialogType('TEMPLATE');
    setAutoAssignKey(undefined);
  };

  const handleAddInstant = () => {
    setDialogType('INSTANCE');
    setAutoAssignKey(undefined);
  };

  const handleAddUserDefault = () => {
    setDialogType('TEMPLATE');
    setAutoAssignKey('LOCATION');
  };

  const handleAddClientDefault = () => {
    setDialogType('TEMPLATE');
    setAutoAssignKey('CLIENT');
  };

  const handleTabChange = (event: any, value: string) => {
    const params = new URLSearchParams();
    if (value === 'autoAssignClient') {
      params.set('autoAssign', 'client');
    } else if (value === 'autoAssignUser') {
      params.set('autoAssign', 'user');
    } else if (value === 'formComplete') {
      params.set('form', 'complete');
    } else if (value === 'formIncomplete') {
      params.set('form', 'incomplete');
    }
    setQueryParams(params);
  };

  useEffect(() => {
    loadData();
  }, [filter]);

  return (
    <div>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mb: 2,
        }}>
        <Tabs value={filter} onChange={handleTabChange}>
          <Tab label="Templates" value="template" />
          <Tab label="Client Default" value="autoAssignClient" />
          <Tab label="User Default" value="autoAssignUser" />
          <Tab label="Complete Forms" value="formComplete" />
          <Tab label="Incomplete Forms" value="formIncomplete" />
        </Tabs>
      </Box>
      <Card
        title={title}
        button="Create"
        buttonMenu={[
          {label: 'Template', onClick: handleAddTemplate},
          {label: 'Instant Form', onClick: handleAddInstant},
          {label: 'User Default Template', onClick: handleAddUserDefault},
          {label: 'Client Default Template', onClick: handleAddClientDefault},
        ]}>
        <DataTable<Form>
          rows={forms}
          onRowClick={handleRowClick}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
              renderCell: createFormName,
            },
            {
              field: 'description',
              headerName: 'Description',
              minWidth: 200,
              flex: 3,
              sortable: false,
            },
            filter !== 'formComplete' &&
              filter !== 'formIncomplete' && {
                field: 'actions',
                type: 'actions',
                minWidth: 100,
                flex: 2,
                align: 'right',
                getActions: params => [
                  <GridActionsCellItem
                    key={1}
                    showInMenu
                    hidden={!params.row.locationId}
                    label="Edit Template"
                    onClick={() => handleEdit(params.row)}
                  />,
                  <GridActionsCellItem
                    key={0}
                    showInMenu
                    label="View Assignments"
                    onClick={() => handleViewAssignments(params.row)}
                  />,
                ],
              },
          ]}
        />
      </Card>
      <FormDialog
        open={!!dialogType}
        type={dialogType || 'TEMPLATE'}
        autoAssign={autoAssignKey}
        portal="location"
        onClose={handleDialogClose}
      />
    </div>
  );
}

export default function FormsPage() {
  const {navigationBase} = useLocationContext();
  return (
    <Routes>
      <Route path="/:formId/assignments" element={<FormAssignmentsPage />} />
      <Route path="/" element={<FormsListPage />} />
      <Route path="*" element={<Navigate to={`${navigationBase}/forms`} />} />
    </Routes>
  );
}
