import React from 'react';

import Box from '@mui/material/Box';

import { useLocationContext} from 'functions/context';
import TrainingAssignmentCard from 'pages/Training/TrainingAssignments';
import { useNavigate } from 'react-router-dom';

export default function MyTrainingsPage() {
  const locationContext = useLocationContext();
  const navigate=useNavigate()
  if (!locationContext?.authUser?.user?.id) return null;
  return (
    <Box>
      <TrainingAssignmentCard
        type="userFill"
        typeId={locationContext.authUser.user.id}
        onPress={(a)=>navigate(`${locationContext.navigationBase}/trainings/${a.trainingId}/fill/${a.id}`)}
      />
    </Box>
  );
}
