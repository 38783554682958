import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

type Props = Omit<React.ComponentProps<typeof FormControl>, 'onChange'> & {
  label: string;
  value: string | string[];
  items: Array<{value: string; label: string}>;
  onChange: (value: any) => void;
  multiple?: boolean;
};

function Select(props: Props) {
  const {label, value, onChange, ...rest} = props;

  return (
    <FormControl {...rest}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <MUISelect
        value={value}
        multiple={props.multiple}
        label={label}
        onChange={e => props.onChange(e.target.value)}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              width: 250,
            },
          },
        }}>
        {props.items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
}

export default Select;
