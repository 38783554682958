import React from 'react';

import {blackColor, dangerColor, successColor} from 'assets/jss/main';

import type {Task, TaskStatus} from 'types/tasks';

const statusNames: {[key in TaskStatus]: string} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  COMPLETE: 'Complete',
  LATE_COMPLETE: 'Complete (Late)',
  PENDING: 'Pending',
};

export default function TaskStatusView(props: {task: Task}) {
  const status = props.task.status;
  let name = statusNames[props.task.status];
  let color = blackColor;

  if (status === 'ACTIVE' || status === 'COMPLETE') {
    color = successColor[0];
  } else if (status === 'INACTIVE' || status === 'LATE_COMPLETE') {
    color = dangerColor[0];
  } else if (props.task.dueTime && Date.now() > props.task.dueTime) {
    name += ' (Late)';
    color = dangerColor[0];
  }

  return <span style={{color}}>{name}</span>;
}
