import {format} from 'date-fns';

import {type Location} from 'react-router-dom';

export function activeRoute(routeName: string, location: Location) {
  return location.pathname.startsWith(routeName.replace('/*', ''));
}

export function formatDate(dateString: string | number | Date) {
  return format(new Date(dateString), 'MMMM d, yyyy');
}

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
