import React from 'react';
import {weekDays} from 'functions/utils';
import type {Task} from 'types/tasks';

export default function TaskRepeatView(props: {task: Task}) {
  const {repeatTime, repeatPeriod} = props.task;

  if (repeatPeriod === 'DAILY') {
    return <span>Every day</span>;
  }
  if (repeatPeriod === 'WEEKLY' && repeatTime) {
    return <span>Every {weekDays[repeatTime]}</span>;
  }
  if (repeatPeriod === 'MONTHLY' && repeatTime) {
    if (repeatTime === -1) {
      return <span>Every month (last day)</span>;
    }
    return <span>Every month (day {repeatTime})</span>;
  }
  return <em>None</em>;
}
