import React, {useEffect, useMemo, useRef, useState} from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';

import {navbarHeight} from 'components/FormNavbar';
import Select from 'components/Select';
import ConfirmDialog from 'components/ConfirmDialog';

import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';

import {apiCall} from 'functions/api';
import {useFormBuilderContext} from 'functions/context';
import {storageBase} from 'functions/uploader';
import {
  defaultImageWidth,
  documentComponentIcons,
  documentComponentNames,
  documentComponentTypes,
  documentTextComponentTypes,
} from 'functions/forms';

import type {
  FormComponent,
  InsertComponentRequest,
  UpdateComponentRequest,
} from 'types/forms';
import type {
  DocumentComponentProps,
  DocumentComponentType,
  FormBuilderPageProps,
} from 'types/formBuilder';
import type {ListResponse} from 'types/utils';

import DocumentComponentView, {
  defaultProps,
  fontSizeOptions,
} from './DocumentComponent';

function DocumentFormBuilder(props: FormBuilderPageProps) {
  const ref = useRef<HTMLDivElement>();
  const context = useFormBuilderContext();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [componentToAdd, setComponentToAdd] = useState<DocumentComponentType>();
  const [components, setComponents] = useState<FormComponent[]>([]);
  const [activeIdx, setActiveIdx] = useState(-1);
  const [imageHeight, setImageHeight] = useState(0);
  const [scale, setScale] = useState(1);
  const [ongoingCalls, setOngoingCalls] = useState(0);

  const apiBase = props.apiBase;
  const associatedComponents = useMemo(
    () =>
      components.map(component => {
        if (component.associatedId) {
          return components.find(c => c.id === component.associatedId);
        }
        return undefined;
      }),
    [components],
  );
  const hasAssociatedComponents = useMemo(
    () =>
      activeIdx !== -1 &&
      components.some(c => c.associatedId === components[activeIdx]?.id),
    [associatedComponents, activeIdx],
  );
  const roleMenuItems = useMemo(
    () => [
      {label: 'Anyone', value: '-1'},
      ...context.roles.map(role => ({
        label: role.name,
        value: role.id.toString(),
      })),
    ],
    [context.roles],
  );

  const updateComponentOnAPI = React.useCallback((component: FormComponent) => {
    setOngoingCalls(ongoingCalls => ongoingCalls + 1);
    const data: UpdateComponentRequest = {
      associatedId: component.associatedId,
      isRequired: component.isRequired,
      properties: component.properties,
      roleId: component.roleId,
    };
    apiCall(`${apiBase}/components/${component.id}`, 'POST', data).then(() => {
      setOngoingCalls(ongoingCalls => ongoingCalls - 1);
    }, console.error);
  }, []);

  const updateComponent = React.useCallback(
    (idx: number, changes: Partial<FormComponent>) => {
      const copy = [...components];
      copy[idx] = {
        ...copy[idx],
        ...changes,
      };
      updateComponentOnAPI(copy[idx]);
      setComponents(copy);
    },
    [components],
  );

  const updateComponentProperties = React.useCallback(
    (idx: number, changes: Partial<DocumentComponentProps>) =>
      updateComponent(idx, {
        properties: {...components[idx].properties, ...changes},
      }),
    [updateComponent, components],
  );

  const createOnComponentChange =
    (i: number) => (component: Partial<DocumentComponentProps>) =>
      updateComponentProperties(i, component);

  const addComponent = React.useCallback(
    (request: InsertComponentRequest) => {
      const mockComponent: FormComponent = {
        value: null,
        associatedId: null,
        roleId: null,
        id: -1,
        formId: context.form.id,
        pageId: props.page.id,
        ...request,
      };
      const index = components.length;
      setComponents([...components, mockComponent]);
      setActiveIdx(index);
      setComponentToAdd(undefined);
      setOngoingCalls(ongoingCalls => ongoingCalls + 1);
      apiCall(`${apiBase}/components`, 'POST', request).then(
        (response: FormComponent) => {
          setComponents(components => {
            const copy = [...components];
            copy[index] = response;
            return copy;
          });
          setOngoingCalls(ongoingCalls => ongoingCalls - 1);
        },
        console.error,
      );
    },
    [components],
  );

  const handleDocumentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!componentToAdd) {
      setActiveIdx(-1);
      return;
    }
    const {target} = e;
    // @ts-expect-error
    const rect = target.getBoundingClientRect();
    const x = (e.clientX - rect.left) / scale;
    const y = (e.clientY - rect.top) / scale;

    const componentProps: DocumentComponentProps = {
      ...defaultProps[componentToAdd],
      x,
      y,
    };
    const insertRequest: InsertComponentRequest = {
      type: componentToAdd,
      properties: componentProps,
      isRequired: componentToAdd !== 'CHECKBOX',
    };
    addComponent(insertRequest);
  };

  const handleDeleteComponent = () => {
    if (activeIdx === -1 || !context.editable) {
      return;
    }
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogConfirm = () => {
    const component = components[activeIdx];
    setOngoingCalls(ongoingCalls => ongoingCalls + 1);
    return apiCall(`${apiBase}/components/${component.id}`, 'DELETE')
      .then(() => apiCall(`${apiBase}/components`))
      .then((response: ListResponse<FormComponent>) => {
        setComponents(response.data);
        setOngoingCalls(ongoingCalls => ongoingCalls - 1);
        setActiveIdx(-1);
        setDeleteDialogOpen(false);
      }, console.error);
  };

  const handleRequiredClick = () => {
    updateComponent(activeIdx, {
      ...components[activeIdx],
      isRequired: !components[activeIdx].isRequired,
    });
  };

  const handleCloneComponent = () => {
    if (activeIdx === -1) {
      return;
    }
    const component = components[activeIdx];
    const request: InsertComponentRequest = {
      type: component.type,
      associatedId: component.id,
      isRequired: component.isRequired,
      properties: {
        x: component.properties.x + 10,
        y: component.properties.y + 10,
        width: component.properties.width,
        height: component.properties.height,
        fontSize: component.properties.fontSize,
      },
    };
    addComponent(request);
  };

  const handleRoleChange = (value: string) => {
    updateComponent(activeIdx, {
      roleId: value === '-1' ? null : parseInt(value, 10),
    });
  };

  const handleFontSizeChange = (value: string) => {
    updateComponentProperties(activeIdx, {
      ...components[activeIdx],
      fontSize: parseInt(value, 10),
    });
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setImageHeight(
      (e.currentTarget.naturalHeight / e.currentTarget.naturalWidth) *
        defaultImageWidth,
    );
  };

  useEffect(() => {
    if (!context.editable) {
      return;
    }
    const eventHandler = (e: KeyboardEvent) => {
      // Check if delete key was pressed
      if (e.key === 'Delete' && activeIdx !== -1) {
        handleDeleteComponent();
      }
    };
    document.addEventListener('keydown', eventHandler);
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('keydown', eventHandler);
  }, [activeIdx, context.editable]);

  useEffect(() => setComponents(props.components), [props.components]);

  useEffect(() => {
    props.updateNavbarProps({
      ...props.navbarProps,
      autoSaveStatus: ongoingCalls === 0 ? 'saved' : 'saving',
    });
  }, [ongoingCalls]);

  return (
    <Box
      sx={{
        height: `calc(100vh - ${navbarHeight}px)`,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          cursor: componentToAdd ? 'copy' : 'auto',
        }}>
        {context.editable && (
          <Box
            sx={{
              flexBasis: '250px',
              flexGrow: 0,
              flexShrink: 0,
              overflow: 'auto',
            }}>
            <Typography fontSize={18} sx={{px: 2, pt: 2, pb: 1}}>
              Components
            </Typography>
            <List sx={{py: 0}}>
              {documentComponentTypes.map(type => (
                <ListItem disablePadding key={type}>
                  <ListItemButton
                    onClick={() => setComponentToAdd(type)}
                    selected={componentToAdd === type}>
                    <ListItemIcon sx={{minWidth: '40px'}}>
                      {React.createElement(documentComponentIcons[type])}
                    </ListItemIcon>
                    <ListItemText primary={documentComponentNames[type]} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider sx={{my: 1}} />
            <Box sx={{p: 2}}>
              <Typography sx={{opacity: 0.8, mb: 1}} fontSize={14}>
                Properties
              </Typography>
              {activeIdx !== -1 && components[activeIdx].id === -1 && (
                <div style={{textAlign: 'center'}}>
                  <CircularProgress />
                </div>
              )}
              {activeIdx !== -1 && components[activeIdx].id !== -1 && (
                <>
                  {components[activeIdx].type !== 'CHECKBOX' &&
                    !components[activeIdx].associatedId && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          mb: 1,
                        }}>
                        <ListItemText primary="Required" />
                        <Switch
                          checked={!!components[activeIdx].isRequired}
                          onClick={handleRequiredClick}
                        />
                      </Box>
                    )}
                  {documentTextComponentTypes.includes(
                    components[activeIdx].type as any,
                  ) && (
                    <Select
                      label="Font Size"
                      value={components[
                        activeIdx
                      ].properties.fontSize!.toString()}
                      sx={{mb: 1}}
                      items={fontSizeOptions.map(n => ({
                        label: n.toString(),
                        value: n.toString(),
                      }))}
                      onChange={handleFontSizeChange}
                    />
                  )}
                  {!components[activeIdx].associatedId && (
                    <Select
                      label="Fill By"
                      value={components[activeIdx]?.roleId?.toString() || '-1'}
                      sx={{mb: 1}}
                      items={roleMenuItems}
                      onChange={handleRoleChange}
                    />
                  )}
                  {hasAssociatedComponents && (
                    <Typography sx={{mb: 1, fontStyle: 'italic'}} fontSize={12}>
                      Some fields are associated with this field.
                    </Typography>
                  )}
                  {!components[activeIdx].associatedId && (
                    <Button
                      fullWidth
                      size="small"
                      variant="outlined"
                      onClick={handleCloneComponent}
                      color="primary"
                      sx={{mb: 1}}>
                      Clone
                    </Button>
                  )}
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={handleDeleteComponent}
                    color="error">
                    Delete
                  </Button>
                </>
              )}
              {activeIdx === -1 && (
                <Typography
                  sx={{
                    pl: 2,
                    pt: 1,
                    fontStyle: 'italic',
                    opacity: 0.9,
                  }}
                  fontSize={12}>
                  Select a component...
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            backgroundColor: '#e5e5e5',
            position: 'relative',
          }}>
          <Box
            sx={{
              padding: '20px',
              width: `${defaultImageWidth * scale + 40}px`,
              height: `${imageHeight * scale + 40}px`,
              boxSizing: 'border-box',
              margin: '0 auto',
            }}>
            <Box
              ref={ref}
              onClick={handleDocumentClick}
              sx={{
                position: 'relative',
                width: `${defaultImageWidth}px`,
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
              }}>
              <img
                style={{
                  width: '100%',
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
                src={storageBase + props.page.image}
                alt="Document Background"
                onLoad={handleImageLoad}
              />
              {ref.current &&
                components.map((component, index) => (
                  <DocumentComponentView
                    scale={scale}
                    active={activeIdx === index}
                    highlight={
                      associatedComponents[activeIdx]?.id === component.id
                    }
                    onClick={() => setActiveIdx(index)}
                    key={index}
                    component={component}
                    associatedComponent={associatedComponents[index]}
                    parentElement={ref.current!}
                    onChange={createOnComponentChange(index)}
                  />
                ))}
            </Box>
          </Box>
          <Box
            sx={{
              position: 'fixed',
              bottom: 20,
              right: 30,
            }}>
            <ButtonGroup orientation="vertical" variant="contained">
              <Button
                size="small"
                disabled={scale >= 1.5}
                onClick={() => setScale(scale + 0.1)}>
                <ZoomIn />
              </Button>
              <Button
                size="small"
                disabled={scale <= 0.4}
                onClick={() => setScale(scale - 0.1)}>
                <ZoomOut />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <ConfirmDialog
        title="Delete Component"
        text="Are you sure you want to delete this component?"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteDialogConfirm}
      />
    </Box>
  );
}

export default DocumentFormBuilder;
