import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import {apiCall} from 'functions/api';

import type {Portal} from 'types/auth';
import type {ListRequest, ListResponse} from 'types/utils';

interface Props {
  label?: string;
  portal: Portal;
  type: 'form' | 'user' | 'client';
  value?: any;
  onChange: (value: any) => void;
  disabled?: boolean;
}

function SearchAutocomplete(props: Props) {
  const [inputValue, setInputValue] = React.useState('');
  const [data, setData] = React.useState<any[]>();
  const [isLoading, setIsLoading] = React.useState(true);

  const getOptionLabel = (option: any) => {
    switch (props.type) {
      case 'form':
        return option.name;
      case 'user':
        return `${option.firstName} ${option.lastName}`;
      case 'client':
        return `${option.firstName} ${option.lastName}`;
      default:
        return '';
    }
  };

  const isOptionEqualToValue = (option: any, value: number) =>
    option.id === value;

  React.useEffect(() => {
    const id = setTimeout(() => {
      const request: ListRequest = {
        search: inputValue || undefined,
        limit: 20,
      };
      let apiURL = '';
      if (props.type === 'form') {
        apiURL = '/location/forms';
      } else if (props.type === 'user') {
        apiURL = '/location/users';
      } else if (props.type === 'client') {
        apiURL = '/location/clients';
      }
      setIsLoading(true);
      apiCall(apiURL, 'GET', request).then(
        (response: ListResponse<any>) => {
          setData(response.data);
          setIsLoading(false);
        },
        (e: any) => {
          setIsLoading(false);
          console.error(e);
        },
      );
    }, 300);

    return () => clearTimeout(id);
  }, [inputValue]);

  return (
    <Autocomplete
      options={data || []}
      disabled={props.disabled}
      value={props.value}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      ListboxProps={{
        sx: {maxHeight: 200},
      }}
      onChange={(_, value) => props.onChange(value)}
      onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
      renderInput={params => (
        <TextField {...params} fullWidth label={props.label} />
      )}
    />
  );
}

export default SearchAutocomplete;
