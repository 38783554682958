import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export interface ValidatedValue {
  value: string;
  state: 'error' | 'success' | '';
  success: boolean;
}

type Props = Omit<
  React.ComponentProps<typeof TextField>,
  'value' | 'onChange' | 'type'
> & {
  disableShowPassword?: boolean;
  value: ValidatedValue;
  onChange: (value: ValidatedValue) => void;
  type:
    | 'any'
    | 'email'
    | 'password'
    | 'text'
    | 'number'
    | 'date'
    | 'duration'
    | RegExp;
};

export const anyRegex = /.*/;
export const textRegex = /^.+$/s;
export const passwordRegex = /^.{5,}$/s;
export const numberRegex = /^\d+$/;
export const emailRegex = /^\S+@\S+\.\S+$/;
export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
export const durationRegex = /^\d+:[0-5][0-9]:[0-5][0-9]$/;

export const getRegex = (type: Props['type']): RegExp => {
  switch (type) {
    case 'any':
      return anyRegex;
    case 'email':
      return emailRegex;
    case 'password':
      return passwordRegex;
    case 'text':
      return textRegex;
    case 'number':
      return numberRegex;
    case 'date':
      return dateRegex;
    case 'duration':
      return durationRegex;
    default:
      return type;
  }
};

export const initialValue = (
  type: Props['type'],
  value: string = '',
): ValidatedValue => {
  const regex = getRegex(type);
  let state: ValidatedValue['state'] = '';

  if (value !== '') {
    state = regex.test(value) ? 'success' : 'error';
  }
  return {
    value,
    state,
    success: regex.test(value),
  };
};

function ValidatedInput(props: Props) {
  const {value, onChange, type, disableShowPassword, ...rest} = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const regex: RegExp = React.useMemo(() => getRegex(type), [type]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const isValid = regex.test(newValue);
    onChange({
      value: newValue,
      state: isValid ? 'success' : 'error',
      success: isValid,
    });
  };

  return (
    <TextField
      type={type === 'password' && !showPassword ? 'password' : 'text'}
      value={value?.value || ''}
      onChange={handleChange}
      error={value?.state === 'error'}
      InputProps={
        type === 'password' && !disableShowPassword
          ? {
              endAdornment: (
                <IconButton
                  size="small"
                  tabIndex={-1}
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? (
                    <VisibilityOff fontSize="small" />
                  ) : (
                    <Visibility fontSize="small" />
                  )}
                </IconButton>
              ),
            }
          : undefined
      }
      {...rest}
    />
  );
}

export default ValidatedInput;
