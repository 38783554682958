import React from 'react';

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

import CheckboxIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioIcon from '@mui/icons-material/RadioButtonUnchecked';
import EditIcon from '@mui/icons-material/Edit';

import {useFormBuilderContext} from 'functions/context';
import {formComponentNames, formTextComponentTypes} from 'functions/forms';

import type {SxProps, Theme} from '@mui/system';
import type {FormComponent} from 'types/forms';
import type {FormComponentType} from 'types/formBuilder';

interface Props {
  component: FormComponent;
  reorderMode?: boolean;
  sx?: SxProps<Theme>;
  associatedComponent?: FormComponent;
  onClick?: (component: FormComponent) => void;
}

function FormComponentView(props: Props) {
  const {component, associatedComponent, reorderMode, sx, onClick} = props;
  const context = useFormBuilderContext!();
  const {attributes, listeners, setNodeRef, transform} = useSortable({
    id: component.id,
  });
  const componentProps = {
    ...associatedComponent?.properties,
    ...component.properties,
  };
  const type = props.component.type as FormComponentType;
  const isRequired = props.associatedComponent
    ? associatedComponent?.isRequired
    : component.isRequired;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (context.editable && onClick) {
      onClick(component);
    }
  };

  let view: React.ReactNode = <div />;

  if (reorderMode) {
    return (
      <Box
        ref={setNodeRef}
        style={{
          transform: CSS.Transform.toString(transform),
          touchAction: 'none',
        }}
        {...attributes}
        {...listeners}>
        <Box sx={sx} onClick={handleClick}>
          <Box
            sx={{
              p: 1,
              backgroundColor: '#e5e5e5',
              borderRadius: 3,
            }}>
            <Typography fontSize={15}>
              {componentProps.label}{' '}
              {isRequired && type !== 'HEADING' && type !== 'CHECKBOX' && '*'}
              <Chip
                color="primary"
                sx={{ml: 1}}
                size="small"
                label={formComponentNames[component.type as FormComponentType]}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  if (type === 'HEADING') {
    view = <Typography fontSize={20}>{componentProps.label}</Typography>;
  } else if (formTextComponentTypes.includes(type)) {
    view = (
      <Box sx={{position: 'relative', cursor: 'pointer'}}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            height: '100%',
          }}
          onClick={handleClick}
        />
        <TextField
          label={componentProps.label}
          size="small"
          sx={{m: 0}}
          required={isRequired}
        />
      </Box>
    );
  } else if (type === 'RADIO') {
    view = (
      <Box sx={{cursor: 'pointer'}}>
        <Typography fontSize={15}>
          {componentProps.label} {isRequired && '*'}
        </Typography>
        {componentProps.options!.map((option: string, key: number) => (
          <Typography fontSize={15} key={key} sx={{mt: '3px'}}>
            <RadioIcon
              sx={{verticalAlign: 'middle', mr: 1, color: '#888888'}}
              fontSize="small"
            />
            {option}
          </Typography>
        ))}
      </Box>
    );
  } else if (type === 'CHECKBOX') {
    view = (
      <Typography fontSize={15} sx={{cursor: 'pointer'}}>
        <CheckboxIcon
          sx={{verticalAlign: 'middle', mr: 1, color: '#888888'}}
          fontSize="small"
        />
        {componentProps.label}
      </Typography>
    );
  } else if (type === 'SIGNATURE') {
    view = (
      <Box sx={{cursor: 'pointer'}}>
        <Typography fontSize={15}>
          {componentProps.label} {isRequired && '*'}
        </Typography>
        <Box
          sx={{
            border: '1px dashed #ccc',
            textAlign: 'center',
            mt: 1,
            borderRadius: 1,
            background: '#eee',
            py: 3,
          }}>
          <EditIcon />
          <Typography>Signature</Typography>
        </Box>
      </Box>
    );
  }

  if (!context.editable) {
    return <Box sx={sx}>{view}</Box>;
  }

  return (
    <Box onClick={handleClick} sx={sx}>
      <Tooltip
        title={
          reorderMode
            ? 'Drag component to reorder'
            : 'Click the component to edit it'
        }
        placement="bottom-start"
        arrow>
        {view!}
      </Tooltip>
    </Box>
  );
}

export default FormComponentView;
