import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Card from 'components/Card';
import DataTable from 'components/DataTable';
import FormDialog from 'pages/FormBuilder/Main/FormDialog';

import {apiCall} from 'functions/api';

import type {Form} from 'types/forms';
import type {ListResponse} from 'types/utils';

export default function FormsPage() {
  const [open, setOpen] = useState(false);
  const [forms, setForms] = useState<Array<Form>>();

  const navigate = useNavigate();

  const loadData = () => {
    setForms(undefined);
    apiCall('/admin/forms', 'GET', {type: 'TEMPLATE'}).then(
      (response: ListResponse<Form>) => {
        setForms(response.data);
      },
    );
  };

  const handleRowClick = (form: Form) => {
    navigate(`/admin/forms/${form.id}`);
  };

  const handleDialogClose = (reload: boolean) => {
    setOpen(false);
    if (reload) {
      loadData();
    }
  };

  useEffect(() => {
    if (forms !== undefined) return;
    apiCall('/admin/forms').then((response: ListResponse<Form>) => {
      setForms(response.data);
    });
  }, [forms]);

  return (
    <div>
      <Card
        title="Templates"
        button="Add"
        buttonOnClick={() => {
          setOpen(true);
        }}>
        <DataTable<Form>
          rows={forms}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Description',
              minWidth: 200,
              flex: 3,
              sortable: false,
            },
          ]}
          onRowClick={params => handleRowClick(params.row)}
        />
      </Card>
      <FormDialog
        open={open}
        portal="admin"
        type="TEMPLATE"
        onClose={handleDialogClose}
      />
    </div>
  );
}
