import {Portal} from 'types/auth';
import {uploadToGCS} from './uploader';

const apiLink = process.env.REACT_APP_API_URL;

export class APIError extends Error {
  code: string;

  constructor(code: string, message?: string) {
    super(message || `API Error: ${code}`);
    this.code = code;
  }
}

export const uploadFileTypes = {
  image: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  document: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
  },
  book: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
  },
  trainingDocument: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
  },
  formPage: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/pdf': ['.pdf'],
  },
  formPageImage: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
  signature: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
  },
};

const setCookie = (cname: string, cvalue: string, extime: number) => {
  const d = new Date();
  d.setTime(extime * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;SameSite=Strict;`;
};

const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0).toString() === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const generateBody = (params: URLSearchParams, prefix: string, object: any) => {
  if (typeof object === 'object') {
    if (object !== null && object !== undefined) {
      if (Array.isArray(object)) {
        if (object.length === 0) {
          params.append(prefix, '');
        } else {
          object.forEach((el, i) => {
            generateBody(params, `${prefix}[${i}]`, el);
          });
        }
      } else {
        const keys = Object.keys(object);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const value = object[key];
          if (value !== null && value !== undefined) {
            if (prefix) {
              generateBody(params, `${prefix}[${key}]`, value);
            } else {
              generateBody(params, key, value);
            }
          }
        }
      }
    }
  } else {
    params.append(prefix, object);
  }
};

export const setAuthCookie = (token: string) => {
  const parts = token.split('.');
  const payload = JSON.parse(atob(parts[1]));
  setCookie('auth-token', token, payload.exp);
};

export const clearAuthCookie = () => {
  setCookie('auth-token', '', 0);
};

export const apiCall = async (
  url: string,
  method: 'GET' | 'POST' | 'DELETE' = 'GET',
  payload: any = null,
  token: string | undefined = undefined,
) => {
  let finalUrl = apiLink + url;
  const headers: HeadersInit = {};
  let body: any;

  if (token === undefined) {
    headers.Authorization = `Bearer ${getCookie('auth-token')}`;
  } else if (token !== '') {
    headers.Authorization = `Bearer ${token}`;
  }

  if (method === 'POST') {
    headers['Content-Type'] = 'application/json';
  }

  if (payload) {
    const params = new URLSearchParams();
    generateBody(params, '', payload);
    if (method === 'GET') {
      finalUrl += `?${params.toString()}`;
    } else {
      body = JSON.stringify(payload);
    }
  }

  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      fetch(finalUrl, {
        method,
        headers,
        body,
        cache: 'no-cache',
      })
        .then(async res => res.json(), reject)
        .then(
          res => {
            if (res?.error) {
              reject(new APIError(res.error, res.message));
            } else {
              resolve(res);
            }
          },
          () => {
            resolve(null);
          },
        );
    }, 500);
  });
};

export const createAndUploadFile = async (
  file: File | Blob,
  portal: Portal,
  type: keyof typeof uploadFileTypes,
  progressFunc?: (progress: number) => void,
) => {
  if (progressFunc) {
    progressFunc(0);
  }
  let total = 0;
  let base = '';

  if (portal === 'admin') {
    base = 'admin';
  } else if (portal === 'location') {
    base = 'location';
  } else if (portal === 'client') {
    base = 'client';
  }

  return apiCall(`/${base}/storage/signedURL`, 'POST', {
    type,
    size: file.size,
    contentType: file.type,
  }).then(async response => {
    const signedURL = response.signed_url;
    const gcsFileName = response.file;

    return new Promise<string>((resolve, reject) => {
      uploadToGCS({
        signedURL,
        file,
        onComplete: () => {
          resolve(gcsFileName);
        },
        onError: reject,
        onProgress: progress => {
          total += progress;
          if (progressFunc) {
            progressFunc((100 * total) / Math.max(file.size, 1));
          }
        },
      });
    });
  });
};

export const createAndUploadImage = async (
  image: string,
  portal: Portal,
  type: keyof typeof uploadFileTypes,
) => {
  const blob = await fetch(image).then(r => r.blob());
  const results = await createAndUploadFile(blob, portal, type);
  return results;
};
