import {UserRole} from 'types/users';

export const userRoles: {
  label: string;
  role: UserRole;
  master?: boolean;
}[] = [
  {label: 'Manage Users', role: 'MANAGE_USERS', master: true},
  {label: 'Manage Clients', role: 'MANAGE_CLIENTS'},
  {label: 'Manage Forms', role: 'MANAGE_FORMS'},
  {label: 'Manage Trainings', role: 'MANAGE_TRAININGS'},
];
