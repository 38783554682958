import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import Box from '@mui/material/Box';

import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import FullPageLoader from 'components/FullPageLoader';

import routes from 'routes/admin';

import LoginPage from 'pages/Admin/Login';

import {AdminContext, type AdminContextType} from 'functions/context';
import {apiCall, clearAuthCookie} from 'functions/api';

import sidebarImage from 'assets/img/sidebar.jpg';
import {containerSx, drawerWidth, transition} from 'assets/jss/main';

import FormBuilderLayout from './FormBuilder';
import TrainingBuilderLayout from './Training';

function AdminLayout() {
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);

  return (
    <Box
      sx={{
        position: 'relative',
        top: '0',
        height: '100vh',
      }}>
      <Sidebar
        logoText="Sign App"
        routes={routes}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        image={sidebarImage}
        base="/admin"
      />
      <Box
        sx={theme => ({
          [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
          },
          overflow: 'auto',
          position: 'relative',
          float: 'right',
          maxHeight: '100%',
          width: '100%',
          overflowScrolling: 'touch',
          ...transition,
        })}>
        <Navbar
          base="/admin"
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          sx={{
            marginTop: '70px',
            padding: '30px 15px',
            minHeight: 'calc(100vh - 123px)',
          }}>
          <Box sx={containerSx}>
            <Routes>
              {routes.map((route, key) => (
                <Route
                  path={route.path}
                  element={<route.element />}
                  key={key}
                />
              ))}
              <Route
                path="*"
                element={<Navigate to={routes[0].path} replace />}
              />
            </Routes>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

function FormBuilderWrapper() {
  const formId = parseInt(useParams<'formId'>().formId!, 10);
  return <FormBuilderLayout portal="admin" formId={formId} />;
}

function TrainingBuilderWrapper() {
  const trainingId = parseInt(useParams<'trainingId'>().trainingId!, 10);
  const groupId = parseInt(useParams<'groupId'>().groupId!, 10);
  return (
    <TrainingBuilderLayout
      type="edit"
      navigationBase={`/admin/training-groups/${groupId}/training/${trainingId}`}
      portal="admin"
      trainingId={trainingId}
    />
  );
}

function AdminAuthenticatedLayout() {
  const [context, setContext] = React.useState<AdminContextType>({
    isLoggedIn: false,
    extra: {},
    updateContext: value => {
      setContext(value);
    },
  });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    apiCall('/admin', 'GET')
      .then(() => {
        setContext({...context, isLoggedIn: true});
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        clearAuthCookie();
        setContext({...context, isLoggedIn: false});
        setLoading(false);
      });
  }, []);

  return (
    <AdminContext.Provider value={context}>
      {loading && <FullPageLoader />}
      {!loading && context.isLoggedIn && (
        <Routes>
          <Route path="forms/:formId/*" element={<FormBuilderWrapper />} />
          <Route
            path="training-groups/:groupId/training/:trainingId/*"
            element={<TrainingBuilderWrapper />}
          />
          <Route path="*" element={<AdminLayout />} />
        </Routes>
      )}
      {!loading && !context.isLoggedIn && (
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Routes>
      )}
    </AdminContext.Provider>
  );
}

export default AdminAuthenticatedLayout;
