import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import {
  DataGrid,
  GridActionsCellItem,
  type GridRowModel,
  type GridColDef,
  type DataGridProps,
} from '@mui/x-data-grid';

interface DataTableProps<T extends GridRowModel>
  extends Omit<DataGridProps<T>, 'rows' | 'columns'> {
  pageSize?: number;
  rows?: any[];
  columns: (GridColDef<T> | false)[];
}

export default function DataTable<T extends GridRowModel = any>(
  props: DataTableProps<T>,
) {
  const {pageSize = 50, rows, columns, onRowClick, ...rest} = props;

  if (!rows?.length) {
    return (
      <Box display="flex" justifyContent="center" py={5}>
        {!rows ? <CircularProgress size={30} /> : 'No row found.'}
      </Box>
    );
  }

  const finalColumns = React.useMemo(() => {
    const arrowAction: GridColDef<T> = {
      field: 'actions',
      headerName: '',
      minWidth: 50,
      flex: 1,
      align: 'right',
      renderCell: () => (
        <GridActionsCellItem
          key={0}
          label="View"
          size="large"
          icon={<KeyboardArrowRight />}
          disableRipple
        />
      ),
    };
    const defaultProps: Partial<GridColDef<T>> = {
      sortable: false,
      filterable: false,
      minWidth: 200,
      flex: 1,
    };
    let list = [...columns, !!onRowClick && arrowAction];
    list = list.filter(Boolean);
    list = list.map(
      props =>
        props && {
          ...defaultProps,
          ...props,
        },
    );
    return list as GridColDef<T>[];
  }, [columns]);

  return (
    <DataGrid<T>
      {...rest}
      rowSelection={false}
      disableRowSelectionOnClick
      disableColumnMenu
      onRowClick={onRowClick}
      columns={finalColumns}
      rows={rows || []}
      pageSizeOptions={[pageSize]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize,
          },
        },
      }}
      sx={{
        border: 'none',
        '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: onRowClick ? 'pointer' : 'default',
          backgroundColor: onRowClick ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
      }}
    />
  );
}
