import type {SxProps, Theme} from '@mui/material/styles';
import {
  blackColor,
  boxShadow,
  drawerWidth,
  grayColor,
  hexToRgb,
  primaryBoxShadow,
  primaryColor,
  transition,
  whiteColor,
} from './main';

const drawerPaperSx: SxProps<Theme> = theme => ({
  border: 'none',
  position: 'fixed',
  top: '0',
  bottom: '0',
  left: '0',
  zIndex: '1',
  ...boxShadow,
  width: drawerWidth,
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    position: 'fixed',
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    width: drawerWidth,
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '100vh',
    left: '0',
    right: 'auto',
    zIndex: '1032',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    transform: `translate3d(${drawerWidth}px, 0, 0)`,
    ...transition,
  },
});
const sidebarWrapperSx = {
  position: 'relative',
  height: '100vh',
  overflow: 'auto',
  width: '260px',
  zIndex: '4',
  overflowScrolling: 'touch',
};
const backgroundSx = {
  position: 'absolute',
  zIndex: '1',
  height: '100%',
  width: '100%',
  display: 'block',
  top: '0',
  left: '0',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  '&:after': {
    position: 'absolute',
    zIndex: '3',
    width: '100%',
    height: '100%',
    content: '""',
    display: 'block',
    opacity: '.8',
  },
};
export const backgroundLightSx = {
  '&:after': {
    background: whiteColor,
  },
};
export const sidebarWrapperLightSx = {
  '& a,& a:hover, & .MuiListItemText-root': {
    color: blackColor,
  },
};
export const backgroundDarkSx = {
  '&:after': {
    background: `linear-gradient(${blackColor},${grayColor[8]})`,
  },
};
export const sidebarWrapperDarkSx = {
  '& a,& a:hover, & .MuiListItemText-root, & .MuiIconButton-root': {
    color: whiteColor,
  },
};

const brandSx = {
  position: 'relative',
  padding: '15px 15px',
  zIndex: '4',
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: '0',
    height: '1px',
    right: '15px',
    width: 'calc(100% - 30px)',
    backgroundColor: `rgba(${hexToRgb(grayColor[6])}, 0.3)`,
  },
  '& a': {
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: '30px',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
};

const listSx = {
  marginTop: '20px',
  paddingLeft: '0',
  paddingTop: '0',
  paddingBottom: '0',
  marginBottom: '20px',
  listStyle: 'none',
  position: 'unset',
};

const listItemSx = {
  width: 'auto',
  transition: 'all 300ms linear',
  margin: '10px 15px 0',
  borderRadius: '10px',
  position: 'relative',
  display: 'block',
  padding: '10px 15px',
  backgroundColor: 'transparent',
};

const listItemActiveSx = {
  backgroundColor: primaryColor[0],
  '& .MuiListItemText-root, & .MuiSvgIcon-root': {
    color: 'white',
  },
  ...primaryBoxShadow,
  '&:hover,&:focus': {
    backgroundColor: primaryColor[0],
    ...primaryBoxShadow,
  },
};

const listItemIconSx = {
  width: '24px',
  height: '30px',
  fontSize: '24px',
  lineHeight: '30px',
  float: 'left',
  marginRight: '15px',
  textAlign: 'center',
  verticalAlign: 'middle',
  opacity: 0.8,
};

const listItemTextSx = {
  margin: '0',
  lineHeight: '30px',
  fontSize: '14px',
  color: blackColor,
};

export {
  drawerPaperSx,
  sidebarWrapperSx,
  backgroundSx,
  brandSx,
  listSx,
  listItemSx,
  listItemActiveSx,
  listItemIconSx,
  listItemTextSx,
};
