import React from 'react';
import {useLocationContext} from 'functions/context';

import {ClientResponse} from 'types/clients';

import DocumentCard from '../Documents/DocumentCard';

function ClientDocumentsPage() {
  const context = useLocationContext();
  const client: ClientResponse = context.extra.client;

  return (
    <DocumentCard
      categoryPortal="CLIENT"
      portal="location"
      filterNone
      userId={client.id}
    />
  );
}

export default ClientDocumentsPage;
