import React from 'react';
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import CloudDone from '@mui/icons-material/CloudDone';
import ArrowBack from '@mui/icons-material/ArrowBack';
import MoreVert from '@mui/icons-material/MoreVert';

import Button from './Button';

interface Props {
  title: string;
  backLink?: string;
  noCover?: boolean;
  static?: boolean;
  menu?: Array<{label: string; onClick: () => void}>;
  button?: {
    label: string;
    disabled?: boolean;
    onClick: () => void;
  };
  autoSaveStatus?: 'saving' | 'saved' | 'error';
}

const navbarHeight = 60;

function FormNavbar(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (index: number) => {
    handleMenuClose();
    props.menu![index].onClick();
  };

  return (
    <div style={{height: props.noCover ? '0px' : `${navbarHeight}px`}}>
      <AppBar
        sx={{
          height: `${navbarHeight}px`,
          display: 'flex',
          paddingRight: '20px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 1,
        }}
        position={props.static ? 'static' : 'fixed'}>
        <Box>
          <Typography fontSize={17}>
            {props.backLink && (
              <Link to={props.backLink} replace>
                <IconButton sx={{color: 'white', mr: 1}}>
                  <ArrowBack />
                </IconButton>
              </Link>
            )}
            {props.title}
          </Typography>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          {props.autoSaveStatus && (
            <Box sx={{mt: props.autoSaveStatus === 'error' ? 0 : 1}}>
              {props.autoSaveStatus === 'saving' && (
                <Tooltip title="Saving form...">
                  <CircularProgress size={24} sx={{color: 'white'}} />
                </Tooltip>
              )}
              {props.autoSaveStatus === 'saved' && (
                <Tooltip title="Form is auto-saved">
                  <CloudDone sx={{color: 'white'}} />
                </Tooltip>
              )}
              {props.autoSaveStatus === 'error' && (
                <Typography sx={{color: '#FF4444'}}>Error in saving</Typography>
              )}
            </Box>
          )}
          {props.menu?.length && (
            <IconButton
              sx={{color: 'white', ml: 1}}
              onClick={handleMenuIconClick}>
              <MoreVert />
            </IconButton>
          )}
          {props.button && (
            <Button
              sx={{ml: 1}}
              color="secondary"
              onClick={props.button.onClick}
              disabled={props.button.disabled}>
              {props.button.label}
            </Button>
          )}
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}>
            {props.menu?.map((item, key) => (
              <MenuItem key={key} onClick={() => handleMenuItemClick(key)}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </AppBar>
    </div>
  );
}

export default FormNavbar;
export {type Props, navbarHeight};
