import React from 'react';
import TrainingDetail from 'pages/Training/TrainingDetail';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
  TrainingBuilderContext,
  TrainingBuilderContextType,
} from 'functions/context';
import {Portal} from 'types/auth';

export default function TrainingLayout({
  trainingId,
  portal,
  navigationBase,
  type,
}: {
  trainingId: number;
  portal: Portal;
  navigationBase: string;
  type: 'fill' | 'edit';
}) {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const from = queryParams.get('from') || `${navigationBase}/../../`;

  const contextValue = React.useMemo<TrainingBuilderContextType>(
    () => ({
      apiBase: `/${portal}/trainings/${trainingId}`,
      searchQuery:`?${queryParams.toString()}`,
      backUrl: from,
      navigationBase,
      trainingId,
      type,
      portal,
    }),
    [navigationBase, trainingId],
  );

  React.useEffect(() => {
    if (!trainingId) navigate(from);
  }, [trainingId]);

  return (
    <TrainingBuilderContext.Provider value={contextValue}>
      <TrainingDetail />
    </TrainingBuilderContext.Provider>
  );
}
