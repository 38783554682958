import HomeIcon from '@mui/icons-material/Home';
import BooksIcon from '@mui/icons-material/Book';
import UsersIcon from '@mui/icons-material/Person';
import ClientIcon from '@mui/icons-material/Person2';
import FormsIcon from '@mui/icons-material/Feed';
import DocumentsIcon from '@mui/icons-material/Article';
import MyFormsIcon from '@mui/icons-material/Dns';
import TrainingsIcon from '@mui/icons-material/AccountTree';
import TasksIcon from '@mui/icons-material/TaskAlt';
import MyTasksIcon from '@mui/icons-material/Task';
import MyTrainingsIcon from '@mui/icons-material/ArtTrack';
import MyDocumentsIcon from '@mui/icons-material/FolderCopy';
import SettingsIcon from '@mui/icons-material/Settings';

import ClientsPage from 'pages/Location/Clients';
import DashboardPage from 'pages/Location/Dashboard';
import SettingsPage from 'pages/Location/Settings';
import UsersPage from 'pages/Location/Users';
import BooksPage from 'pages/Location/Books';
import FormsPage from 'pages/Location/Forms/Manage';
import MyFormsPage from 'pages/Location/Forms/MyForms';
import TrainingsPage from 'pages/Location/Trainings/Manage';
import MyDocumentsPage from 'pages/Location/MyDocuments';
import MyTrainingsPage from 'pages/Location/Trainings/MyTrainings';
import DocumentCategoriesPage from 'pages/Location/Documents';
import ListTasksPage from 'pages/Location/Tasks/List';
import MyTasksPage from 'pages/Location/Tasks/MyTasks';

import type {Route} from 'types/routes';

const routes: Route[] = [
  {
    path: 'dashboard',
    icon: HomeIcon,
    name: 'Dashboard',
    element: DashboardPage,
  },
  {
    path: 'users/*',
    icon: UsersIcon,
    name: 'Users',
    element: UsersPage,
    roles: ['MANAGE_USERS'],
  },
  {
    path: 'clients/*',
    icon: ClientIcon,
    name: 'Clients',
    element: ClientsPage,
    roles: ['MANAGE_CLIENTS'],
  },
  {
    path: 'books',
    icon: BooksIcon,
    name: 'Books',
    element: BooksPage,
  },
  {
    path: 'forms/*',
    icon: FormsIcon,
    name: 'Forms',
    element: FormsPage,
    module: 'FORMS',
    roles: ['MANAGE_FORMS'],
  },
  {
    path: 'trainings/*',
    icon: TrainingsIcon,
    name: 'Trainings',
    element: TrainingsPage,
    module: 'TRAININGS',
    roles: ['MANAGE_TRAININGS'],
  },
  {
    path: 'documents/*',
    icon: DocumentsIcon,
    name: 'Documents',
    element: DocumentCategoriesPage,
    module: 'DOCUMENTS',
    roles: ['MANAGE_USERS', 'MANAGE_CLIENTS'],
  },
  {
    path: 'my-forms',
    icon: MyFormsIcon,
    name: 'My Forms',
    element: MyFormsPage,
    noAdmin: true,
    module: 'FORMS',
  },
  {
    path: 'my-trainings',
    icon: MyTrainingsIcon,
    name: 'My Trainings',
    element: MyTrainingsPage,
    noAdmin: true,
  },
  {
    path: 'my-documents',
    icon: MyDocumentsIcon,
    name: 'My Documents',
    element: MyDocumentsPage,
    noAdmin: true,
    module: 'DOCUMENTS',
  },
  {
    path: 'tasks',
    icon: TasksIcon,
    name: 'Tasks',
    element: ListTasksPage,
    module: 'TASKS',
    roles: ['MANAGE_USERS'],
  },
  {
    path: 'my-tasks',
    icon: MyTasksIcon,
    name: 'My Tasks',
    element: MyTasksPage,
    module: 'TASKS',
    noAdmin: true,
  },
  {
    path: 'settings',
    icon: SettingsIcon,
    name: 'Settings',
    element: SettingsPage,
  },
];

export default routes;
