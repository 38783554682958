import React from 'react';

import Dialog from 'components/Dialog';
import SearchAutocomplete from 'components/SearchAutocomplete';

import {APIError, apiCall} from 'functions/api';

import type {InsertTaskUserRequest, Task} from 'types/tasks';
import type {UserResponse} from 'types/users';

interface Props {
  open: boolean;
  onClose: (reload: boolean) => void;
  task: Task;
}

export default function UserDialog(props: Props) {
  const [user, setUser] = React.useState<UserResponse>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();

  const error = !user;

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = async () => {
    if (submitting || !user) {
      return;
    }
    setServerError(undefined);
    setSubmitting(true);
    try {
      const request: InsertTaskUserRequest = {
        taskId: props.task.id,
        userId: user.id,
      };
      await apiCall(`/location/taskUsers`, 'POST', request);
      setSubmitting(false);
      props.onClose(true);
    } catch (e: any) {
      setSubmitting(false);
      if (e instanceof APIError && e.code === 'id_used') {
        setServerError('This user is already assigned this task.');
      } else {
        setServerError(e?.message || 'An unknown error occurred.');
      }
    }
  };

  React.useEffect(() => {
    if (props.open) {
      setServerError(undefined);
      setUser(undefined);
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Assign User"
      errorMessage={serverError}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled: error || submitting,
        },
      ]}>
      <SearchAutocomplete
        portal="location"
        type="user"
        label="User"
        value={user}
        onChange={setUser}
      />
    </Dialog>
  );
}
