import React from 'react';

import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Select from 'components/Select';
import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {format} from 'date-fns';
import {APIError, apiCall} from 'functions/api';

import type {
  InsertTaskRequest,
  RepeatPeriod,
  Task,
  TaskStatus,
  TaskType,
  UpdateTaskRequest,
} from 'types/tasks';
import {weekDays} from 'functions/utils';

interface Props {
  open: boolean;
  onClose: (reload: boolean) => void;
  task?: Task;
}

export default function TaskDialog(props: Props) {
  const [name, setName] = React.useState(initialValue('text'));
  const [description, setDescription] = React.useState(initialValue('any'));
  const [type, setType] = React.useState<TaskType | ''>('');
  const [status, setStatus] = React.useState<TaskStatus | ''>('');
  const [repeatTime, setRepeatTime] = React.useState<string>('');
  const [repeatPeriod, setRepeatPeriod] = React.useState<RepeatPeriod | ''>('');
  const [dueTime, setDueTime] = React.useState(initialValue('number'));
  const [duePeriod, setDuePeriod] = React.useState<'hours' | 'days' | ''>('');
  const [hasDueTime, setHasDueTime] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();

  const monthDaysItems = React.useMemo(() => {
    const days = Array(31)
      .fill(0)
      .map((_, idx) => ({
        label: `Day ${idx + 1}`,
        value: `${idx + 1}`,
      }));
    return [...days, {label: 'Last Day', value: '-1'}];
  }, []);

  const dueDateString = React.useMemo(() => {
    if (
      !props.task ||
      props.task.type !== 'INSTANCE' ||
      !duePeriod ||
      !dueTime.success ||
      !hasDueTime
    ) {
      return '';
    }
    const step = duePeriod === 'days' ? 24 * 60 * 60 * 1000 : 60 * 60 * 1000;
    const dueDate = new Date(
      props.task.assignmentTime! + step * parseInt(dueTime.value, 10),
    );
    const formatted = format(dueDate, 'MMMM dd, HH:mm');
    return `Task is due at ${formatted}.`;
  }, [props.task, duePeriod, dueTime, hasDueTime]);

  const error = React.useMemo(() => {
    if (!name.success || !description.success || !type) {
      return true;
    }
    if (hasDueTime) {
      if (!duePeriod || !dueTime.success) {
        return true;
      }
      if (parseInt(dueTime.value, 10) <= 0) {
        return true;
      }
    }
    if (type === 'REPEATING') {
      if (!status || !repeatPeriod) {
        return true;
      }
      if (repeatPeriod !== 'DAILY' && !repeatTime) {
        return true;
      }
    }
    return false;
  }, [
    name,
    description,
    type,
    status,
    repeatTime,
    repeatPeriod,
    hasDueTime,
    dueTime,
    duePeriod,
  ]);

  const handleRepeatPeriodChange = (value: RepeatPeriod) => {
    setRepeatTime('');
    setRepeatPeriod(value);
  };

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = async () => {
    if (submitting) {
      return;
    }
    setServerError(undefined);
    setSubmitting(true);
    try {
      if (!props.task) {
        const request: InsertTaskRequest = {
          name: name.value,
          description: description.value,
          type: type as TaskType,
          checklist: [],
        };
        if (hasDueTime) {
          const step = (duePeriod === 'days' ? 24 : 1) * 60 * 60 * 1000;
          request.dueTime = parseInt(dueTime.value, 10) * step;
        }
        if (type === 'INSTANCE') {
          request.assignmentTime = Date.now();
          if (request.dueTime) {
            request.dueTime = request.assignmentTime + request.dueTime;
          }
        } else {
          request.status = status as TaskStatus;
          request.repeatPeriod = repeatPeriod as RepeatPeriod;
          request.repeatTime = parseInt(repeatTime || '0', 10);
        }
        await apiCall('/location/tasks', 'POST', request);
      } else {
        const request: UpdateTaskRequest = {
          name: name.value,
          description: description.value,
        };
        if (hasDueTime) {
          const step = (duePeriod === 'days' ? 24 : 1) * 60 * 60 * 1000;
          request.dueTime = parseInt(dueTime.value, 10) * step;
        }
        if (type === 'INSTANCE') {
          if (request.dueTime) {
            request.dueTime = props.task.assignmentTime! + request.dueTime;
          }
        } else {
          request.status = status as TaskStatus;
          request.repeatPeriod = repeatPeriod as RepeatPeriod;
          request.repeatTime = parseInt(repeatTime || '0', 10);
        }
        await apiCall(`/location/tasks/${props.task.id}`, 'POST', request);
      }
      setSubmitting(false);
      props.onClose(true);
    } catch (e: any) {
      setSubmitting(false);
      if (e instanceof APIError && e.message) {
        setServerError(e.message);
      } else {
        setServerError('An unknown error occurred.');
      }
    }
  };

  React.useEffect(() => {
    if (props.open) {
      const {task} = props;
      setServerError(undefined);
      setName(initialValue('text', task?.name));
      setDescription(initialValue('any', task?.description));
      setType(task?.type || '');
      setStatus(task?.status || '');
      setRepeatTime(`${task?.repeatTime || ''}`);
      setRepeatPeriod(task?.repeatPeriod || '');
      if (task?.dueTime) {
        setHasDueTime(true);
        let dueTime = task.dueTime;
        if (task.type === 'INSTANCE') {
          dueTime -= task.assignmentTime!;
        }
        dueTime = Math.round(dueTime / (60 * 60 * 1000));
        if (dueTime % 24 === 0) {
          setDueTime(initialValue('number', `${dueTime / 24}`));
          setDuePeriod('days');
        } else {
          setDueTime(initialValue('number', `${dueTime}`));
          setDuePeriod('hours');
        }
      } else {
        setHasDueTime(false);
        setDueTime(initialValue('number'));
        setDuePeriod('');
      }
    }
  }, [props.open, props.task]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.task ? 'Edit Task' : 'Add Task'}
      errorMessage={serverError}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled: error || submitting,
        },
      ]}>
      <ValidatedInput
        label="Name"
        type="text"
        value={name}
        onChange={setName}
      />
      <ValidatedInput
        label="Description"
        type="any"
        value={description}
        onChange={setDescription}
      />
      <Select
        value={type}
        onChange={setType}
        label="Type"
        disabled={!!props.task}
        items={[
          {label: 'One-Time', value: 'INSTANCE'},
          {label: 'Repeating', value: 'REPEATING'},
        ]}
      />
      {type === 'REPEATING' && (
        <>
          <Select
            value={status}
            onChange={setStatus}
            label="Status"
            items={[
              {label: 'Active', value: 'ACTIVE'},
              {label: 'Inactive', value: 'INACTIVE'},
            ]}
          />
          <Select
            value={repeatPeriod}
            onChange={handleRepeatPeriodChange}
            label="Repeat Period"
            items={[
              {label: 'Daily', value: 'DAILY'},
              {label: 'Weekly', value: 'WEEKLY'},
              {label: 'Monthly', value: 'MONTHLY'},
            ]}
          />
          {repeatPeriod === 'WEEKLY' && (
            <Select
              value={repeatTime}
              onChange={setRepeatTime}
              label="Day"
              items={weekDays.map((day, idx) => ({
                label: day,
                value: `${idx}`,
              }))}
            />
          )}
          {repeatPeriod === 'MONTHLY' && (
            <Select
              value={repeatTime}
              onChange={setRepeatTime}
              label="Day"
              items={monthDaysItems}
            />
          )}
        </>
      )}
      {hasDueTime && (
        <>
          <Select
            value={duePeriod}
            onChange={setDuePeriod}
            label="Due Period"
            items={[
              {label: 'Hours', value: 'hours'},
              {label: 'Days', value: 'days'},
            ]}
          />
          <ValidatedInput
            label="Due Time"
            type="number"
            value={dueTime}
            onChange={setDueTime}
          />
        </>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={!hasDueTime}
            onChange={e => setHasDueTime(!e.target.checked)}
          />
        }
        label="No due time for the task"
      />
      {type === 'REPEATING' && (
        <Typography fontSize="0.9em" mt={1}>
          The task will be reassigned at the midnight of the repeat days.
        </Typography>
      )}
      {dueDateString && (
        <Typography fontSize="0.9em" mt={1}>
          {dueDateString}
        </Typography>
      )}
    </Dialog>
  );
}
