import React from 'react';

import {GridActionsCellItem} from '@mui/x-data-grid';
import ViewIcon from '@mui/icons-material/KeyboardArrowRight';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import type {Book} from 'types/books';
import type {ListResponse} from 'types/utils';

function BooksPage() {
  const [books, setBooks] = React.useState<Book[]>();

  const loadData = () => {
    setBooks(undefined);
    apiCall('/location/books').then((response: ListResponse<Book>) => {
      setBooks(response.data);
    });
  };

  const handleBookOpen = (book: Book) => {
    window.open(
      process.env.REACT_APP_GOOGLE_STORAGE_LINK! + book.file,
      '_blank',
    );
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card
        title="Books"
        loading={books === undefined}>
        <DataTable
          rows={books}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Access',
              minWidth: 400,
              flex: 2,
              sortable: false,
            },
            {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 2,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={0}
                  label="View"
                  icon={<ViewIcon />}
                  onClick={() => handleBookOpen(params.row)}
                />,
              ],
            },
          ]}
        />
      </Card>
    </div>
  );
}

export default BooksPage;
