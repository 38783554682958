import React, {useState} from 'react';

import Card from 'components/Card';
import Details from 'components/Details';
import ChangePasswordDialog from 'components/ChangePasswordDialog';

import {useLocationContext} from 'functions/context';
import {userRoles} from 'functions/users';

import UserDialog from './Users/Dialog';

export default function SettingsPage() {
  const {
    location,
    authUser: {user, isAdmin} = {},
    reloadUser,
  } = useLocationContext();
  const [changePassword, setChangePassword] = useState(false);
  const [edit, setEdit] = useState(false);
  if (isAdmin) {
    return (
      <div>
        <Card
          title="Location Details"
          padding
          menu={[
            {label: 'Change Password', onClick: () => setChangePassword(true)},
          ]}>
          <Details label="Id">{location.locationId}</Details>
          <Details label="Name">{location.name}</Details>
          <Details label="Email">{location.email}</Details>
          <Details label="Modules" items={location.modules} />
        </Card>
        <ChangePasswordDialog
          open={changePassword}
          onClose={() => setChangePassword(false)}
          apiLink="/location/location"
        />
      </div>
    );
  }
  return (
    <div>
      <Card
        title="My Profile"
        padding
        menu={[
          {label: 'Change Password', onClick: () => setChangePassword(true)},
          ...(user?.roles.includes('MANAGE_USERS')
            ? [{label: 'Edit', onClick: () => setEdit(true)}]
            : []),
        ]}>
        <Details label="Name">
          {user?.firstName} {user?.lastName}
        </Details>
        <Details label="Email">{user?.email}</Details>
        <Details label="Date of Birth">{user?.dateOfBirth}</Details>
        <Details
          label="Roles"
          items={user?.roles.map(v => userRoles.find(i => i.role === v)!.label)}
        />
      </Card>
      <UserDialog
        user={user}
        open={edit}
        onClose={s => {
          if (s) reloadUser();
          setEdit(false);
        }}
      />
      <ChangePasswordDialog
        open={changePassword}
        onClose={() => setChangePassword(false)}
        apiLink={`/location/users/${user?.id}`}
      />
    </div>
  );
}
