import React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';

import ValidatedInput, {
  ValidatedValue,
  initialValue,
} from 'components/ValidatedInput';
import Dialog from 'components/Dialog';
import Select from 'components/Select';
import Button from 'components/Button';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RadioIcon from '@mui/icons-material/RadioButtonUnchecked';

import {useFormBuilderContext} from 'functions/context';
import {formComponentNames, formComponentTypes} from 'functions/forms';

import type {FormComponent} from 'types/forms';
import type {FormComponentType} from 'types/formBuilder';

interface Props {
  open: boolean;
  component?: FormComponent;
  allComponents: Array<FormComponent>;
  submitting?: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (component: FormComponent) => void;
}

const componentTypes = formComponentTypes.map(type => ({
  value: type,
  label: formComponentNames[type],
}));

export default function FormComponentDialog(props: Props) {
  const context = useFormBuilderContext();

  const [type, setType] = React.useState<FormComponentType | ''>('');
  const [associatedField, setAssociatedField] = React.useState<string>('-1');
  const [label, setLabel] = React.useState(initialValue('text'));
  const [required, setRequired] = React.useState<boolean>(false);
  const [radioOptions, setRadioOptions] = React.useState<ValidatedValue[]>([
    initialValue('text'),
    initialValue('text'),
  ]);
  const [roleId, setRoleId] = React.useState<string>('-1');

  const error = React.useMemo(() => {
    if (!type) {
      return true;
    }
    if (!label.success) {
      return true;
    }
    if (associatedField !== '-1') {
      return false;
    }
    if (type === 'RADIO') {
      if (radioOptions.some(o => !o.success) || radioOptions.length < 2) {
        return true;
      }
    }
    return false;
  }, [type, label, required, radioOptions, associatedField]);

  const roleMenuItems = React.useMemo(
    () => [
      {label: 'Anyone', value: '-1'},
      ...context.roles.map(role => ({
        label: role.name,
        value: role.id.toString(),
      })),
    ],
    [context.roles],
  );

  const associatedFieldOptions = React.useMemo(() => {
    const filtered = props.allComponents.filter(
      component => component.type === type && !component.associatedId,
    );
    return [
      {label: 'None', value: '-1'},
      ...filtered.map(component => ({
        label: component.properties.label,
        value: component.id.toString(),
      })),
    ] as {value: string; label: string}[];
  }, [props.allComponents, type]);

  const hasAssociatedField = React.useMemo(
    () =>
      props.component &&
      props.allComponents.some(
        component => component.associatedId === props.component?.id,
      ),
    [props.allComponents, type],
  );

  const handleSubmit = () => {
    if (error) {
      return;
    }
    const component: FormComponent = {
      id: -1,
      formId: -1,
      pageId: -1,
      type,
      associatedId: null,
      isRequired: type === 'CHECKBOX' || type === 'HEADING' ? false : required,
      roleId: null,
      properties: {label: label.value},
      value: null,
    };

    if (type !== 'HEADING' && associatedField !== '-1') {
      component.associatedId = parseInt(associatedField, 10);
    } else {
      if (type !== 'HEADING' && roleId !== '-1') {
        component.roleId = parseInt(roleId, 10);
      }
      if (type === 'RADIO') {
        component.properties.options = radioOptions.map(o => o.value);
      }
    }
    props.onSubmit(component);
  };

  const handleClose = () => {
    if (!props.submitting) {
      props.onClose();
    }
  };

  const handleDelete = () => {
    if (!props.submitting) {
      props.onDelete();
    }
  };

  const handleOptionChange = (index: number, value: ValidatedValue) => {
    setRadioOptions(radioOptions.map((o, i) => (i === index ? value : o)));
  };

  const handleOptionDelete = (index: number) => {
    setRadioOptions(radioOptions.filter((o, i) => i !== index));
  };

  const handleOptionAdd = () => {
    setRadioOptions([...radioOptions, initialValue('text')]);
  };

  React.useEffect(() => {
    if (props.open) {
      if (props.component) {
        setType(props.component.type as FormComponentType);
        setLabel(initialValue('text', props.component.properties.label));
        setRequired(props.component.isRequired);
        setRoleId(props.component.roleId?.toString() || '-1');
        if (props.component.type === 'RADIO') {
          if (!props.component.associatedId) {
            const options: string[] = props.component.properties.options!;
            setRadioOptions(options.map(o => initialValue('text', o)));
          }
        }
        if (props.component.type !== 'HEADING') {
          setAssociatedField(
            props.component.associatedId
              ? props.component.associatedId.toString()
              : '-1',
          );
        }
      } else {
        setLabel(initialValue('text'));
        setRadioOptions([initialValue('text'), initialValue('text')]);
        setAssociatedField('-1');
      }
    }
  }, [props.component, props.open]);

  React.useEffect(() => {
    if (!associatedFieldOptions.some(o => o.value === associatedField)) {
      setAssociatedField('-1');
    }
  }, [associatedFieldOptions, associatedField]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      title={props.component ? 'Edit Component' : 'Add Component'}
      onSubmit={handleSubmit}
      progress={props.submitting && {indeterminate: true}}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        !!props.component && {
          text: 'Delete',
          onClick: handleDelete,
          color: 'error',
          disabled: props.submitting,
        },
        {
          text: 'Submit',
          color: 'primary',
          submit: true,
          disabled: error || props.submitting,
        },
      ]}>
      <ValidatedInput
        value={label}
        onChange={setLabel}
        label="Label"
        type="text"
      />
      <Select
        value={type}
        items={componentTypes}
        label="Type"
        disabled={!!props.component}
        onChange={setType}
      />
      {type && type !== 'HEADING' && (
        <Select
          value={associatedField}
          items={associatedFieldOptions}
          label="Clone Of"
          onChange={setAssociatedField}
        />
      )}
      {type !== 'HEADING' && associatedField === '-1' && (
        <Select
          label="Fill By"
          value={roleId}
          sx={{mb: 1}}
          items={roleMenuItems}
          onChange={setRoleId}
        />
      )}
      {type === 'RADIO' && associatedField === '-1' && (
        <>
          <Typography fontSize={15} mt={1}>
            Options
          </Typography>
          {radioOptions.map((option, key) => (
            <Box
              sx={{
                py: 0.2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                gap: 0.5,
              }}
              key={key}>
              <ValidatedInput
                type="text"
                value={option}
                label={`Option ${key + 1}`}
                onChange={value => handleOptionChange(key, value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RadioIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: radioOptions.length > 2 && (
                    <IconButton
                      size="small"
                      onClick={() => handleOptionDelete(key)}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ),
                }}
              />
            </Box>
          ))}
          <Button
            size="small"
            variant="text"
            startIcon={<AddIcon />}
            sx={{
              p: 0.5,
              fontSize: 15,
              textTransform: 'capitalize',
              mt: 1,
            }}
            onClick={handleOptionAdd}>
            Add Option
          </Button>
        </>
      )}
      {type &&
        type !== 'CHECKBOX' &&
        type !== 'HEADING' &&
        associatedField === '-1' && (
          <Typography
            onClick={() => setRequired(!required)}
            sx={{
              cursor: 'pointer',
              mt: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span>Required</span>
            <Switch checked={required} />
          </Typography>
        )}
      {hasAssociatedField && (
        <Typography sx={{mt: 1}}>
          This field has a field associated with it.
        </Typography>
      )}
    </Dialog>
  );
}
