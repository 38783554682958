import React, {useEffect, useMemo, useState} from 'react';

import Typography from '@mui/material/Typography';
import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {apiCall} from 'functions/api';
import type {Training} from 'types/trainings';

export interface DetailDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (training: Training) => void;
  apiUrl: string;
  apiProps?: Record<string, any>;
  training?: Training;
}
export default function TrainingDetailDialog(props: DetailDialogProps) {
  const [name, setName] = useState(initialValue('text'));
  const [description, setDescription] = useState(initialValue('text'));
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (props.open) {
      setName(initialValue('text', props.training?.name || ''));
      setDescription(initialValue('text', props.training?.description || ''));
    }
  }, [props.open]);

  const disabled = useMemo(
    () => !name?.success || !description?.success || submitting,
    [name, description, submitting],
  );

  return (
    <Dialog
      title="Training Details"
      onClose={props.onClose}
      onSubmit={() => {
        if (disabled) return;
        setSubmitting(true);
        apiCall(props.apiUrl, 'POST', {
          name: name?.value,
          description: description?.value,
          ...(props.apiProps || {}),
        }).then(
          (v: Training) => {
            setSubmitting(false);
            props.onClose();
            props.onSubmit?.(v);
          },
          v => {
            setSubmitting(false);
            setError(v.message || 'An error occurred');
          },
        );
      }}
      open={props.open}
      actions={[
        {
          text: 'Cancel',
          onClick: props.onClose,
          color: 'secondary',
        },
        {
          text: 'Submit',
          submit: true,
          color: 'primary',
          disabled,
        },
      ]}>
      <ValidatedInput
        type="text"
        value={name}
        onChange={setName}
        label="Name"
      />

      <ValidatedInput
        type="any"
        value={description}
        onChange={setDescription}
        label="Description"
      />
      {error && (
        <Typography color="error" mt={1} fontSize={14}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}
