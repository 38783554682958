import React, {useEffect, useMemo, useState} from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import Combo from 'components/Combo';
import Dialog from 'components/Dialog';

import {APIError, apiCall} from 'functions/api';

import {
  InsertTrainingAssignmentRequest,
  TrainingAssignment,
} from 'types/trainings';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

const labels = {
  userForTraining: 'User',
  clientForTraining: 'Client',
  trainingForUser: 'Training',
  trainingForClient: 'Training',
};

const paths = {
  trainingForUser: '/location/trainings',
  trainingForClient: '/location/trainings',
  userForTraining: '/location/users',
  clientForTraining: '/location/clients',
};

interface Props {
  open: boolean;
  onClose: (submit?: boolean) => void;
  select:
    | 'trainingForUser'
    | 'trainingForClient'
    | 'clientForTraining'
    | 'userForTraining';
  entityId: number;
  assignment?: TrainingAssignment;
}

type Option = {value: number; label: string};
export default function AddTrainingAssignment(props: Props) {
  const [options, setOptions] = useState<Option[]>();
  const [selected, setSelected] = useState<number>();
  const [deadline, setDeadline] = useState<Date | null>();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();

  const selectProperty = useMemo(
    () => (props.select.includes('trainingFor') ? 'trainingId' : 'userId'),
    [props.select],
  );
  useEffect(() => {
    setSelected(props.assignment?.[selectProperty]);
    setDeadline(
      props.assignment?.deadline
        ? new Date(props.assignment.deadline)
        : undefined,
    );
  }, [props.open, props.assignment, selectProperty]);

  useEffect(() => {
    apiCall(paths[props.select], 'GET').then(({data}) => {
      setOptions(
        data.map((item: any) => ({
          value: item.id,
          label: item.firstName
            ? `${item.firstName} ${item.lastName}`
            : item.name,
        })),
      );
    });
  }, [props.select]);
  return (
    <Dialog
      title={props.assignment ? 'Edit Assignment' : 'Assign Training'}
      open={props.open}
      onClose={props.onClose}
      onSubmit={() => {
        setSubmitting(true);
        if (!deadline || !selected) return;
        const request: Partial<InsertTrainingAssignmentRequest> = {
          deadline: deadline.getTime(),
        };
        if (!props.assignment) {
          request[selectProperty] = selected;
          request.portal = props.select.toLowerCase().includes('client')
            ? 'CLIENT'
            : 'LOCATION';
          request[selectProperty === 'trainingId' ? 'userId' : 'trainingId'] =
            props.entityId;
        }
        apiCall(
          `/location/trainingAssignments/${props.assignment?.id || ''}`,
          'POST',
          request,
        ).then(
          () => {
            setSubmitting(false);
            props.onClose(true);
          },
          e => {
            setSubmitting(false);
            if (e instanceof APIError && e.code === 'id_used') {
              setError('Already Added.');
            } else {
              setError('An unknown error occurred.');
            }
          },
        );
      }}
      actions={[
        {
          text: 'Cancel',
          color: 'secondary',
          onClick: () => {
            props.onClose(false);
          },
        },
        {
          text: props.assignment ? 'Submit' : 'Assign',
          color: 'primary',
          disabled: !selected || !deadline || submitting,
          submit: true,
        },
      ]}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker value={deadline} onChange={setDeadline} label="Deadline" />
      </LocalizationProvider>
      <br />
      {!props.assignment &&
        (!options ? (
          <div style={{textAlign: 'center'}}>
            <CircularProgress />
          </div>
        ) : (
          <Combo
            value={selected}
            onChange={v => {
              if (v) {
                setSelected(v as number);
              }
            }}
            label={labels[props.select]}
            items={options}
          />
        ))}
      {error && (
        <Typography color="error" mt={1} fontSize={14}>
          {error}
        </Typography>
      )}
    </Dialog>
  );
}
