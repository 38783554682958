import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SignatureDialog from 'components/SignatureDialog';
import {dateRegex, emailRegex} from 'components/ValidatedInput';

import SignatureIcon from '@mui/icons-material/Edit';

import {formTextComponentTypes} from 'functions/forms';
import {storageBase} from 'functions/uploader';

import {dangerColor} from 'assets/jss/main';

import type {SxProps, Theme} from '@mui/system';
import type {FormComponent, FormComponentValue} from 'types/forms';
import type {FormComponentType} from 'types/formBuilder';

interface Props {
  component: FormComponent;
  associatedComponent?: FormComponent;
  disabled?: boolean;
  viewOnly?: boolean;
  value?: FormComponentValue | null;
  onChange?: (value: FormComponentValue | null) => void;
  roleName?: string;
  sx?: SxProps<Theme>;
}

export function isValidValue(
  component: FormComponent,
  associatedComponent: FormComponent | undefined | null,
  value: FormComponentValue | null,
) {
  const type = component.type as FormComponentType;
  const textValue = value?.text || '';
  const isRequired = associatedComponent
    ? associatedComponent.isRequired
    : component.isRequired;
  if (type === 'CHECKBOX' || type === 'HEADING') {
    return true;
  }
  if (!isRequired && !value) {
    return true;
  }
  if (formTextComponentTypes.includes(type) && !textValue.length) {
    return !isRequired;
  }
  return (
    (type === 'DATE' && dateRegex.test(textValue)) ||
    (type === 'EMAIL' && emailRegex.test(textValue)) ||
    (type === 'TEXT' && !!textValue.length && textValue.length < 255) ||
    (type === 'LONG_TEXT' && !!textValue.length && textValue.length < 1000) ||
    (type === 'SIGNATURE' && value?.file) ||
    (type === 'RADIO' && value?.option !== undefined)
  );
}

function FormComponentView(props: Props) {
  const {
    component,
    associatedComponent,
    value,
    disabled,
    roleName,
    sx,
    viewOnly,
    onChange,
  } = props;
  const [signatureDialogOpen, setSignatureDialogOpen] = React.useState(false);

  const valid = React.useMemo(
    () =>
      viewOnly ||
      disabled ||
      isValidValue(component, associatedComponent, value || null),
    [component, disabled, associatedComponent, value],
  );

  const type = component.type as FormComponentType;
  const componentProps = {
    ...associatedComponent?.properties,
    ...component.properties,
  };
  const isRequired = props.associatedComponent
    ? associatedComponent?.isRequired
    : component.isRequired;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onChange) {
      onChange({text: event.target.value});
    }
  };

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onChange) {
      onChange({checked: e.target.checked});
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && onChange) {
      onChange({option: parseInt(e.target.value, 10)});
    }
  };

  const handleSignatureClick = () => {
    if (!disabled) {
      setSignatureDialogOpen(true);
    }
  };

  const handleSignatureDialogClose = (image?: string) => {
    setSignatureDialogOpen(false);
    if (image && onChange) {
      onChange({file: image});
    }
  };

  React.useEffect(() => {
    if (component.type === 'CHECKBOX' && !value && onChange) {
      onChange({checked: false});
    }
  }, [value, component]);

  let view: React.ReactNode = <div />;

  if (type === 'HEADING') {
    view = <Typography fontSize={20}>{componentProps.label}</Typography>;
  } else if (formTextComponentTypes.includes(type)) {
    view = (
      <TextField
        disabled={disabled}
        label={componentProps.label}
        value={value?.text || ''}
        error={!valid}
        onChange={handleInputChange}
        multiline={type === 'LONG_TEXT'}
        size="small"
        sx={{m: 0}}
        required={isRequired}
      />
    );
  } else if (type === 'RADIO') {
    view = (
      <FormControl disabled={disabled}>
        <FormLabel sx={{color: !valid ? dangerColor[0] : undefined}}>
          {componentProps.label} {isRequired && '*'}
        </FormLabel>
        <RadioGroup
          value={value?.option === undefined ? '' : value.option}
          onChange={handleRadioChange}>
          {componentProps.options?.map((option: string, key: number) => (
            <FormControlLabel
              key={key}
              value={key}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  } else if (type === 'CHECKBOX') {
    view = (
      <FormControlLabel
        sx={{color: !valid ? dangerColor[0] : undefined}}
        disabled={disabled}
        control={
          <Checkbox
            checked={value?.checked || false}
            onChange={handleCheckboxClick}
            inputProps={{'aria-label': 'controlled'}}
          />
        }
        label={componentProps.label}
      />
    );
  } else if (type === 'SIGNATURE') {
    let file = value?.file;
    if (file && !file.startsWith('data')) {
      file = storageBase + file;
    }
    view = (
      <Box
        sx={{cursor: disabled ? 'default' : 'pointer'}}
        onClick={handleSignatureClick}>
        <Typography
          fontSize={15}
          sx={{color: !valid ? dangerColor[0] : undefined}}>
          {componentProps.label} {isRequired && '*'}
        </Typography>
        <Box
          sx={{
            border: '1px dashed #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 1,
            py: 3,
            borderRadius: 1,
            background: '#eee',
            height: '90px',
            backgroundColor: disabled ? '#E5E5E5' : '#FFFFFF',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: file && `url(${file})`,
            backgroundSize: 'contain',
          }}>
          {!value?.file && (
            <>
              <SignatureIcon />
              Signature {isRequired && '*'}
            </>
          )}
        </Box>
      </Box>
    );
  }
  if (disabled) {
    view = <Tooltip title={roleName}>{view}</Tooltip>;
  }
  if (type === 'SIGNATURE') {
    view = (
      <>
        {view}
        <SignatureDialog
          open={signatureDialogOpen}
          onClose={handleSignatureDialogClose}
          ratio={9 / 16}
        />
      </>
    );
  }

  return <Box sx={sx}>{view}</Box>;
}

export default FormComponentView;
