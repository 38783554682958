import React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from 'components/Card';
import Button from 'components/Button';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';

import {apiCall, setAuthCookie} from 'functions/api';

function LoginPage() {
  const [email, setEmail] = React.useState(initialValue('email'));
  const [password, setPassword] = React.useState(initialValue('password'));
  const [error, setError] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');
    apiCall(
      '/admin/login',
      'POST',
      {email: email?.value, password: password?.value},
      '',
    )
      .then((response: {token: string}) => {
        setSubmitting(false);
        if (response.token) {
          setAuthCookie(response.token);
          window.location.reload();
        }
      })
      .catch(e => {
        console.error(e);
        setSubmitting(false);
        setError('Invalid email or password');
      });
  };

  return (
    <Container maxWidth="xs">
      <Box sx={{py: '150px', px: 1, mx: 'auto'}}>
        <form onSubmit={handleSubmit}>
          <Card sx={{p: 2, borderRadius: 3}}>
            <Typography
              variant="h5"
              textAlign="center"
              pt={1}
              pb={2}
              fontWeight={500}>
              Login
            </Typography>
            <ValidatedInput
              label="Email"
              value={email}
              onChange={setEmail}
              type="email"
              sx={{mb: 2}}
            />
            <ValidatedInput
              label="Password"
              value={password}
              onChange={setPassword}
              type="password"
            />
            <br />
            {error && (
              <span style={{color: 'red', fontSize: '0.75em'}}>{error}</span>
            )}
            <br />
            <Button
              fullWidth
              type="submit"
              loading={submitting}
              disabled={!email?.success || !password?.success}>
              Login
            </Button>
          </Card>
        </form>
      </Box>
    </Container>
  );
}

export default LoginPage;
