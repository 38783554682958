import React from 'react';

import ValidatedInput, {initialValue} from 'components/ValidatedInput';
import Dialog from 'components/Dialog';
import Select from 'components/Select';

import {apiCall} from 'functions/api';
import {useFormBuilderContext} from 'functions/context';

import type {
  FormRolePortal,
  FormRole,
  InsertRoleRequest,
  FormRoleExpanded,
} from 'types/forms';
import type {Portal} from 'types/auth';
import SearchAutocomplete from 'components/SearchAutocomplete';
import {UserResponse} from 'types/users';
import {ClientResponse} from 'types/clients';

export interface RoleDialogProps {
  open: boolean;
  role?: FormRoleExpanded;
  portal?: Portal;
  onClose: (reload: boolean, role?: FormRole) => void;
}

function RoleDialog(props: RoleDialogProps) {
  const context = useFormBuilderContext();
  const [name, setName] = React.useState(initialValue('text'));
  const [type, setType] = React.useState<FormRolePortal | ''>('');
  const [email, setEmail] = React.useState(initialValue('email'));
  const [user, setUser] = React.useState<UserResponse>();
  const [client, setClient] = React.useState<ClientResponse>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();

  const acceptValues = React.useMemo(
    () => context.form.type === 'INSTANCE',
    [context.form],
  );
  const error = React.useMemo(() => {
    if (!name.success || !type) {
      return true;
    }
    if (acceptValues) {
      return (
        (type === 'LOCATION' && !user) ||
        (type === 'CLIENT' && !client) ||
        (type === 'EXTERNAL_USER' && !email.success)
      );
    }
    return false;
  }, [name, type, acceptValues, user, client, email]);
  const portal = props.portal || context.portal;

  const handleClose = () => {
    if (submitting) return;
    props.onClose(false);
  };

  const handleSubmit = () => {
    if (submitting || !type) return;
    setSubmitting(true);
    setServerError(undefined);

    const request: Partial<InsertRoleRequest> = {
      name: name.value,
      portal: type,
      formId: context.form.id,
    };

    let apiURL = context.rolesAPIBase;
    if (props.role) {
      apiURL += `/${props.role.id}`;
      delete request.portal;
      delete request.formId;
    }
    if (acceptValues) {
      if (type === 'EXTERNAL_USER') {
        request.email = email.value;
      } else {
        request.userId = type === 'CLIENT' ? client?.id : user?.id;
      }
    }

    apiCall(apiURL, 'POST', request).then(
      (response: FormRole) => {
        setSubmitting(false);
        props.onClose(true, response);
      },
      (errorResponse: any) => {
        setSubmitting(false);
        setServerError(errorResponse.message || 'An error occurred.');
      },
    );
  };

  React.useEffect(() => {
    if (props.open) {
      setName(initialValue('text', props.role?.name || ''));
      setType(props.role?.portal || '');
      setEmail(initialValue('email', props.role?.email || ''));
      setUser(props.role?.user);
      setClient(props.role?.client);
    }
  }, [props.open]);

  return (
    <Dialog
      title={props.role ? 'Edit Role' : 'Add Role'}
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={props.open}
      errorMessage={serverError}
      actions={[
        {
          text: 'Cancel',
          onClick: handleClose,
          color: 'secondary',
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          submit: true,
          color: 'primary',
          disabled: error || submitting,
        },
      ]}>
      <ValidatedInput
        type="text"
        value={name}
        onChange={setName}
        label="Name"
      />
      <Select
        label="Role Type"
        value={type}
        disabled={!!props.role}
        onChange={setType}
        items={[
          {label: 'User', value: 'LOCATION'},
          {label: 'Client', value: 'CLIENT'},
          {label: 'External User', value: 'EXTERNAL_USER'},
        ]}
      />
      {acceptValues && type === 'EXTERNAL_USER' && (
        <ValidatedInput
          type="email"
          label="Email Address"
          value={email}
          onChange={setEmail}
        />
      )}
      {acceptValues && type === 'LOCATION' && (
        <SearchAutocomplete
          portal={portal}
          type="user"
          label="User"
          value={user}
          onChange={setUser}
        />
      )}
      {acceptValues && type === 'CLIENT' && (
        <SearchAutocomplete
          portal={portal}
          type="client"
          label="Client"
          value={client}
          onChange={setClient}
        />
      )}
    </Dialog>
  );
}

export default RoleDialog;
