import React, {useMemo} from 'react';

import {useSearchParams} from 'react-router-dom';

import {GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import Card from 'components/Card';
import DataTable from 'components/DataTable';
import ConfirmDialog from 'components/ConfirmDialog';

import {apiCall} from 'functions/api';
import type {
  DocumentCategory,
  DocumentCategoryPortal,
  ListDocumentCategoriesRequest,
} from 'types/documents';
import type {ListResponse} from 'types/utils';

import DocumentCategoryDialog, {accessTypes} from './Dialog';

export const documentPortals: DocumentCategoryPortal[] = ['CLIENT', 'LOCATION'];
const tabNames: {[k in DocumentCategoryPortal]: string} = {
  CLIENT: 'Clients',
  LOCATION: 'Users',
};

function DocumentCategoriesPage() {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [categories, setCategories] = React.useState<DocumentCategory[]>();
  const [selectedCategory, setSelectedCategory] =
    React.useState<DocumentCategory>();

  const [queryParams, setQueryParams] = useSearchParams();

  const documentPortal: DocumentCategoryPortal | undefined = useMemo(
    () => documentPortals.find(v => v === queryParams.get('for')),
    [queryParams],
  );

  const setDocumentPortal = (type: DocumentCategoryPortal) => {
    const params = new URLSearchParams();
    params.set('for', type);
    setQueryParams(params);
  };

  const loadData = () => {
    if (!documentPortal) return undefined;
    setCategories(undefined);
    const props: ListDocumentCategoriesRequest = {portal: documentPortal};
    apiCall('/location/documentCategories', 'GET', props).then(
      (response: ListResponse<DocumentCategory>) => {
        setCategories(response.data);
      },
    );
    return true;
  };

  const handleAddClick = () => {
    setSelectedCategory(undefined);
    setEditDialogOpen(true);
  };

  const handleEdit = (category: DocumentCategory) => {
    setSelectedCategory(category);
    setEditDialogOpen(true);
  };

  const handleDelete = (category: DocumentCategory) => {
    setSelectedCategory(category);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = (refresh?: boolean) => {
    setEditDialogOpen(false);
    setDeleteDialogOpen(false);
    if (refresh) {
      loadData();
    }
  };

  React.useEffect(() => {
    if (!documentPortal) setDocumentPortal(documentPortals[0]);
  }, []);

  React.useEffect(() => {
    loadData();
  }, [documentPortal]);

  if (!documentPortal) return null;

  return (
    <div>
      <Box sx={{borderBottom: 1, mb: 3, borderColor: 'divider'}}>
        <Tabs
          value={documentPortal}
          onChange={(_, value) => setDocumentPortal(value)}>
          {documentPortals.map(v => (
            <Tab key={v} label={tabNames[v]} value={v} />
          ))}
        </Tabs>
      </Box>
      <Card
        title="Document Categories"
        button="Add"
        buttonOnClick={handleAddClick}
        empty={categories?.length === 0}
        loading={categories === undefined}>
        <DataTable
          rows={categories}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 1,
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Description',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'access',
              headerName: 'Access',
              minWidth: 200,
              flex: 2,
              sortable: false,
              renderCell: ({row}) => (
                <Typography fontWeight={500}>
                  {accessTypes.find(v => v.value === row.access)?.label}
                </Typography>
              ),
            },
            {
              field: 'actions',
              type: 'actions',
              minWidth: 100,
              flex: 2,
              align: 'right',
              getActions: params => [
                <GridActionsCellItem
                  key={0}
                  label="Edit"
                  showInMenu
                  icon={<EditIcon />}
                  onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                  key={0}
                  label="Remove"
                  showInMenu
                  icon={<DeleteIcon />}
                  onClick={() => handleDelete(params.row)}
                />,
              ],
            },
          ]}
        />
      </Card>
      <DocumentCategoryDialog
        documentPortal={documentPortal}
        open={editDialogOpen}
        onClose={handleDialogClose}
        category={selectedCategory}
      />
      <ConfirmDialog
        title="Remove Category"
        text="Are you sure you want to remove this category?"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        apiLink={`/location/documentCategories/${selectedCategory?.id}`}
      />
    </div>
  );
}

export default DocumentCategoriesPage;
