import React, {useMemo, useState} from 'react';

import DragHandle from '@mui/icons-material/DragHandle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {TrainingContent} from 'types/trainings';

import Card from 'components/Card';
import Dialog from 'components/Dialog';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {apiCall} from 'functions/api';
import {useTrainingBuilderContext} from 'functions/context';

export type TrainingContentsReorderDialogProps = {
  open: boolean;
  onClose: (contents?: TrainingContent[]) => void;
  contents: TrainingContent[];
  order: number[];
};

function Item(props: {content: TrainingContent}) {
  const {attributes, listeners, setNodeRef, transform} = useSortable({
    id: props.content.id,
  });
  return (
    <div
      style={{transform: CSS.Transform.toString(transform)}}
      ref={setNodeRef}
      {...attributes}
      {...listeners}>
      <Card
        key={props.content.id}
        sx={{
          display: 'flex',
          gap: 1,
          padding: 2,
          boxShadow: 'none',
          cursor: 'pointer',
        }}>
        <DragHandle />
        <Typography>{props.content.name}</Typography>
      </Card>
    </div>
  );
}

export default function TrainingContentsReorderDialog(
  props: TrainingContentsReorderDialogProps,
) {
  const [orderedContents, setOrderedContents] = useState<TrainingContent[]>(
    props.contents,
  );
  const [saving, setSaving] = useState(false);

  const {apiBase} = useTrainingBuilderContext();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const orderChanged = useMemo(
    () =>
      !props.contents.every(({id}, index) => id === orderedContents[index].id),
    [props.order, orderedContents],
  );

  React.useEffect(() => {
    setOrderedContents(props.contents);
  }, [props.contents]);

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (over && active.id !== over.id) {
      const oldIndex = orderedContents.findIndex(({id}) => id === active.id);
      const newIndex = orderedContents.findIndex(({id}) => id === over.id);
      const newOrder = arrayMove(orderedContents, oldIndex, newIndex);
      setOrderedContents(newOrder);
    }
  };

  const handleSave = () => {
    setSaving(true);
    apiCall(apiBase, 'POST', {order: orderedContents.map(v => v.id)}).then(
      () => {
        setSaving(false);
        props.onClose(orderedContents);
      },
    );
  };

  return (
    <Dialog
      title="Reorder Contents"
      onClose={() => props.onClose()}
      open={props.open}
      actions={[
        {
          text: 'Cancel',
          onClick: () => props.onClose(),
          color: 'secondary',
        },
        {
          text: 'Save',
          onClick: handleSave,
          color: 'primary',
          disabled: !orderChanged || saving,
        },
      ]}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}>
          <SortableContext
            items={orderedContents}
            strategy={verticalListSortingStrategy}>
            {orderedContents.map(content => (
              <Item content={content} key={content.id} />
            ))}
          </SortableContext>
        </DndContext>
      </Box>
    </Dialog>
  );
}
