import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Article from '@mui/icons-material/Article';
import MoreVert from '@mui/icons-material/MoreVert';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Quiz from '@mui/icons-material/Quiz';
import Subject from '@mui/icons-material/Subject';
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import Card from 'components/Card';
import ConfirmDialog from 'components/ConfirmDialog';
import type {Training, TrainingContent} from 'types/trainings';
import {apiCall} from 'functions/api';
import {useTrainingBuilderContext} from 'functions/context';

import FullPageLoader from 'components/FullPageLoader';

import {ListResponse} from 'types/utils';
import TrainingDetailDialog from './DetailDialog';
import ContentDetailDialog from './ContentDialog';
import TrainingItem from './TrainingItem';
import TrainingContentsReorderDialog from './ReorderDialog';

export const contentType: Array<{
  value: TrainingContent['type'];
  label: string;
  icon: React.ReactNode;
}> = [
  {value: 'DOCUMENT', label: 'Document', icon: <Subject />},
  {value: 'VIDEO', label: 'Youtube Video', icon: <PlayCircleFilled />},
  {value: 'QUIZ', label: 'Quiz', icon: <Quiz />},
];

export default function TrainingDetail() {
  const navigate = useNavigate();

  const {apiBase, navigationBase, backUrl, searchQuery, type, portal} =
    useTrainingBuilderContext();

  const location = useLocation();

  const contentId = useMemo(() => {
    const parsed = location.pathname.split('/');
    return +parsed[parsed.findIndex(v => v === 'content') + 1];
  }, [location]);

  const [training, setTraining] = useState<Training>();

  const [removeTraining, setRemoveTraining] = useState(false);

  const [editTraining, setEditTraining] = useState(false);

  const [topMenu, setTopMenu] = useState<HTMLElement>();

  const [contents, setContents] = useState<TrainingContent[]>();

  const [addMenu, setAddMenu] = useState<HTMLElement>();

  const [content, setContent] = useState<TrainingContent['type']>();

  const [expanded, setExpanded] = React.useState(false);

  const [optionsMenu, setOptionsMenu] = React.useState<HTMLElement>();

  const [reorder, setReorder] = React.useState(false);

  const editable = useMemo(
    () => type === 'edit' && (portal !== 'location' || !training?.groupId),
    [portal, training?.groupId],
  );

  const defaultNavigation = useCallback(
    (c: TrainingContent[] = contents!) =>
      `content/${c?.[0]?.id || 0}${searchQuery}`,
    [contents],
  );

  const onError = useCallback(() => {
    navigate(backUrl);
  }, []);

  const handleEditTraining = () => {
    setTopMenu(undefined);
    setEditTraining(true);
  };

  const handleRemoveTraining = () => {
    setTopMenu(undefined);
    setRemoveTraining(true);
  };

  const handleAddContent = (type: TrainingContent['type']) => {
    setAddMenu(undefined);
    setContent(type);
  };

  const handleReorder = () => {
    setOptionsMenu(undefined);
    setReorder(true);
  };

  const handleReorderClose = (_contents?: TrainingContent[]) => {
    if (_contents) setContents(_contents);
    setReorder(false);
  };

  const loadData = useCallback(() => {
    setContents(undefined);
    apiCall(apiBase).then((response: Training) => {
      setTraining(response);
      apiCall(`${apiBase}/contents`).then(
        ({data}: ListResponse<TrainingContent>) => {
          const contents = data;
          const order = [...response.order];
          contents.forEach(contents => !order.includes(contents.id));
          const ordered: Array<TrainingContent> = order.map(
            id => contents.find(contents => contents.id === id)!,
          );
          setContents(ordered);
        },
        onError,
      );
    }, onError);
  }, []);

  const handleUpdateContent = (content: TrainingContent) => {
    setContents(contents?.map(v => (v.id === content.id ? content : v)));
  };

  const handleRemoveContent = (id: number) => {
    const newContents = contents?.filter(v => v.id !== id);
    setContents(newContents);
    navigate(defaultNavigation(newContents));
  };

  const handleSubmit = (_content?: TrainingContent) => {
    setContent(undefined);
    if (_content) {
      setContents([...(contents || []), _content]);
      navigate(`${navigationBase}/content/${_content.id}${searchQuery}`);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  const list = (
    <>
      <Divider />
      <List>
        {contents?.map(content => (
          <Link to={`${navigationBase}/content/${content.id}${searchQuery}`} key={content.id}>
            <ListItemButton
              key={content.id}
              sx={{color: theme => theme.palette.text.primary}}
              selected={content.id === contentId}>
              <span style={{opacity: 0.7, marginRight: '10px'}}>
                {contentType.find(v => v.value === content.type)?.icon}
              </span>
              <Typography component="span" variant="subtitle2">
                {content.name}
              </Typography>
            </ListItemButton>
          </Link>
        ))}
      </List>
      {editable && (
        <Box sx={{m: 1, display: 'flex'}}>
          <ButtonBase
            sx={{
              p: 1,
              borderRadius: 2,
              fontSize: 15,
              width: '100%',
              backgroundColor: '#e3e3e3',
            }}
            onClick={e => setAddMenu(e.currentTarget)}>
            <Add />
            &nbsp;Add Content
          </ButtonBase>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <IconButton
              size="small"
              onClick={e => setOptionsMenu(e.currentTarget)}>
              <MoreVert fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
  if (!training || !contents) {
    return <FullPageLoader />;
  }
  return (
    <Box sx={{p: 2}}>
      <Box sx={{pb: 1}} display="flex" justifyContent="space-between">
        <Box>
          <Link to={backUrl}>
            <IconButton sx={{p: '8px'}}>
              <ArrowBack />
            </IconButton>
          </Link>
          <Typography
            component="span"
            sx={{opacity: 0.8, ml: 1, verticalAlign: 'middle'}}
            fontSize={18}
            fontWeight="400">
            Training Builder
          </Typography>
        </Box>
        {editable && (
          <Box>
            <IconButton onClick={e => setTopMenu(e.currentTarget)}>
              <MoreVert />
            </IconButton>
          </Box>
        )}
      </Box>
      <Grid container gap={2} maxWidth="lg" mx="auto" justifyContent="center">
        <Grid item xs={12} sm={12} md={3}>
          <Card>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={2}>
              <Box display="flex" gap={1} alignItems="center">
                <Article sx={{opacity: 0.8, verticalAlign: 'bottom'}} />
                <div>
                  <Typography fontWeight={500} fontSize={14}>
                    {training.name}
                  </Typography>
                  <Typography variant="body2" fontSize={11}>
                    {training.description}
                  </Typography>
                </div>
              </Box>
              <Hidden mdUp implementation="css">
                <IconButton
                  sx={{p: '0'}}
                  onClick={() => {
                    setExpanded(!expanded);
                  }}>
                  <KeyboardArrowDown
                    sx={[expanded && {transform: 'rotate(180deg)'}]}
                  />
                </IconButton>
              </Hidden>
            </Box>
            <Hidden implementation="css" mdUp>
              <Collapse in={expanded}>{list}</Collapse>
            </Hidden>
            <Hidden implementation="css" mdDown>
              {list}
            </Hidden>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={8} gap={2}>
          <Routes>
            <Route
              path="content/:contentId"
              element={
                <TrainingItem
                  onUpdate={handleUpdateContent}
                  onRemove={handleRemoveContent}
                  editable={editable}
                  contents={contents}
                />
              }
            />
            <Route
              path="*"
              element={<Navigate to={defaultNavigation()} replace />}
            />
          </Routes>
        </Grid>
      </Grid>
      <Menu
        open={!!topMenu}
        onClose={() => setTopMenu(undefined)}
        anchorEl={topMenu}>
        <MenuItem onClick={handleEditTraining}>Edit Details</MenuItem>
        <MenuItem onClick={handleRemoveTraining}>Remove Training</MenuItem>
      </Menu>
      <Menu
        open={!!optionsMenu}
        onClose={() => setOptionsMenu(undefined)}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorEl={optionsMenu}>
        <MenuItem onClick={handleReorder}>Reorder</MenuItem>
      </Menu>

      <Menu
        anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
        transformOrigin={{horizontal: 'center', vertical: 'top'}}
        open={!!addMenu}
        onClose={() => setAddMenu(undefined)}
        anchorEl={addMenu}>
        {contentType.map((v, k) => (
          <MenuItem onClick={() => handleAddContent(v.value)} key={k}>
            {v.label}
          </MenuItem>
        ))}
      </Menu>

      <TrainingContentsReorderDialog
        contents={contents}
        open={reorder}
        onClose={handleReorderClose}
        order={training.order}
      />
      <TrainingDetailDialog
        apiUrl={apiBase}
        open={editTraining}
        onClose={() => setEditTraining(false)}
        onSubmit={setTraining}
        training={training}
      />
      <ContentDetailDialog
        open={!!content}
        type={content || 'DOCUMENT'}
        onClose={handleSubmit}
      />
      <ConfirmDialog
        open={removeTraining}
        title="Remove Training"
        onClose={s => {
          if (s) navigate(backUrl);
          setRemoveTraining(false)
        }}
        text="Are you sure to remove this training?"
        confirmButton="Remove"
        apiLink={apiBase}
      />
    </Box>
  );
}
