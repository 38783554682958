import React from 'react';
import {useNavigate} from 'react-router-dom';
import FormAssignmentsCard from 'pages/FormBuilder/FormAssignments';
import {useClientContext} from 'functions/context';

function HomePage() {
  const context = useClientContext();
  const navigate = useNavigate();

  if (context.location.modules.includes('FORMS')) {
    return (
      <FormAssignmentsCard
        title="Form Assignments"
        portal="client"
        userPortal="CLIENT"
        userId={context.client!.id}
        onRowClick={form =>
          navigate(`${context.navigationBase}/forms/${form.id}/fill`)
        }
      />
    );
  }
  return <div />;
}

export default HomePage;
