import React, {useEffect, useState} from 'react';

import {apiCall} from 'functions/api';

import Dialog from './Dialog';
import ValidatedInput, {initialValue} from './ValidatedInput';

interface Props {
  open: boolean;
  onClose: (successful: boolean) => void;
  onSubmit?: () => Promise<any>;
  apiLink?: string;
  cancelButton?: string;
  submitButton?: string;
}

function ChangePasswordDialog(props: Props) {
  const [submitting, setSubmitting] = React.useState(false);
  const [password, setPassword] = useState(initialValue('password'));
  const [repeatPassword, setRepeatPassword] = useState(
    initialValue('password'),
  );
  useEffect(() => {
    setPassword(initialValue('password'));
    setRepeatPassword(initialValue('password'));
  }, [props.open]);
  const handleSubmit = () => {
    setSubmitting(true);
    let promise = Promise.resolve();
    if (props.onSubmit) {
      promise = props.onSubmit();
    } else if (props.apiLink) {
      promise = apiCall(props.apiLink, 'POST', {password: password.value});
    }
    promise
      .then(() => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch(e => {
        console.error(e);
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      title="Change Password"
      width="xs"
      onSubmit={handleSubmit}
      actions={[
        {
          text: props.cancelButton || 'Cancel',
          color: 'secondary',
          onClick: handleClose,
        },
        {
          text: props.submitButton || (submitting ? 'Submitting' : 'Submit'),
          disabled:
            submitting ||
            !password.success ||
            !repeatPassword.success ||
            password.value !== repeatPassword.value,
          color: 'primary',
          submit: true,
        },
      ]}>
      <ValidatedInput
        label="Password"
        value={password}
        onChange={setPassword}
        type="password"
      />
      <ValidatedInput
        label="Repeat Password"
        value={repeatPassword}
        onChange={setRepeatPassword}
        type="password"
      />
    </Dialog>
  );
}

export default ChangePasswordDialog;
