import React, {useState} from 'react';

import {useClientContext} from 'functions/context';

import Card from 'components/Card';
import Details from 'components/Details';
import ChangePasswordDialog from 'components/ChangePasswordDialog';

export default function SettingsPage() {
  const {client} = useClientContext();
  const [changePassword, setChangePassword] = useState(false);
  return (
    <div>
      <Card
        title="My Profile"
        padding
        menu={[
          {label: 'Change Password', onClick: () => setChangePassword(true)},
        ]}>
        <Details label="Name">
          {client?.firstName} {client?.lastName}
        </Details>
        <Details label="Email">{client?.email}</Details>
      </Card>
      <ChangePasswordDialog
        open={changePassword}
        onClose={() => setChangePassword(false)}
        apiLink={`/location/clients/${client?.id}`}
      />
    </div>
  );
}
