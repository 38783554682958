import React, {useMemo, useState} from 'react';

import {useParams} from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowOutward from '@mui/icons-material/ArrowOutward';

import type {TrainingContent} from 'types/trainings';

import Card from 'components/Card';
import ConfirmDialog from 'components/ConfirmDialog';

import {useTrainingBuilderContext} from 'functions/context';

import ContentDetailDialog from './ContentDialog';
import QuizBuilder from './QuizBuilder';

export interface TrainingItemProps {
  contents: TrainingContent[];
  onUpdate: (content: TrainingContent) => void;
  onRemove: (contentId: number) => void;
  editable: boolean;
}

const STORAGE_BASE = process.env.REACT_APP_GOOGLE_STORAGE_LINK;

export default function TrainingItem({
  contents,
  onUpdate,
  onRemove,
  editable,
}: TrainingItemProps) {
  const contentId = +(useParams().contentId as string);
  const content = useMemo(
    () => contents.find(c => c.id === contentId),
    [contentId, contents],
  );

  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);

  const {apiBase} = useTrainingBuilderContext();

  if (!content) {
    return (
      <Box
        sx={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
        Add a content to view details
      </Box>
    );
  }
  return (
    <Card
      sx={{padding: 2}}
      menu={
        editable
          ? [
              {
                label: 'Edit',
                onClick: () => setEdit(true),
              },
              {
                label: 'Remove',
                onClick: () => setRemove(true),
              },
            ]
          : undefined
      }>
      <Typography variant="h5" sx={{mb: 2}}>
        {content.name}
      </Typography>
      <Typography sx={{mb: 2}}>{content.description}</Typography>
      {content.youtubeId && (
        <iframe
          width="100%"
          height="400px"
          title={content.name}
          style={{borderRadius: 8, border: 'none'}}
          src={`https://www.youtube.com/embed/${content.youtubeId}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      )}
      {content.file && (
        <Button
          href={STORAGE_BASE + content.file}
          sx={{color: 'white !important'}}
          target="_blank"
          endIcon={<ArrowOutward />}>
          View Document
        </Button>
      )}
      {content.quiz && (
        <>
          <Typography sx={{mb: 2}}>
            Questions (Number of Questions: {content.quiz.numberOfQuestions})
          </Typography>
          <QuizBuilder
            editable={editable}
            quiz={content.quiz}
            setContent={onUpdate}
            contentId={content.id}
          />
        </>
      )}
      <ContentDetailDialog
        open={edit}
        type={content.type}
        onClose={submit => {
          setEdit(false);
          if (submit) onUpdate(submit);
        }}
        content={content}
      />
      <ConfirmDialog
        open={remove}
        title="Remove Content"
        onClose={s => {
          setRemove(false);
          if (s) onRemove(content.id);
        }}
        text="Are you sure to remove this content?"
        confirmButton="Remove"
        apiLink={`${apiBase}/contents/${content.id}`}
      />
    </Card>
  );
}
