import React from 'react';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface Props {
  label: string;
  value: string | number | undefined;
  items: Array<{label: string; value: string | number}>;
  onChange: (value: string | number | undefined) => void;
  multiple?: boolean;
  freeSolo?: boolean;
}

const filter = createFilterOptions<{label: string; value: string | number}>();

function Combo(props: Props) {
  const {label, value, items, onChange, freeSolo, ...rest} = props;

  return (
    <Autocomplete
      {...rest}
      value={items.find(item => item.value === value)}
      options={items}
      filterOptions={
        freeSolo
          ? (options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push({
                  label: `Add "${params.inputValue}"`,
                  value: params.inputValue,
                });
              }

              return filtered;
            }
          : undefined
      }
      disableClearable
      renderOption={(itemProps, option) => (
        <li {...itemProps}>{option.label}</li>
      )}
      getOptionLabel={
        freeSolo
          ? option =>
              (typeof option === 'string' ? option : option.value).toString()
          : undefined
      }
      freeSolo={freeSolo}
      selectOnFocus
      clearOnBlur
      onChange={(e, selected) => {
        let change: string | number = '';
        if (typeof selected === 'string') {
          change = selected;
        } else if (!Array.isArray(selected)) {
          change = selected?.value || '';
        }
        onChange(change);
      }}
      renderInput={params => <TextField {...params} label={label} />}
    />
  );
}

export default Combo;
