import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import FullPageLoader from 'components/FullPageLoader';

import FormMainPage from 'pages/FormBuilder/Main';
import FormBuilderPage from 'pages/FormBuilder/Page';

import {
  FormBuilderContext,
  FormBuilderContextType,
  useLocationContext,
} from 'functions/context';
import {APIError, apiCall} from 'functions/api';
import {getAPIBases} from 'functions/forms';

import type {Form, FormRoleExpanded, ListRolesRequest} from 'types/forms';
import type {ListResponse} from 'types/utils';
import type {Portal} from 'types/auth';

export interface Props {
  portal: Portal;
  formId: number;
}

export default function FormBuilderLayout(props: Props) {
  const navigate = useNavigate();
  const locationContext = useLocationContext();
  const [form, setForm] = React.useState<Form>();
  const [formRoles, setFormRoles] = React.useState<FormRoleExpanded[]>();

  const from = useSearchParams()[0].get('from');

  const [formsAPIBase, rolesAPIBase] = React.useMemo(
    () => getAPIBases(props.portal),
    [props.portal],
  );

  const loadForm = async () => {
    const apiLink = `${formsAPIBase}/${props.formId}`;
    setForm(undefined);
    try {
      const response: Form = await apiCall(apiLink, 'GET');
      setForm(response);
    } catch (e) {
      console.error(e);
      if (e instanceof APIError && e.code === 'not_found') {
        if (props.portal === 'admin') {
          navigate('/admin/forms');
        } else {
          navigate(from || `${locationContext.navigationBase}/forms`);
        }
      }
    }
  };

  const loadRoles = async () => {
    const apiLink = rolesAPIBase;
    const request: ListRolesRequest = {
      expand: true,
      formId: props.formId,
    };
    setFormRoles(undefined);
    try {
      const response: ListResponse<FormRoleExpanded> = await apiCall(
        apiLink,
        'GET',
        request,
      );
      setFormRoles(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    loadForm();
    loadRoles();
  }, []);

  const contextValue = React.useMemo<FormBuilderContextType | undefined>(() => {
    if (!form || !formRoles) {
      return undefined;
    }
    const navigationBase =
      props.portal === 'admin'
        ? `/admin/forms/${form.id}`
        : `/${locationContext?.location.locationId}/forms/${form.id}/builder`;
    let editable = props.portal !== 'client';

    if (props.portal === 'location') {
      editable =
        !!form.locationId &&
        locationContext.authUser!.roles.includes('MANAGE_FORMS');
    }

    return {
      form,
      navigationBase,
      formsAPIBase,
      rolesAPIBase,
      backUrl: from,
      roles: formRoles,
      editable,
      portal: props.portal,
      updateForm: setForm,
      updateRoles: setFormRoles,
      loadForm,
      loadRoles,
    };
  }, [form, formRoles, from, locationContext?.authUser]);

  if (!contextValue) {
    return <FullPageLoader />;
  }

  return (
    <FormBuilderContext.Provider value={contextValue}>
      <Routes>
        <Route path="/" element={<FormMainPage />} />
        <Route path="pages/:pageId" element={<FormBuilderPage />} />
        <Route
          path="*"
          element={<Navigate to={contextValue.navigationBase} />}
        />
      </Routes>
    </FormBuilderContext.Provider>
  );
}
