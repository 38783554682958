import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import {GridActionsCellItem} from '@mui/x-data-grid';

import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Article from '@mui/icons-material/Article';

import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  type DragEndEvent,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';

import SettingsIcon from '@mui/icons-material/Settings';
import FormBuilderNavbar from 'components/FormNavbar';
import Card from 'components/Card';
import Details from 'components/Details';
import Button from 'components/Button';
import ConfirmDialog from 'components/ConfirmDialog';
import DataTable from 'components/DataTable';

import {useFormBuilderContext, useLocationContext} from 'functions/context';
import {apiCall} from 'functions/api';
import {storageBase} from 'functions/uploader';
import {formRoleNames} from 'functions/forms';

import type {
  Form,
  FormPage,
  FormRoleExpanded,
  FormRolePortal,
} from 'types/forms';
import type {ListResponse} from 'types/utils';

import FormDialog from './FormDialog';
import PageDialog from './PageDialog';
import RoleDialog from './RoleDialog';

interface PageItemProps {
  page: FormPage;
}

function PageItem(props: PageItemProps) {
  const {page} = props;
  const {attributes, listeners, setNodeRef, transform} = useSortable({
    id: page.id,
  });
  const context = useFormBuilderContext();

  return (
    <div
      style={{
        transform: CSS.Transform.toString(
          transform && {
            x: transform.x,
            y: transform.y,
            scaleX: 1,
            scaleY: 1,
          },
        ),
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}>
      <MuiCard
        sx={{
          p: 2,
          mb: 1,
          display: 'flex',
          alignItems: 'center',
          background: 'white',
        }}>
        <Box sx={{mr: 1}}>
          {!page.image && (
            <Article
              sx={{
                color: 'gray',
                verticalAlign: 'middle',
                width: '40px',
                height: '40px',
              }}
            />
          )}
          {page.image && (
            <img
              src={storageBase + page.image!}
              alt={page.name}
              width={40}
              height={40}
              style={{objectFit: 'contain'}}
            />
          )}
        </Box>
        <Box sx={{flex: 1}}>
          <Typography>{page.name}</Typography>
          <Typography textTransform="capitalize" fontSize={11} lineHeight={1}>
            {page.type.toLowerCase()}
          </Typography>
        </Box>
        <Link to={`${context.navigationBase}/pages/${page.id}`}>
          <IconButton>
            <KeyboardArrowRight />
          </IconButton>
        </Link>
      </MuiCard>
    </div>
  );
}

function FormMainPage() {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [pageDialogOpen, setPageDialogOpen] = React.useState(false);
  const [pages, setPages] = React.useState<FormPage[]>();
  const [roleDialogOpen, setRoleDialogOpen] = React.useState(false);
  const [deleteRoleDialogOpen, setDeleteRoleDialogOpen] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState<FormRoleExpanded>();
  const [rolesLoading, setRolesLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  const navigate = useNavigate();
  const context = useFormBuilderContext()!;
  const locationContext = useLocationContext();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const order = React.useMemo(
    () => (pages ? pages.map(page => page.id) : []),
    [pages],
  );
  const orderChanged = React.useMemo(
    () => !context.form.order.every((id, index) => id === order[index]),
    [context.form.order, order],
  );

  const backLink =
    context.backUrl ||
    (context.portal === 'admin'
      ? '/admin/forms'
      : `${locationContext.navigationBase}/forms`);

  const formDialogAPIUrl =
    context.portal === 'admin'
      ? `/admin/forms/${context.form.id}`
      : `/location/forms/${context.form.id}`;

  const loadPages = (reloadForm: boolean) => {
    setPages(undefined);
    const apiURL = `${context.formsAPIBase}/${context.form.id}`;
    const promise = reloadForm
      ? apiCall(apiURL)
      : Promise.resolve(context.form);

    promise.then((form: Form) => {
      if (reloadForm) {
        context.updateForm(form);
      }
      const apiURL = `${context.formsAPIBase}/${context.form.id}/pages`;
      apiCall(apiURL).then((response: ListResponse<FormPage>) => {
        const pages = response.data;
        const order = [...form.order];
        pages.forEach(page => !order.includes(page.id));
        const ordered: Array<FormPage> = order.map(
          id => pages.find(page => page.id === id)!,
        );
        setPages(ordered);
      }, console.error);
    });
  };

  const handleEditButtonClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = (reload: boolean, form?: Form) => {
    setEditDialogOpen(false);
    if (reload) {
      context.updateForm(form!);
    }
  };

  const handleDeleteButtonClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = (reload: boolean) => {
    if (reload) {
      navigate(backLink, {replace: true});
    }
    setDeleteDialogOpen(false);
  };

  const handleAddPage = () => {
    setPageDialogOpen(true);
  };

  const handlePageDialogClose = (reload: boolean) => {
    setPageDialogOpen(false);
    if (reload) {
      loadPages(true);
    }
  };

  const handleAddRole = () => {
    setSelectedRole(undefined);
    setRoleDialogOpen(true);
  };

  const handleRoleEdit = (role: FormRoleExpanded) => {
    setSelectedRole(role);
    setRoleDialogOpen(true);
  };

  const handleRoleDelete = (role: FormRoleExpanded) => {
    setSelectedRole(role);
    setDeleteRoleDialogOpen(true);
  };

  const handleRoleDialogClose = (reload: boolean) => {
    setRoleDialogOpen(false);
    setDeleteRoleDialogOpen(false);
    if (reload) {
      setRolesLoading(true);
      context.loadRoles().then(() => setRolesLoading(false));
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (over && active.id !== over.id) {
      const oldIndex = order.findIndex(id => id === active.id);
      const newIndex = order.findIndex(id => id === over.id);
      const newOrder = arrayMove(order, oldIndex, newIndex);
      const newPages = newOrder.map(id => pages!.find(page => page.id === id)!);
      setPages(newPages);
    }
  };

  const handleSaveOrder = () => {
    setSubmitting(true);
    apiCall(formDialogAPIUrl, 'POST', {order}).then((response: Form) => {
      setSubmitting(false);
      context.updateForm(response);
    }, console.error);
  };

  const getRoleUserName = (role: FormRoleExpanded) => {
    if (role.portal === 'EXTERNAL_USER') {
      return role.email || '';
    }
    if (role.portal === 'LOCATION') {
      return `${role.user?.firstName || ''} ${role.user?.lastName || ''}`;
    }
    return `${role.client?.firstName || ''} ${role.client?.lastName || ''}`;
  };

  React.useEffect(() => {
    loadPages(false);
  }, [context.form]);

  return (
    <Box>
      <FormBuilderNavbar title={context.form.name} backLink={backLink} />
      <Container maxWidth="md" sx={{mt: 3, mb: 3}}>
        <Card
          title="Form Details"
          padding
          menuIcon={<SettingsIcon />}
          menu={[
            {label: 'Edit Details', onClick: handleEditButtonClick},
            {label: 'Delete', onClick: handleDeleteButtonClick},
          ]}>
          <Details label="Name">{context.form.name}</Details>
          <Details label="Description">{context.form.description}</Details>
          <Details label="Type">
            {context.form.type === 'TEMPLATE'
              ? 'Template Form'
              : 'Instance Form'}
          </Details>
          {context.form.autoAssign && (
            <Details label="Auto Assign To">
              {context.form.autoAssign === 'CLIENT'
                ? 'New Clients'
                : 'New Users'}
            </Details>
          )}
          {context.form.type === 'INSTANCE' && (
            <Details label="Status">
              {context.form.status === 'COMPLETE' ? (
                <Typography color="success">Complete</Typography>
              ) : (
                <Typography color="error">Incomplete</Typography>
              )}
            </Details>
          )}
          {context.editable && (
            <Box sx={{justifyContent: 'right', display: 'flex'}}>
              {context.portal === 'location' &&
                context.form.type === 'INSTANCE' && (
                  <Button
                    size="medium"
                    color="primary"
                    sx={{mt: 1}}
                    onClick={() =>
                      navigate(
                        `${locationContext.navigationBase}/forms/${context.form.id}/fill`,
                      )
                    }>
                    Fill Form
                  </Button>
                )}
            </Box>
          )}
        </Card>
        <Card
          title="Roles"
          padding={false}
          sx={{mt: 3}}
          button={
            context.editable && !context.form.autoAssign
              ? 'Add Role'
              : undefined
          }
          buttonOnClick={handleAddRole}
          loading={rolesLoading}>
          <DataTable<FormRoleExpanded>
            rows={context.roles}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                minWidth: 200,
                flex: 1,
                sortable: false,
              },
              {
                field: 'type',
                headerName: 'Type',
                minWidth: 200,
                flex: 1,
                sortable: false,
                valueGetter: params =>
                  formRoleNames[params.row.portal as FormRolePortal],
              },
              context.form.type === 'INSTANCE' && {
                field: 'user',
                headerName: 'User',
                minWidth: 200,
                flex: 1,
                sortable: false,
                valueGetter: params => getRoleUserName(params.row),
              },
              context.editable &&
                !context.form.autoAssign && {
                  field: 'actions',
                  type: 'actions',
                  minWidth: 100,
                  flex: 2,
                  align: 'right',
                  getActions: params => [
                    <GridActionsCellItem
                      key={0}
                      showInMenu
                      label="Edit"
                      onClick={() => handleRoleEdit(params.row)}
                    />,
                    <GridActionsCellItem
                      key={1}
                      showInMenu
                      label="Delete"
                      onClick={() => handleRoleDelete(params.row)}
                    />,
                  ],
                },
            ]}
          />
        </Card>
        <Typography fontWeight="300" fontSize={18} sx={{mt: 3, mb: 1}}>
          Pages
        </Typography>
        {!pages && (
          <Box sx={{textAlign: 'center'}}>
            <CircularProgress />
          </Box>
        )}
        {pages && (
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}>
              <SortableContext
                items={order}
                disabled={!context.editable}
                strategy={verticalListSortingStrategy}>
                {pages.map(page => (
                  <PageItem page={page} key={page.id} />
                ))}
              </SortableContext>
            </DndContext>
            {!pages.length && (
              <Typography>No pages have been added yet</Typography>
            )}
            <Box sx={{mt: 1, textAlign: 'right'}}>
              {!orderChanged && context.editable && (
                <Button onClick={handleAddPage} color="secondary">
                  Add Page
                </Button>
              )}
              {orderChanged && context.editable && (
                <Button onClick={handleSaveOrder} disabled={submitting}>
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              )}
            </Box>
          </>
        )}
      </Container>
      <FormDialog
        open={editDialogOpen}
        type={context.form.type}
        form={context.form}
        onClose={handleEditDialogClose}
      />
      <PageDialog open={pageDialogOpen} onClose={handlePageDialogClose} />
      <RoleDialog
        open={roleDialogOpen}
        onClose={handleRoleDialogClose}
        role={selectedRole}
      />
      <ConfirmDialog
        open={deleteRoleDialogOpen}
        title="Delete Role"
        text="Are you sure you want to delete this role?"
        onClose={handleRoleDialogClose}
        apiLink={`${context.rolesAPIBase}/${selectedRole?.id}`}
      />
      <ConfirmDialog
        open={deleteDialogOpen}
        title="Delete Form"
        text="Are you sure you want to delete this form?"
        onClose={handleDeleteDialogClose}
        apiLink={`${context.formsAPIBase}/${context.form.id}`}
      />
    </Box>
  );
}

export default FormMainPage;
