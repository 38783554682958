import TextIcon from '@mui/icons-material/TextFields';
import LongTextIcon from '@mui/icons-material/TextSnippet';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SignatureIcon from '@mui/icons-material/Edit';

import type {FormRolePortal} from 'types/forms';
import type {DocumentComponentType, FormComponentType} from 'types/formBuilder';
import type {Portal} from 'types/auth';

export const defaultImageWidth = 900;

export const formComponentTypes: FormComponentType[] = [
  'TEXT',
  'LONG_TEXT',
  'EMAIL',
  'DATE',
  'RADIO',
  'CHECKBOX',
  'SIGNATURE',
  'HEADING',
];

export const formTextComponentTypes: FormComponentType[] = [
  'TEXT',
  'LONG_TEXT',
  'EMAIL',
  'DATE',
];

export const documentComponentTypes: DocumentComponentType[] = [
  'TEXT',
  'LONG_TEXT',
  'EMAIL',
  'DATE',
  'CHECKBOX',
  'SIGNATURE',
];

export const documentTextComponentTypes: DocumentComponentType[] = [
  'TEXT',
  'LONG_TEXT',
  'EMAIL',
  'DATE',
];

export const formComponentNames: {[key in FormComponentType]: string} = {
  TEXT: 'Text',
  LONG_TEXT: 'Long Text',
  EMAIL: 'Email',
  DATE: 'Date',
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  SIGNATURE: 'Signature',
  HEADING: 'Heading',
};

export const documentComponentNames: {[key in DocumentComponentType]: string} =
  {
    TEXT: 'Text',
    LONG_TEXT: 'Long Text',
    EMAIL: 'Email',
    DATE: 'Date',
    CHECKBOX: 'Checkbox',
    SIGNATURE: 'Signature',
  };

export const documentComponentIcons: {
  [key in DocumentComponentType]: React.JSX.ElementType;
} = {
  TEXT: TextIcon,
  LONG_TEXT: LongTextIcon,
  EMAIL: TextIcon,
  DATE: TextIcon,
  CHECKBOX: CheckBoxIcon,
  SIGNATURE: SignatureIcon,
};

export const formRoleNames: {[key in FormRolePortal]: string} = {
  CLIENT: 'Client',
  LOCATION: 'User',
  EXTERNAL_USER: 'External User',
};

export function getAPIBases(portal: Portal) {
  if (portal === 'admin') {
    return ['/admin/forms', '/admin/formRoles'];
  }
  if (portal === 'location') {
    return ['/location/forms', '/location/formRoles'];
  }
  return ['/client/forms', '/client/formRoles'];
}
