import React from 'react';

import {apiCall} from 'functions/api';
import Dialog from './Dialog';

interface Props {
  open: boolean;
  onClose: (successful: boolean) => void;
  onConfirm?: () => Promise<any>;
  apiLink?: string;
  title: string;
  text: string;
  submitting?: boolean;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  errorMessage?: string;
  cancelButton?: string;
  confirmButton?: string;
}

function ConfirmDialog(props: Props) {
  const [submitting, setSubmitting] = React.useState(false);

  const handleConfirm = () => {
    let promise = Promise.resolve();
    if (props.onConfirm) {
      promise = props.onConfirm();
    } else if (props.apiLink) {
      promise = apiCall(props.apiLink, 'DELETE');
    } else {
      props.onClose(true);
      return;
    }
    setSubmitting(true);
    promise
      .then(() => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch(e => {
        console.error(e);
        setSubmitting(false);
      });
  };

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  React.useEffect(() => {
    setSubmitting(!!props.submitting);
  }, [props.submitting]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      title={props.title}
      width={props.width || 'sm'}
      onSubmit={handleConfirm}
      errorMessage={props.errorMessage}
      actions={[
        {
          text: props.cancelButton || 'Cancel',
          color: 'secondary',
          onClick: handleClose,
        },
        {
          text: props.confirmButton || (submitting ? 'Confirming' : 'Confirm'),
          disabled: submitting,
          color: 'primary',
          submit: true,
        },
      ]}>
      {props.text}
    </Dialog>
  );
}

export default ConfirmDialog;
