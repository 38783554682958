import React, {useState} from 'react';

import Card from 'components/Card';
import Details from 'components/Details';
import ChangePasswordDialog from 'components/ChangePasswordDialog';
import ConfirmDialog from 'components/ConfirmDialog';

import {useLocationContext} from 'functions/context';
import type {ClientResponse} from 'types/clients';

import ClientDialog from './Dialog';

function ClientInfoPage() {
  const context = useLocationContext();
  const client: ClientResponse = context.extra.client;
  const reload: any = context.extra.reload;

  const [action, setAction] = useState<'edit' | 'change-password' | 'remove'>();
  return (
    <>
      <Card
        title="Client Details"
        padding
        menu={[
          {
            label: 'Edit',
            onClick: () => {
              setAction('edit');
            },
          },
          {
            label: 'Change Password',
            onClick: () => {
              setAction('change-password');
            },
          },
          {
            label: 'Remove',
            onClick: () => {
              setAction('remove');
            },
          },
        ]}>
        {client && (
          <>
            <Details label="Name">
              {client.firstName} {client.lastName}
            </Details>
            <Details label="Email">{client.email}</Details>
          </>
        )}
      </Card>
      <ChangePasswordDialog
        open={action === 'change-password'}
        onClose={success => {
          if (success) {
            reload();
          }
          setAction(undefined);
        }}
        apiLink={`/location/clients/${client.id}`}
      />
      <ConfirmDialog
        open={action === 'remove'}
        onClose={success => {
          if (success) {
            reload();
          }
          setAction(undefined);
        }}
        title="Remove Client"
        text="Are you sure you want to remove this client?"
        apiLink={`/location/clients/${client.id}`}
      />
      <ClientDialog
        open={action === 'edit'}
        client={client}
        onClose={success => {
          if (success) {
            reload();
          }
          setAction(undefined);
        }}
      />
    </>
  );
}

export default ClientInfoPage;
