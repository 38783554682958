import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import {type LocationResponse} from 'types/locations';
import {type ListResponse} from 'types/utils';
import {type TabRoute} from 'types/routes';
import {activeRoute} from 'functions/utils';
import {AdminContext, useAdminContext} from 'functions/context';
import LocationInfoPage from './Info';
import LocationDialog from './Dialog';

import TrainingsAssignments from './Trainings';
import FormsAssignments from './Forms';
import BooksAssignments from './Books';

const routes: TabRoute[] = [
  {
    name: 'Info',
    path: 'info',
    element: LocationInfoPage,
  },
  {
    name: 'Training Groups',
    path: 'trainings',
    module: 'TRAININGS',
    element: TrainingsAssignments,
  },
  {
    name: 'Forms',
    path: 'forms',
    module: 'FORMS',
    element: FormsAssignments,
  },
  {
    name: 'Books',
    path: 'books',
    element: BooksAssignments,
  },
];

function LocationsListPage() {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [locations, setLocations] = React.useState<LocationResponse[]>();

  const loadData = () => {
    setLocations(undefined);
    apiCall('/admin/locations').then(
      (response: ListResponse<LocationResponse>) => {
        setLocations(response.data);
      },
    );
  };

  const handleLocationClick = (id: number) => {
    navigate(`/admin/locations/${id}`);
  };

  const handleAddClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (success: boolean) => {
    setDialogOpen(false);
    if (success) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card title="Locations" button="Add" buttonOnClick={handleAddClick}>
        <DataTable
          rows={locations}
          columns={[
            {
              field: 'locationId',
              headerName: 'Id',
              minWidth: 100,
              flex: 2,
              sortable: false,
            },
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 200,
              flex: 2,
              sortable: false,
            },
            {
              field: 'email',
              headerName: 'Email',
              minWidth: 300,
              flex: 1,
              sortable: false,
            },
          ]}
          onRowClick={params => {
            handleLocationClick(params.row.id);
          }}
        />
      </Card>
      <LocationDialog open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
}

function LocationTabsPage() {
  const {locationId} = useParams<'locationId'>();
  const navigate = useNavigate();
  const location = useLocation();
  const context = useAdminContext();
  const [data, setData] = React.useState<LocationResponse>();

  const loadData = React.useCallback(() => {
    setData(undefined);
    apiCall(`/admin/locations/${locationId}`).then(
      (response: LocationResponse) => {
        setData(response);
      },
      () => navigate('/admin/locations'),
    );
  }, [locationId]);

  React.useEffect(() => {
    loadData();
  }, []);

  const base = React.useMemo(
    () => `/admin/locations/${locationId}`,
    [locationId],
  );
  const active = React.useMemo(
    () =>
      routes.find(route => activeRoute(`${base}/${route.path}`, location))
        ?.path,
    [location.pathname],
  );
  const updatedContext = React.useMemo(
    () => ({
      ...context,
      extra: {
        location: data,
        reload: loadData,
      },
    }),
    [data, context, loadData],
  );

  if (!data) {
    return (
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
    );
  }

  const filteredRoutes = routes.filter(route => {
    if (!route.module) return true;
    return data.modules.includes(route.module);
  });

  return (
    <>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={active}
          onChange={(event, value) => {
            navigate(`${base}/${value}`);
          }}>
          {filteredRoutes.map(route => (
            <Tab key={route.path} label={route.name} value={route.path} />
          ))}
        </Tabs>
      </Box>
      <br />
      <AdminContext.Provider value={updatedContext}>
        <Routes>
          {filteredRoutes.map(route => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.element />}
            />
          ))}
          <Route path="*" element={<Navigate to={filteredRoutes[0].path} />} />
        </Routes>
      </AdminContext.Provider>
    </>
  );
}

function LocationsPage() {
  return (
    <Routes>
      <Route path="/:locationId/*" element={<LocationTabsPage />} />
      <Route path="/" element={<LocationsListPage />} />
      <Route path="*" element={<Navigate to="/admin/locations" />} />
    </Routes>
  );
}

export default LocationsPage;
