import React, {forwardRef, useRef, useState} from 'react';

import MoreVert from '@mui/icons-material/MoreVert';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type {SxProps} from '@mui/system';

import {cardShadow, grayColor} from 'assets/jss/main';

export interface CardProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  sx?: SxProps;
  button?: string | false;
  buttonDisabled?: boolean;
  buttonOnClick?: () => void;
  padding?: boolean;
  loading?: boolean;
  empty?: boolean;
  emptyText?: string;
  menuIcon?: React.ReactElement;
  menu?: Array<{label: string; onClick?: () => void}> | false;
  buttonMenu?: Array<{label: string; onClick?: () => void}>;
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const [buttonMenuOpen, setButtonMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const buttonEl = useRef<HTMLButtonElement>(null);
  const iconButtonEl = useRef<HTMLButtonElement>(null);

  const handleButtonClick = () => {
    if (props.buttonOnClick) {
      props.buttonOnClick();
    } else if (props.buttonMenu) {
      setButtonMenuOpen(true);
    }
  };

  const buttons = (
    <div>
      {props.button && (
        <Button
          variant="contained"
          size="small"
          ref={buttonEl}
          disabled={props.buttonDisabled}
          onClick={handleButtonClick}>
          {props.button}
        </Button>
      )}
      {props.menu && (
        <IconButton
          size="small"
          sx={{ml: 1}}
          ref={iconButtonEl}
          onClick={() => setMenuOpen(true)}>
          {props.menuIcon || <MoreVert fontSize="small" />}
        </IconButton>
      )}
      {props.menu && (
        <Menu
          anchorEl={iconButtonEl.current}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}>
          {props.menu
            .filter(v => !!v.onClick)
            .map(item => (
              <MenuItem
                key={item.label}
                onClick={() => {
                  setMenuOpen(false);
                  item.onClick?.();
                }}>
                {item.label}
              </MenuItem>
            ))}
        </Menu>
      )}
      {props.buttonMenu && (
        <Menu
          anchorEl={buttonEl.current}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          open={buttonMenuOpen}
          onClose={() => setButtonMenuOpen(false)}>
          {props.buttonMenu
            .filter(v => !!v.onClick)
            .map(item => (
              <MenuItem
                key={item.label}
                onClick={() => {
                  setButtonMenuOpen(false);
                  item.onClick?.();
                }}>
                {item.label}
              </MenuItem>
            ))}
        </Menu>
      )}
    </div>
  );

  return (
    <MuiCard
      ref={ref}
      sx={{
        overflow: 'hidden',
        borderRadius: '10px',
        boxShadow: cardShadow,
        border: '1px solid #e1e1e1',
        ...props.sx,
      }}>
      {props.title ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 2,
            color: grayColor[7],
            pr: 2,
            pl: 3,
            alignItems: 'center',
            borderBottom: '1px solid #e1e1e1',
          }}>
          <Typography fontWeight="300" fontSize={18}>
            {props.title}
          </Typography>
          {buttons}
        </Box>
      ) : (
        !!(props.button || props.menu) && (
          <Box sx={{float: 'right', p: 1}}>{buttons}</Box>
        )
      )}
      {props.padding || props.loading || props.empty ? (
        <div
          style={{
            padding: '15px',
          }}>
          {props.loading && (
            <div style={{textAlign: 'center'}}>
              <CircularProgress />
            </div>
          )}
          {props.empty &&
            !props.loading &&
            (props.emptyText || 'No data to display.')}
          {!props.loading && !props.empty && props.children}
        </div>
      ) : (
        props.children
      )}
    </MuiCard>
  );
});

export default Card;
