import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';
import {APIError, apiCall} from 'functions/api';
import {
  type InsertLocationRequest,
  type LocationModule,
  type LocationResponse,
  type UpdateLocationRequest,
} from 'types/locations';

interface Props {
  open: boolean;
  onClose: (success: boolean) => void;
  location?: LocationResponse;
}

const idRegex = /^[a-z-]{3,}$/;

function LocationDialog(props: Props) {
  const [locationId, setLocationId] = React.useState(initialValue(idRegex));
  const [name, setName] = React.useState(initialValue('text'));
  const [email, setEmail] = React.useState(initialValue('email'));
  const [password, setPassword] = React.useState(initialValue('password'));
  const [repeatPassword, setRepeatPassword] = React.useState(
    initialValue('password'),
  );
  const [modules, setModules] = React.useState<LocationModule[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState('');

  const error = React.useMemo(
    () =>
      locationId.state !== 'success' ||
      name.state !== 'success' ||
      email.state !== 'success' ||
      (!props.location &&
        (password.state !== 'success' ||
          repeatPassword.state !== 'success' ||
          password.value !== repeatPassword.value)),
    [locationId, name, email, password, repeatPassword],
  );

  const handleModuleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const module = e.target.value as LocationModule;
    const filtered = modules.filter(m => m !== module);
    if (e.target.checked) {
      setModules([...filtered, module]);
    } else {
      setModules(filtered);
    }
  };

  const handleClose = () => {
    if (!submitting) {
      props.onClose(false);
    }
  };

  const handleSubmit = () => {
    if (error) {
      return;
    }
    setSubmitting(true);
    setServerError('');
    const url = props.location
      ? `/admin/locations/${props.location.id}`
      : '/admin/locations';
    const request: InsertLocationRequest | UpdateLocationRequest = {
      locationId: locationId.value,
      name: name.value,
      email: email.value,
      password: password.value,
      modules,
    };
    if (props.location) {
      delete request.password;
    }
    apiCall(url, 'POST', request)
      .then(() => {
        setSubmitting(false);
        props.onClose(true);
      })
      .catch(e => {
        setSubmitting(false);
        if (e instanceof APIError) {
          if (e.code === 'id_used') {
            setServerError('Location ID already in use.');
          } else {
            setServerError('An unexpected error occurred.');
          }
        }
      });
  };

  React.useEffect(() => {
    const {location} = props;
    if (props.open) {
      if (location) {
        setName(initialValue('text', location.name));
        setEmail(initialValue('email', location.email));
        setLocationId(initialValue(idRegex, location.locationId));
        setModules(location.modules);
      } else {
        setName(initialValue('text'));
        setEmail(initialValue('email'));
        setLocationId(initialValue(idRegex));
        setModules([]);
      }
      setPassword(initialValue('password'));
      setRepeatPassword(initialValue('password'));
    }
  }, [props.open, props.location]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={props.location ? 'Edit Location' : 'Create Location'}
      actions={[
        {
          text: 'Cancel',
          color: 'secondary',
          onClick: () => {
            handleClose();
          },
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          color: 'primary',
          submit: true,
          disabled: error || submitting,
        },
      ]}>
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      <ValidatedInput
        label="Location ID"
        value={locationId}
        onChange={setLocationId}
        type={idRegex}
      />
      <ValidatedInput
        label="Email"
        value={email}
        onChange={setEmail}
        type="email"
      />
      {props.location === undefined && (
        <>
          <ValidatedInput
            label="Password"
            value={password}
            onChange={setPassword}
            type="password"
          />
          <ValidatedInput
            label="Repeat Password"
            value={repeatPassword}
            onChange={setRepeatPassword}
            type="password"
          />
        </>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={modules.includes('TRAININGS')}
            value="TRAININGS"
            onChange={handleModuleChange}
          />
        }
        label="Enable trainings module"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={modules.includes('FORMS')}
            value="FORMS"
            onChange={handleModuleChange}
          />
        }
        label="Enable forms module"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={modules.includes('DOCUMENTS')}
            value="DOCUMENTS"
            onChange={handleModuleChange}
          />
        }
        label="Enable documents module"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={modules.includes('TASKS')}
            value="TASKS"
            onChange={handleModuleChange}
          />
        }
        label="Enable tasks module"
      />
      {serverError && <p style={{color: 'red'}}>{serverError}</p>}
    </Dialog>
  );
}

export default LocationDialog;
