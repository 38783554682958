import React from 'react';
import {useLocationContext} from 'functions/context';
import TaskAssignmentsCard from './Assignments';

export default function MyTasksPage() {
  const context = useLocationContext();

  if (!context.authUser?.user) {
    return null;
  }

  return (
    <TaskAssignmentsCard
      type="INSTANCE"
      user={context.authUser.user}
      title="My Tasks"
    />
  );
}
