import React from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import Card from 'components/Card';
import DataTable from 'components/DataTable';

import {apiCall} from 'functions/api';
import {type TrainingGroup} from 'types/trainings';
import {type ListResponse} from 'types/utils';

import TrainingsPage from './Trainings';
import TrainingGroupDialog from './TrainingGroupDialog';

function TrainingGroupsListPage() {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [TrainingGroups, setTrainingGroups] = React.useState<TrainingGroup[]>();

  const loadData = () => {
    setTrainingGroups(undefined);
    apiCall('/admin/trainingGroups').then(
      (response: ListResponse<TrainingGroup>) => {
        setTrainingGroups(response.data);
      },
    );
  };

  const handleLocationClick = (id: number) => {
    navigate(`/admin/training-groups/${id}`);
  };

  const handleAddClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = (success?: boolean) => {
    setDialogOpen(false);
    if (success) {
      loadData();
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Card title="Training Groups" button="Add" buttonOnClick={handleAddClick}>
        <DataTable
          rows={TrainingGroups}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              minWidth: 100,
              flex: 2,
              sortable: false,
            },
          ]}
          onRowClick={params => handleLocationClick(params.row.id)}
        />
      </Card>
      <TrainingGroupDialog open={dialogOpen} onClose={handleDialogClose} />
    </div>
  );
}

function TrainingGroupsPage() {
  return (
    <Routes>
      <Route path="/:groupId" element={<TrainingsPage />} />
      <Route path="/" element={<TrainingGroupsListPage />} />
      <Route path="*" element={<Navigate to="/admin/trainingGroups" />} />
    </Routes>
  );
}

export default TrainingGroupsPage;
