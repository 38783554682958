import React from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import Dialog from './Dialog';

interface Props {
  open: boolean;
  ratio?: number;
  onClose: (image?: string) => void;
  onClear?: () => void;
}

export default function SignatureDialog(props: Props) {
  const ratio = props.ratio || 1;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<ReactSignatureCanvas>(null);
  const [width, setWidth] = React.useState<number>(0);

  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear();
    }
    if (props.onClear) {
      props.onClear();
    }
  };

  const handleSubmit = () => {
    if (canvasRef.current) {
      props.onClose(canvasRef.current.toDataURL('image/png'));
    } else {
      props.onClose();
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (containerRef.current) {
        const boundingRect = containerRef.current.getBoundingClientRect();
        setWidth(boundingRect.width);
      }
    }, 20);
  }, [containerRef, props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose()}
      onSubmit={handleSubmit}
      title="Draw your signature"
      actions={[
        {
          color: 'secondary',
          text: 'Cancel',
          onClick: () => props.onClose(),
        },
        (props.onClear || false) && {
          color: 'secondary',
          text: 'Clear',
          onClick: handleClear,
        },
        {
          color: 'primary',
          text: 'Save',
          submit: true,
        },
      ]}>
      {props.open && (
        <div
          ref={containerRef}
          style={{border: '1px solid #e5e5e5', lineHeight: '0'}}>
          {!!width && (
            <ReactSignatureCanvas
              key={ratio}
              ref={canvasRef}
              canvasProps={{
                width: width - 2,
                height: ratio * (width - 2),
                className: 'sigCanvas',
              }}
            />
          )}
        </div>
      )}
    </Dialog>
  );
}
