import React from 'react';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import Box from '@mui/material/Box';

import Sidebar from 'components/Sidebar';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import FullPageLoader from 'components/FullPageLoader';

import routes from 'routes/location';

import LoginPage from 'pages/Location/Login';
import TaskInfoPage from 'pages/Location/Tasks/Info';

import {
  LocationContext,
  type LocationContextType,
  useLocationContext,
} from 'functions/context';
import {APIError, apiCall, clearAuthCookie} from 'functions/api';

import sidebarImage from 'assets/img/sidebar.jpg';
import {containerSx, drawerWidth, transition} from 'assets/jss/main';

import type {LocationResponse} from 'types/locations';
import type {GetUserResponse} from 'types/users';

import FormBuilderLayout from './FormBuilder';
import FormFillLayout from './FormFill';
import TrainingLayout from './Training';

function LocationLayout() {
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  const context = useLocationContext();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    window.addEventListener('resize', resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [mainPanel]);

  const filteredRoutes = React.useMemo(
    () =>
      routes.filter(route => {
        if (route.module && !context.location.modules.includes(route.module)) {
          return false;
        }
        if (
          route.roles &&
          !context.authUser?.roles.some(r => route.roles?.includes(r))
        ) {
          return false;
        }
        if (route.noAdmin && context.authUser?.isAdmin) {
          return false;
        }
        return true;
      }),
    [context.location, context.authUser],
  );

  return (
    <Box
      sx={{
        position: 'relative',
        top: '0',
        height: '100vh',
      }}>
      <Sidebar
        dark
        logoText={context.location.name}
        routes={filteredRoutes}
        base={context.navigationBase}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        image={sidebarImage}
      />
      <Box
        sx={theme => ({
          [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
          },
          overflow: 'auto',
          position: 'relative',
          float: 'right',
          maxHeight: '100%',
          width: '100%',
          overflowScrolling: 'touch',
          ...transition,
        })}>
        <Navbar
          settingsLink
          base={context.navigationBase}
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          sx={{
            marginTop: '70px',
            padding: '30px 15px',
            minHeight: 'calc(100vh - 123px)',
          }}>
          <Box sx={containerSx}>
            <Routes>
              {filteredRoutes.map((route, key) => (
                <Route
                  path={route.path}
                  element={<route.element />}
                  key={key}
                />
              ))}
              <Route path="/tasks/:taskId" element={<TaskInfoPage />} />
              <Route
                path="*"
                element={<Navigate to={routes[0].path} replace />}
              />
            </Routes>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

function FormBuilderWrapper() {
  const formId = parseInt(useParams<'formId'>().formId!, 10);
  return <FormBuilderLayout portal="location" formId={formId} />;
}

function FormFillWrapper() {
  const formId = parseInt(useParams<'formId'>().formId!, 10);
  return <FormFillLayout portal="location" formId={formId} />;
}

function TrainingFillWrapper() {
  const trainingId = parseInt(useParams<'trainingId'>().trainingId!, 10);
  const locationContext = useLocationContext();
  return (
    <TrainingLayout
      navigationBase={`${locationContext.navigationBase}/trainings/${trainingId}/fill`}
      type="fill"
      portal="location"
      trainingId={trainingId}
    />
  );
}

function TrainingBuilderWrapper() {
  const trainingId = parseInt(useParams<'trainingId'>().trainingId!, 10);
  const locationContext = useLocationContext();
  return (
    <TrainingLayout
      navigationBase={`${locationContext.navigationBase}/trainings/${trainingId}/builder`}
      type="edit"
      portal="location"
      trainingId={trainingId}
    />
  );
}

function LocationAuthenticatedLayout() {
  const locationId = useParams<'locationId'>().locationId!;
  const [context, setContext] = React.useState<LocationContextType>();
  const [loading, setLoading] = React.useState(true);

  const loadUser = (location: LocationResponse) => {
    setLoading(true);
    apiCall('/location/user', 'GET')
      .then((user: GetUserResponse) => {
        if (user.location.id !== location.id) {
          clearAuthCookie();
          window.location.reload();
        }
        setContext(context => ({
          ...context!,
          authUser: {
            isAdmin: user.isAdmin,
            roles: user.roles,
            user: user.user || undefined,
          },
        }));
        setLoading(false);
      })
      .catch(e => {
        if (e instanceof APIError && e.code === 'unauthorized') {
          setLoading(false);
        } else {
          console.error(e);
        }
      });
  };

  React.useEffect(() => {
    apiCall('/location/checkLocation', 'POST', {locationId}).then(
      (location: LocationResponse) => {
        setContext({
          extra: {},
          location,
          navigationBase: `/${locationId}`,
          updateContext: setContext,
          reloadUser: () => loadUser(location),
        });
        loadUser(location);
      },
      e => {
        if (e instanceof APIError && e.code === 'not_found') {
          window.location.href = '/';
        } else {
          console.error(e);
        }
      },
    );
  }, []);

  if (loading) {
    return <FullPageLoader />;
  }

  return (
    <LocationContext.Provider value={context}>
      {!context?.authUser && (
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="login" replace />} />
        </Routes>
      )}
      {context?.authUser && (
        <Routes>
          {context.location.modules.includes('FORMS') && (
            <>
              {context.authUser.roles.includes('MANAGE_FORMS') && (
                <Route
                  path="forms/:formId/builder/*"
                  element={<FormBuilderWrapper />}
                />
              )}
              <Route
                path="forms/:formId/fill/*"
                element={<FormFillWrapper />}
              />
            </>
          )}
          {context.location.modules.includes('TRAININGS') && (
            <>
              {context.authUser.roles.includes('MANAGE_TRAININGS') && (
                <Route
                  path="trainings/:trainingId/builder/*"
                  element={<TrainingBuilderWrapper />}
                />
              )}
              <Route
                path="trainings/:trainingId/fill/*"
                element={<TrainingFillWrapper />}
              />
            </>
          )}
          <Route path="*" element={<LocationLayout />} />
        </Routes>
      )}
    </LocationContext.Provider>
  );
}

export default LocationAuthenticatedLayout;
