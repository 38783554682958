import AccountTree from '@mui/icons-material/AccountTree';
import Book from '@mui/icons-material/Book';
import Feed from '@mui/icons-material/Feed';
import Home from '@mui/icons-material/Home';
import Store from '@mui/icons-material/Store';

import BooksPage from 'pages/Admin/Books';

import DashboardPage from 'pages/Admin/Dashboard';
import FormsPage from 'pages/Admin/Forms';
import LocationsPage from 'pages/Admin/Locations';
import TrainingGroupsPage from 'pages/Admin/Trainings';

import {type Route} from 'types/routes';

const routes: Route[] = [
  {
    path: 'dashboard',
    icon: Home,
    name: 'Dashboard',
    element: DashboardPage,
  },
  {
    path: 'locations/*',
    icon: Store,
    name: 'Locations',
    element: LocationsPage,
  },
  {
    path: 'forms',
    icon: Feed,
    name: 'Templates',
    element: FormsPage,
  },
  {
    path: 'training-groups/*',
    icon: AccountTree,
    name: 'Training Groups',
    element: TrainingGroupsPage,
  },
  {
    name: 'Books',
    path: 'books',
    icon: Book,
    element: BooksPage,
  },
];

export default routes;
