import React from 'react';
import {useLocationContext} from 'functions/context';

import type {UserResponse} from 'types/users';
import TrainingAssignmentCard from 'pages/Training/TrainingAssignments';

function UserTrainingsPage() {
  const context = useLocationContext();
  const user: UserResponse = context.extra.user;

  return <TrainingAssignmentCard type="userAssign" typeId={user.id} />;
}

export default UserTrainingsPage;
