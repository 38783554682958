import React, {useEffect} from 'react';

import Card, {CardProps} from 'components/Card';
import DataTable from 'components/DataTable';
import Typography from '@mui/material/Typography';

import type {
  Form,
  FormRoleExpanded,
  FormStatus,
  ListRolesRequest,
} from 'types/forms';

import FormDialog from 'pages/FormBuilder/Main/FormDialog';

import {apiCall} from 'functions/api';
import {getAPIBases} from 'functions/forms';
import {formatDate} from 'functions/utils';

import type {Portal} from 'types/auth';
import type {ListResponse} from 'types/utils';

interface Props extends CardProps {
  portal: Portal;
  form?: Form;
  status?: FormStatus;
  userId?: number;
  userPortal?: 'LOCATION' | 'CLIENT';
  onRowClick: (form: Form) => void;
}

export default function FormAssignmentsCard({
  form,
  status,
  portal,
  userId,
  userPortal,
  onRowClick,
  ...props
}: Props) {
  const [assignmentDialogOpen, setAssignmentDialogOpen] = React.useState(false);
  const [instantForms, setInstantForms] = React.useState<Form[]>();

  const [formsAPIBase, rolesAPIBase] = getAPIBases(portal);

  const handleAssignFormClick = () => {
    setAssignmentDialogOpen(true);
  };

  const handleAssignmentDialogClose = (reload: boolean) => {
    if (reload) setInstantForms(undefined);
    setAssignmentDialogOpen(false);
  };

  useEffect(() => {
    if (instantForms) return;
    if (form) {
      apiCall(formsAPIBase, 'GET', {
        type: 'INSTANCE',
        parentFormId: form.id,
        status,
      }).then((res: ListResponse<Form>) => {
        setInstantForms(res.data);
      });
    } else if (userId) {
      const request: ListRolesRequest = {
        userId,
        portal: userPortal,
        status,
        expand: true,
      };
      apiCall(rolesAPIBase, 'GET', request).then(
        (res: ListResponse<FormRoleExpanded>) => {
          setInstantForms(res.data.map(formRole => formRole.form));
        },
      );
    }
  }, [instantForms]);

  if (!form && !userId) return null;
  return (
    <Card
      title={props.title || 'Template Assignments'}
      button={form ? 'Assign Template' : undefined}
      buttonOnClick={handleAssignFormClick}
      {...props}>
      <DataTable<Form>
        rows={instantForms}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            sortable: false,
          },
          !status && {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: p =>
              p.row.status === 'COMPLETE' ? (
                <Typography color="success">Complete</Typography>
              ) : (
                <Typography color="error">Incomplete</Typography>
              ),
          },
          {
            field: 'createdAt',
            headerName: 'Assigned At',
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: p => p.row.createdAt && formatDate(p.row.createdAt),
          },
        ]}
        pageSize={50}
        onRowClick={({row}) => onRowClick(row)}
        loading={!instantForms}
      />
      {form && (
        <FormDialog
          open={assignmentDialogOpen}
          portal="location"
          onClose={handleAssignmentDialogClose}
          type="INSTANCE"
          template={form}
        />
      )}
    </Card>
  );
}
