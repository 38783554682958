import React from 'react';
import {useLocationContext} from 'functions/context';

import TaskAssignmentsCard from 'pages/Location/Tasks/Assignments';

import type {UserResponse} from 'types/users';

function UserTasksPage() {
  const context = useLocationContext();
  const user: UserResponse = context.extra.user;

  return (
    <TaskAssignmentsCard title="Task Assignments" user={user} type="INSTANCE" />
  );
}

export default UserTasksPage;
