import React from 'react';

import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  DndContext,
  type DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Button from 'components/Button';

import {useFormBuilderContext} from 'functions/context';
import {apiCall} from 'functions/api';

import type {
  FormComponent,
  FormPage,
  InsertComponentRequest,
  UpdateComponentRequest,
  UpdatePageRequest,
} from 'types/forms';
import type {FormBuilderPageProps} from 'types/formBuilder';

import FormComponentView from './FormComponent';
import FormComponentDialog from './FormComponentDialog';

function FormBuilder(props: FormBuilderPageProps) {
  const context = useFormBuilderContext();
  const apiBase = props.apiBase;
  const [submitting, setSubmitting] = React.useState(false);
  const [componentDialogOpen, setComponentDialogOpen] = React.useState(false);
  const [reorderMode, setReorderMode] = React.useState(false);
  const [components, setComponents] = React.useState<FormComponent[]>(
    props.components,
  );
  const [order, setOrder] = React.useState<number[]>(props.page.order);
  const [activeIdx, setActiveIdx] = React.useState<number>(-1);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const componentsOrdered = React.useMemo(
    () => order.map(id => components.find(c => c.id === id)!),
    [components, order],
  );
  const associatedComponents = React.useMemo(
    () =>
      componentsOrdered.map(component => {
        if (component.associatedId) {
          return components.find(c => c.id === component.associatedId);
        }
        return undefined;
      }),
    [componentsOrdered],
  );

  const handleAddClick = () => {
    if (context.editable) {
      setActiveIdx(-1);
      setComponentDialogOpen(true);
    }
  };

  const handleComponentClick = (component: FormComponent) => {
    if (context.editable) {
      setActiveIdx(components.findIndex(c => c.id === component.id));
      setComponentDialogOpen(true);
    }
  };

  const handleComponentDialogClose = () => {
    setComponentDialogOpen(false);
  };

  const loadComponents = React.useCallback(
    () =>
      Promise.all([
        apiCall(`${apiBase}/components`),
        apiCall(`${apiBase}`),
      ]).then(response => {
        const components = response[0].data as FormComponent[];
        const page = response[1] as FormPage;
        props.updatePage(page);
        setComponents(components);
        setOrder(page.order);
      }, console.error),
    [apiBase],
  );

  const handleComponentDialogSubmit = (component: FormComponent) => {
    setSubmitting(true);

    let promise: Promise<FormComponent>;
    if (activeIdx !== -1) {
      const request: UpdateComponentRequest = {
        associatedId: component.associatedId,
        isRequired: component.isRequired,
        roleId: component.roleId,
        properties: component.properties,
      };
      promise = apiCall(
        `${apiBase}/components/${components[activeIdx].id}`,
        'POST',
        request,
      );
    } else {
      const request: InsertComponentRequest = {
        associatedId: component.associatedId || undefined,
        roleId: component.roleId || undefined,
        isRequired: component.isRequired,
        type: component.type,
        properties: component.properties,
      };
      promise = apiCall(`${apiBase}/components`, 'POST', request);
    }
    promise
      .then(() => loadComponents(), console.error)
      .then(() => {
        setSubmitting(false);
        setComponentDialogOpen(false);
      });
  };

  const handleComponentDialogDelete = () => {
    setSubmitting(true);
    apiCall(`${apiBase}/components/${components[activeIdx].id}`, 'DELETE')
      .then(() => loadComponents(), console.error)
      .then(() => {
        setSubmitting(false);
        setComponentDialogOpen(false);
      });
  };

  const handleReorderClick = () => {
    if (!reorderMode) {
      setReorderMode(true);
    } else {
      setSubmitting(true);
      const request: UpdatePageRequest = {order};
      apiCall(`${apiBase}`, 'POST', request)
        .then(() => loadComponents(), console.error)
        .then(() => {
          setSubmitting(false);
          setReorderMode(false);
        });
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (over && active.id !== over.id) {
      const oldIndex = order.findIndex(id => id === active.id);
      const newIndex = order.findIndex(id => id === over.id);
      const newOrder = arrayMove(order, oldIndex, newIndex);
      setOrder(newOrder);
    }
  };

  React.useEffect(() => {
    props.updateNavbarProps({
      ...props.navbarProps,
      autoSaveStatus: submitting ? 'saving' : 'saved',
    });
  }, [submitting]);

  return (
    <Box>
      <Container maxWidth="md">
        <Card sx={{mt: 2, py: 2}}>
          {!components.length && (
            <Box sx={{px: 2, mb: 1}}>
              No components added yet. Click the button below to add a new
              component.
            </Box>
          )}
          {!reorderMode &&
            componentsOrdered.map((component, idx) => (
              <React.Fragment key={component.id}>
                {component.type === 'HEADING' && idx > 0 && (
                  <Divider sx={{my: 2}} />
                )}
                <FormComponentView
                  component={component}
                  associatedComponent={associatedComponents[idx]}
                  onClick={handleComponentClick}
                  sx={{px: 2, mb: 1}}
                />
              </React.Fragment>
            ))}
          {reorderMode && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}>
              <SortableContext
                items={order}
                disabled={!context.editable}
                strategy={verticalListSortingStrategy}>
                {componentsOrdered.map((component, idx) => (
                  <FormComponentView
                    key={component.id}
                    component={component}
                    associatedComponent={associatedComponents[idx]}
                    sx={{px: 2, mb: 1}}
                    reorderMode
                  />
                ))}
              </SortableContext>
            </DndContext>
          )}
          {context.editable && (
            <Box sx={{px: 2, pt: 1, display: 'flex', justifyContent: 'right'}}>
              {!!components.length && context.editable && (
                <Button
                  onClick={handleReorderClick}
                  disabled={submitting}
                  color={reorderMode ? 'primary' : 'secondary'}>
                  {submitting && 'Saving'}
                  {!submitting && (reorderMode ? 'Done' : 'Change Order')}
                </Button>
              )}
              {!reorderMode && (
                <Button onClick={handleAddClick} sx={{ml: 1}}>
                  Add Component
                </Button>
              )}
            </Box>
          )}
        </Card>
      </Container>
      <FormComponentDialog
        open={componentDialogOpen}
        component={activeIdx !== -1 ? components[activeIdx] : undefined}
        allComponents={components}
        submitting={submitting}
        onClose={handleComponentDialogClose}
        onDelete={handleComponentDialogDelete}
        onSubmit={handleComponentDialogSubmit}
      />
    </Box>
  );
}

export default FormBuilder;
