import React from 'react';
import Typography from '@mui/material/Typography';

import Dialog from 'components/Dialog';
import ValidatedInput, {initialValue} from 'components/ValidatedInput';
import Select from 'components/Select';
import Dropzone from 'components/Dropzone';

import {useFormBuilderContext} from 'functions/context';
import {apiCall, createAndUploadFile} from 'functions/api';

import type {FormPage, InsertPageRequest} from 'types/forms';
import type {Portal} from 'types/auth';

interface Props {
  open: boolean;
  page?: FormPage;
  onClose: (reload: boolean, page?: FormPage) => void;
  portal?: Portal;
}

function PageDialog(props: Props) {
  const [name, setName] = React.useState(initialValue('text'));
  const [type, setType] = React.useState<FormPage['type']>('DOCUMENT');
  const [file, setFile] = React.useState<File>();
  const [submitting, setSubmitting] = React.useState(false);
  const [serverError, setServerError] = React.useState<string>();
  const [progress, setProgress] = React.useState<number>(0);

  const context = useFormBuilderContext()!;
  const portal = props.portal || context.portal;
  const fileType = props.page ? 'formPageImage' : 'formPage';

  const error = React.useMemo(() => {
    if (props.page) {
      return !name.success;
    }
    return !name.success || !type || (type === 'DOCUMENT' && !file);
  }, [name, type, file]);

  const handleDrop = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleClose = () => {
    if (submitting) return;
    props.onClose(false);
  };

  const handleSubmit = async () => {
    if (submitting || error) return;
    setSubmitting(true);
    setServerError(undefined);
    const request: Partial<InsertPageRequest> = {
      name: name.value,
    };
    let apiURL = `${context.formsAPIBase}/${context.form.id}/pages`;
    if (props.page) {
      apiURL += `/${props.page.id}`;
    } else {
      request.type = type;
    }

    if (type === 'DOCUMENT' && file) {
      try {
        request.image = await createAndUploadFile(
          file!,
          portal,
          fileType,
          (progress: number) => setProgress(progress),
        );
      } catch (e: any) {
        setProgress(0);
        setSubmitting(false);
        setServerError(e.message || 'An error occurred');
        return;
      }
      setProgress(0);
    }

    try {
      const page = await apiCall(apiURL, 'POST', request);
      props.onClose(true, page);
      setSubmitting(false);
    } catch (e: any) {
      setServerError(e.message || 'An error occurred.');
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (props.open) {
      setName(initialValue('text', props.page?.name || ''));
      setType(props.page?.type || 'DOCUMENT');
      setFile(undefined);
      setServerError(undefined);
    }
  }, [props.open]);

  return (
    <Dialog
      title={props.page ? 'Edit Page' : 'Add Page'}
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={props.open}
      errorMessage={serverError}
      progress={
        submitting &&
        !props.page && {
          indeterminate: !progress,
          value: progress,
        }
      }
      actions={[
        {
          text: 'Cancel',
          color: 'secondary',
          onClick: handleClose,
        },
        {
          text: submitting ? 'Submitting' : 'Submit',
          color: 'primary',
          submit: true,
          disabled: submitting || error,
        },
      ]}>
      <ValidatedInput
        label="Name"
        value={name}
        onChange={setName}
        type="text"
      />
      <Select
        label="Type"
        value={type}
        disabled={!!props.page}
        onChange={setType as any}
        items={[
          {value: 'DOCUMENT', label: 'Document'},
          {value: 'FORM', label: 'Form'},
        ]}
      />
      {type === 'DOCUMENT' && (
        <>
          <Typography mt={1} fontSize={14}>
            File
          </Typography>
          <Dropzone
            type={fileType}
            onDrop={handleDrop}
            file={file}
            initialText="Drag and drop a file here to change page image"
          />
        </>
      )}
    </Dialog>
  );
}

export default PageDialog;
